.character-info {
  position: relative;
  background-image: linear-gradient(to right, rgb(24, 96, 10), rgb(169, 252, 66) 45%, rgb(169, 252, 66) 55%, rgb(24, 96, 10));
  background-size: 200%;
  background-position: center;
  transform: skewY(-1deg);
  margin-top: -10px;

  .unskew {
    transform: skewY(1deg);
  }

  .leaf-pattern {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../images/characters/shared/leaf_pattern.png");
    background-size: auto 60%;
    background-position: 270% -15%;
    background-repeat: repeat-x;
    opacity: 0.15;
  }

  .character-img-wrapper {
    order: 2;
    padding-left: 0;
    padding-right: 0;
  }

  .info-wrapper {
    order: 1;
    padding-top: 20px;

    p {
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 1.125rem;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  .dashed-path-wrapper {
    display: none;
  }

  .char-name {
    margin: 0;
    width: 100%;
    color: #fff;
    font-size: 2.25rem;
  }

  .animal-img-wrapper {
    display: none;
  }

  .animal-img {
    width: 100%;
    padding-top: 65%;
    position: relative;
  }

  .character-img {
    width: 100%;
    padding-top: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center top;
  }
}

@media only screen and (min-width: 768px) {
  .character-info {
    margin-top: -1%;
    background-image: linear-gradient(to right, rgb(24, 96, 10), rgb(169, 252, 66) 45%, rgb(169, 252, 66) 55%, rgb(24, 96, 10));

    .leaf-pattern {
      background-size: 33%;
      background-position: -7% -40%;
      background-repeat: repeat-x;
    }

    .character-img-wrapper {
      width: 37%;
      order: 1;
      align-self: flex-end;
      padding: 3.5% 0 0;
    }

    .info-wrapper {
      width: 35%;
      order: 2;
      padding-top: 8%;
      padding-bottom: 3%;
      text-align: right;
      align-self: flex-start;

      p {
        margin-top: 10px;
        font-size: 0.9rem;
      }
    }

    .dashed-path-wrapper {
      display: block;
      position: relative;
      width: 100%;
    }

    .dashed-path-container {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
    }

    .dashed-arrow-to-info {
      width: 100%;
      position: absolute;
      bottom: 0;
      right: 20%;
    }

    .char-name {
      font-size: 1.8rem;
    }

    .animal-img-wrapper {
      width: 28%;
      order: 3;
      display: block;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .animal-img {
      padding-top: 100%;
    }

    .character-img {
      padding-top: 90%;
      width: 90%;
      background-size: 100%;
      background-position: bottom;
    }

    .animal-img {
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}

@media only screen and (min-width: 1300px){
  .character-info{
    .char-name {
      font-size: 4.5rem;
    }
    .info-wrapper{
      p{
        font-size: 2.25rem;
      }
    }
  }
}