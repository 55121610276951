.video-player-container{
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  box-shadow: 0 8px 1px 0px rgba(0,0,0,0.2);

  .player-wrapper, .video-thumbnail{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  #youtube_player{
    display: block;
    width: 100%;
    height: 100%;
  }
  .video-thumbnail{
    background-size: cover;
  }
  .play-button{
    width: 100px;
    &:hover{
      cursor: pointer;
    }
  }
}