.popup-window {
  position: fixed;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.popup-wrapper {
  position: absolute;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.popup-contents-wrapper {
  position: relative;
  width: 90%;
  height: 100%;
  max-height: 100%;
  max-width: 640px;
}

.popup-contents {
  background-color: #fff;
  border-radius: 25px;
  padding: 40px 20px;
}

.close-button {
  background-color: #fff;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  position: relative;
  z-index: 6;
  transition: background-color 0.3s ease-in-out;
  margin-left: auto;
  margin-bottom: 20px;

  &:hover {
    cursor: pointer;
    background-color: #61ae26;

    &:before {
      background-color: #fff;
    }
    &:after {
      background-color: #fff;
    }
  }

  &:before,
  &:after {
    position: absolute;
    content: '';
    display: block;
    width: 30px;
    height: 5px;
    top: 50%;
    left: 50%;
    background-color: black;
    border-radius: 15px;
    transition: background-color 0.3s ease-in-out;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.wrapper-game {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  height: 1px !important;
  width: 1px !important;
  min-height: 100%;
  min-width: 100%;
  z-index: 1;
}

.wrapper-game-iframe {
  height: 100%;
  width: 100%;
}
