.character-specials {
  position: relative;
  background-color: #000;
  background-size: 200%;
  background-position: center;
  transform: skewY(-1deg);
  margin-top: -1%;
  padding-top: 20px;
  position: relative;
  z-index: 1;

  .unskew {
    transform: skewY(1deg);
  }

  .specials-wrapper {
    width: 100%;
  }

  .video-wrapper {
    order: 1;
    padding-top: 0px;
    padding-bottom: 10px;
    margin-bottom: -3%;
  }

  .dashed-path-wrapper {
    display: none;
  }

  .character-footprint-shared {
    position: absolute;
    width: 10%;
    padding-top: 10%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }

  .character-footprint-1 {
    margin-top: -29%;
    right: -2%;
    transform: rotate(200deg);
    transition: opacity 0.7s;
  }

  .character-footprint-2 {
    margin-top: -25%;
    left: 30%;
    transform: rotate(-60deg);
    transition: opacity 0.7s 0.7s;
  }

  .traits-wrapper {
    order: 2;
    margin-bottom: -3%;
  }

  .traits-stripe,
  .more-btn-wrapper {
    margin: 0 auto;
    position: relative;
  }

  .traits-stripe {
    width: 100%;
  }

  .more-btn-wrapper {
    text-decoration: none;
    width: 80%;
  }

  .traits-column {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    transform: skewY(-11deg);
    padding: 15%;
    text-align: center;
  }

  .trait {
    margin: 0;
    text-transform: uppercase;
    font-size: 1.45625rem;
    margin-bottom: 18%;
    text-shadow: 0 4px rgba(0, 0, 0, 0.3);
  }

  .more-btn-wrapper {
    .button {
      width: 100%;
      margin: 0 auto;

      .button-label {
        font-size: 1.5rem;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .character-specials {
    .trait {
      font-size: 1.5rem;
      margin-bottom: 17%;
    }
  }
}

@media only screen and (min-width: 480px) {
  .character-specials {
    .trait {
      font-size: 1.5rem;
      margin-bottom: 20%;
    }
  }
}

@media only screen and (min-width: 601px) {
  .character-specials {
    .trait {
      font-size: 2.3rem;
      margin-bottom: 18%;
    }
  }
}

@media only screen and (min-width: 768px) {
  .character-specials {
    .specials-wrapper {
    }

    .video-wrapper {
      order: 2;
      width: 63%;
      padding-top: 50px;
      position: relative;
      z-index: 1;
    }

    .traits-wrapper {
      order: 1;
      width: 37%;
      padding-right: 30px;
      position: relative;
      z-index: 1;
      align-self: flex-end;
    }

    .trait {
      font-size: 1.1625rem;
      margin-bottom: 13%;
    }

    .more-btn-wrapper {
      width: 95%;

      .button {
        .button-label {
          font-size: 1.203125rem;
        }
      }
    }

    .dashed-path-wrapper {
      display: block;
      position: relative;
      width: 100%;
    }

    .dashed-path-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 75%;
      padding-top: 10%;
    }

    .dashed-arrow-to-specials {
      width: 100%;
      position: absolute;
      right: 10%;
      bottom: 0;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .character-specials {
    .trait {
      font-size: 2.90625rem;
      margin-bottom: 6%;
    }

    .traits-column {
      padding: 12%;
    }

    .traits-stripe {
      width: 100%;
    }

    .more-btn-wrapper {
      .button {
        .button-label {
          font-size: 2.5rem;
        }
      }
    }
  }
}
