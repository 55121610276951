h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  font-weight: normal;
}
//setup slate section  headers
h1 {
  font-size: 2.6em;
}

h2 {
  font-size: 2.5em;
}

h3 {
  font-size: 3em;
}

h4 {
  font-size: 1.3em;
}
