.fade-block {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  z-index: 0;
  opacity: 0;
}
.characters-page {
  &.fade-out {
    .fade-block {
      display: block;
      transition: opacity 0.5s;
      opacity: 1;
    }
  }
  &.fade-in {
    .fade-block {
      display: block;
      transition: opacity 0.5s, transform 0s 0.5s;
      opacity: 0;
      transform: translate(-100%);
    }
  }
}

.media-viewer {
  //enter animations
  &.expand-enter {
    &.media-viewer--on-splash-page {
      .media-viewer__container {
        padding-top: 0;
      }
    }
    .media-viewer__container {
      height: 0;
      min-height: 0;
    }
    .media-viewer__media {
      opacity: 0;
    }
  }
  &.expand-enter-active {
    &.media-viewer--on-splash-page {
      .media-viewer__container {
        padding-top: 200px;
        transition: min-height 0.5s, padding-top 0.5s;
      }
    }
    .media-viewer__container {
      min-height: 600px;
      transition: min-height 0.5s;
    }
    .media-viewer__media {
      opacity: 1;
      transition: opacity 0.2s 0.3s;
    }
  }
  &.expand-enter-done {
    &.media-viewer--on-splash-page {
      .media-viewer__container {
        padding-top: 200px;
      }
    }
    .media-viewer__container {
      min-height: 600px;
    }
    .media-viewer__media {
      opacity: 1;
    }
  }

  //exit animations
  &.expand-exit {
    padding-top: 20px;
    padding-bottom: 20px;

    &.media-viewer--on-splash-page {
      .media-viewer__container {
        padding-top: 200px;
      }
    }
    .media-viewer__container {
      height: 0;
      min-height: 600px;
    }
    .media-viewer__media {
      opacity: 0;
    }
  }
  &.expand-exit-active {
    padding-top: 0;
    padding-bottom: 0;
    transition: padding-top 0.5s, padding-bottom 0.5s;
    &.media-viewer--on-splash-page {
      .media-viewer__container {
        padding-top: 0;
        transition: padding-top 0.5s, min-height 0.5s;
      }
    }
    .media-viewer__container {
      min-height: 0;
      transition: min-height 0.5s;
    }
    .media-viewer__media {
      opacity: 0;
    }
  }
  &.expand-exit-done {
    &.media-viewer--on-splash-page {
      .media-viewer__container {
        padding-top: 0;
      }
    }
    .media-viewer__container {
      min-height: 0;
    }
    .media-viewer__media {
      opacity: 0;
    }
  }
}

// Keyframe Animations
@keyframes flashingArrows {
  from {
    stroke: #fff;
  }
  50% {
    stroke: #61ae26;
  }
  to {
    stroke: #fff;
  }
}

@keyframes rockingAnimation {
  from {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes fade-in-out-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes coconut-animation {
  0% {
    transform: rotate(0deg);
    transform-origin: center bottom;
  }
  1.5% {
    transform: rotate(-4deg);
  }
  3% {
    transform: rotate(0deg);
  }
  4.5% {
    transform: rotate(3deg);
  }
  6% {
    transform: rotate(0deg);
  }
  7.5% {
    transform: rotate(-3deg);
  }
  9% {
    transform: rotate(0deg);
  }
  10.5% {
    transform: rotate(4deg);
  }
  12% {
    transform: rotate(0deg);
  }
  14% {
    transform: rotate(0deg);
  }
  18% {
    transform: translateY(-30px) scale(0.91, 1.02) rotate(2deg);
  }
  22% {
    transform: translateY(0px) scale(1, 1) rotate(0deg);
  }
  26% {
    transform: translateY(-15px) scale(0.97, 1) rotate(-1deg);
  }
  29% {
    transform: translateY(0px) scale(1, 1) rotate(0deg);
  }
  31% {
    transform: rotate(1deg);
  }
  32.5% {
    transform: rotate(0deg);
  }
  55% {
    transform: rotate(0deg);
    transform-origin: center bottom;
  }
  56.5% {
    transform: rotate(3deg);
  }
  58% {
    transform: rotate(0deg);
  }
  59.5% {
    transform: rotate(-4deg);
  }
  61% {
    transform: rotate(0deg);
  }
  62.5% {
    transform: rotate(4deg);
  }
  64% {
    transform: rotate(0deg);
  }
  65.5% {
    transform: rotate(-3deg);
  }
  67% {
    transform: rotate(0deg);
  }
}

@keyframes coconut-shadow-animation {
  14% {
    transform: scale(1, 1);
  }
  18% {
    transform: scale(0.85, 0.9);
  }
  22% {
    transform: scale(1, 1);
  }
  26% {
    transform: scale(0.92, 0.95);
  }
  29% {
    transform: scale(1, 1);
  }
}

@keyframes starburst-animation {
  0% {
    transform: translate(-50%, 32%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, 32%) rotate(359deg);
  }
}

//loader

.regot-loader {
  font-size: 20px;
  position: absolute;
  width: 4em;
  height: 1em;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.regot {
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  background: $color_main_orange;
  position: absolute;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.regot1,
.regot2 {
  left: 0;
}

.regot3 {
  left: 1.5em;
}

.regot4 {
  left: 3em;
}

@keyframes show-dot {
  from {
    transform: scale(0.001);
  }
  to {
    transform: scale(1);
  }
}

@keyframes remove-dot {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.001);
  }
}

@keyframes shift-dot {
  to {
    transform: translateX(1.5em);
  }
}

.regot1 {
  animation-name: show-dot;
}

.regot2,
.regot3 {
  animation-name: shift-dot;
}

.regot4 {
  animation-name: remove-dot;
}
