.character-header--walkthrough .arrow-wrapper,
.character-header--walkthrough .arrow-down-wrapper,
.character-header--walkthrough .character-name {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.character-header--walkthrough .speech-bubble--step-1,
.character-header--walkthrough .speech-bubble--step-2,
.character-header--walkthrough .speech-bubble--step-3,
.character-header--walkthrough .speech-bubble--step-4 {
  display: none;
}

.character-header--walkthrough.step-2 .arrow-wrapper,
.character-header--walkthrough.step-3 .arrow-wrapper,
.character-header--walkthrough.step-3 .arrow-down-wrapper,
.character-header--walkthrough.step-3 .character-name {
  opacity: 1;
  z-index: 300;
}

.character-header--walkthrough.step-4 .arrow-down-wrapper,
.character-header--walkthrough.step-4 .character-name,
.character-header--walkthrough.step-4 .arrow-wrapper {
  opacity: 1;
}

.character-header--walkthrough.step-2 .arrow-wrapper .arrow .cls-3,
.character-header--walkthrough.step-3 .arrow-down-wrapper .down-arrow .cls-3 {
  animation: flashingArrows 0.7s ease-in-out infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

// walkthrough step 1
.character-header--walkthrough.step-1 .speech-bubble--step-1 {
  display: block;
  position: absolute;
  height: 0;
  width: 40%;
  bottom: 0px;
  right: 20%;

  @media only screen and (min-width: 768px) {
    width: 22%;
  }

  .orange-bubble {
    content: '';
    width: 100%;
    padding-top: 100%;
    background-color: $color_main_orange;
    border-radius: 50%;
    position: absolute;
    left: 40%;
    display: block;
    transform: translateY(-53%);
  }

  .quincy {
    display: block;
    width: 130%;
    padding-top: 130%;
    content: '';
    position: absolute;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100%;
    left: 0;
    background-image: url('../images/characters_walkthrough/char_quincy_step_1.png');
    transform: translateY(-57%);

    .speech-bubble-message-wrapper {
      position: absolute;
      top: -5%;
      width: 100%;
      animation-duration: 4s;
      animation-iteration-count: infinite;

      @media only screen and (min-width: 768px) {
        width: 200%;
        top: 20%;
        right: 85%;
      }
      &.bounce {
        animation-name: bounce;
        // animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
      }
    }
    .speech-bubble__message {
      right: -5%;
      transform: translateY(-100%);

      &:after {
        right: -10%;
        bottom: 4%;

        @media only screen and (min-width: 952px) {
          right: -14%;
        }
        @media only screen and (min-width: 1300px) {
          right: -18%;
        }
      }
    }
  }
}

// walkthrough step 2
.character-header--walkthrough.step-2 .speech-bubble--step-2 {
  display: block;
  position: absolute;
  height: 0;
  width: 30%;
  bottom: 50%;
  right: -5%;

  @media only screen and (min-width: 768px) {
    width: 20%;
  }
  @media only screen and (min-width: 1300px) {
    width: 17%;
  }

  .orange-bubble {
    content: '';
    width: 130%;
    padding-top: 130%;
    background-color: $color_main_orange;
    border-radius: 50%;
    position: absolute;
    left: 10%;
    display: block;
    transform: translateY(-50%);
  }

  .quincy {
    display: block;
    width: 130%;
    padding-top: 130%;
    content: '';
    position: absolute;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
    background-image: url('../images/characters_walkthrough/char_quincy_step_2.png');
    transform: translateY(-57%);
  }

  .speech-bubble-message-wrapper {
    position: absolute;
    left: -125%;
    width: 120%;
    top: 40%;

    @media only screen and (min-width: 768px) {
      width: 210%;
      left: -235%;
      top: 81%;
    }
    @media only screen and (min-width: 952px) {
      left: -235%;
      top: 61%;
    }
    @media only screen and (min-width: 1300px) {
      width: 150%;
      left: -165%;
      top: 30%;
    }

    animation-duration: 4s;
    animation-iteration-count: infinite;

    &.bounce {
      animation-name: bounce;
      // animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    }
  }
  .speech-bubble__message {
    right: -5%;
    transform: translateY(-50%);

    @media only screen and (min-width: 768px) {
      transform: translateY(-100%);
    }

    &:after {
      right: -10%;
      bottom: 4%;

      @media only screen and (min-width: 768px) {
        right: -15%;
        bottom: 45%;
        transform: rotate(-45deg);
      }

      @media only screen and (min-width: 952px) {
        right: -15%;
        bottom: 35%;
        transform: rotate(-45deg);
      }
      @media only screen and (min-width: 1300px) {
        right: -18%;
        bottom: 5%;
        transform: rotate(15deg);
      }
    }
  }
}

// walkthrough step 3
.character-header--walkthrough.step-3 .speech-bubble--step-3 {
  display: block;
  position: absolute;
  height: 0;
  width: 40%;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);

  @media only screen and (min-width: 768px) {
    width: 25%;
  }
  @media only screen and (min-width: 1300px) {
    width: 30%;
  }

  .orange-bubble {
    content: '';
    width: 110%;
    padding-top: 110%;
    background-color: $color_main_orange;
    border-radius: 50%;
    position: absolute;
    left: -5%;
    display: block;
    transform: translateY(-42%);
  }

  .quincy {
    display: block;
    width: 130%;
    padding-top: 130%;
    content: '';
    position: absolute;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
    left: -25%;
    background-image: url('../images/characters_walkthrough/char_quincy_step_3.png');
    transform: translateY(-57%);
  }

  .speech-bubble-message-wrapper {
    position: absolute;
    left: 0;
    width: 120%;
    top: 10%;

    @media only screen and (min-width: 768px) {
      width: 120%;
      left: 60%;
      top: 14%;
    }
    @media only screen and (min-width: 952px) {
      width: 130%;
      left: 60%;
      top: 14%;
    }
    @media only screen and (min-width: 1300px) {
      width: 110%;
      left: 60%;
      top: 12%;
    }
    animation-duration: 4s;
    animation-iteration-count: infinite;
    &.bounce {
      animation-name: bounce;
      // animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    }
  }
  .speech-bubble__message {
    left: 0;
    transform: translateY(-100%);

    @media only screen and (min-width: 768px) {
      padding-left: 50px;
      padding-right: 50px;
    }

    @media only screen and (min-width: 952px) {
      padding-left: 100px;
      padding-right: 100px;
    }

    &:after {
      right: -10%;
      bottom: 4%;

      @media only screen and (min-width: 768px) {
        left: -15%;
        right: auto;
        bottom: 25%;
        transform: rotate(140deg);
      }

      @media only screen and (min-width: 952px) {
        left: -15%;
        right: auto;
        bottom: 25%;
        transform: rotate(140deg);
      }
      @media only screen and (min-width: 1300px) {
        left: -15%;
        right: auto;
        bottom: 25%;
        transform: rotate(140deg);
      }
    }
  }
}

// walkthrough step 4
.character-header--walkthrough.step-4 .speech-bubble--step-4 {
  display: block;
  position: absolute;
  height: 0;
  width: 40%;
  bottom: 2%;
  right: -25%;

  @media only screen and (min-width: 768px) {
    width: 25%;
    right: -15%;
    bottom: -2%;
  }

  .orange-bubble {
    content: '';
    width: 110%;
    padding-top: 110%;
    background-color: $color_main_orange;
    border-radius: 50%;
    position: absolute;
    left: -30%;
    display: block;
    transform: translateY(-50%);
  }

  .quincy {
    display: block;
    width: 130%;
    padding-top: 130%;
    content: '';
    position: absolute;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
    left: -25%;
    background-image: url('../images/characters_walkthrough/char_quincy_step_4.png');
    transform: translateY(-57%);
  }

  .speech-bubble-message-wrapper {
    position: absolute;
    left: -120%;
    width: 120%;
    top: 10%;

    @media only screen and (min-width: 768px) {
      left: -160%;
      width: 140%;
      top: 10%;
    }
    animation-duration: 4s;
    animation-iteration-count: infinite;
    &.bounce {
      animation-name: bounce;
      // animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    }
  }
  .speech-bubble__message {
    right: 0;
    transform: translateY(-100%);

    @media only screen and (min-width: 768px) {
      padding-left: 50px;
      padding-right: 50px;
    }

    //@media only screen and (min-width: 952px){
    //  padding-left: 100px;
    //  padding-right: 100px;
    //}

    &:after {
      right: -10%;
      bottom: 4%;

      @media only screen and (min-width: 768px) {
        right: -15%;
        bottom: 10%;
        transform: rotate(15deg);
      }

      //@media only screen and (min-width: 952px){
      //  left: -15%;
      //  right: auto;
      //  bottom: 25%;
      //  transform: rotate(140deg);
      //}
      //@media only screen and (min-width: 1300px){
      //  left: -15%;
      //  right: auto;
      //  bottom: 25%;
      //  transform: rotate(140deg);
      //}
    }
  }
}

.speech-bubble__message {
  background-color: #ffffff;
  padding: 20px;
  text-align: center;
  border-radius: 20px;
  position: absolute;
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.2);

  @media only screen and (min-width: 768px) {
    padding: 20px 100px;
  }

  &:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 40px 0 0 100px;
    border-color: transparent;
    transform: rotate(15deg);

    @media only screen and (min-width: 768px) {
      border-color: transparent transparent transparent #ffffff;
    }
  }
}

.speech-bubble__title {
  font-size: 20px;
  line-height: 1.1;

  @media only screen and (min-width: 768px) {
    font-size: 36px;
  }
}

.speech-bubble__description {
  font-size: 18px;
  line-height: 1.1;

  @media only screen and (min-width: 768px) {
    font-size: 22px;
  }
}

// Speech Bubble close
.speech-bubble__close {
  position: absolute;
  right: -15px;
  top: -15px;
}

.speech-bubble__close-button {
  width: 20px;
  height: 26px;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 6px;
    border-radius: 7px;
    background-color: #000;
    transition: background-color 0.2s ease-in-out;
  }

  &:before {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }

  &:after {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
  }
}

.speech-bubble__close-button__wrapper {
  background-color: #fff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: relative;
  z-index: 6;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: $color_main_green;

    .speech-bubble__close-button {
      &:before,
      &:after {
        background-color: #fff;
      }
    }
  }
}
