//import fonts here!!!!

body,
button,
input,
select,
optgroup,
textarea {
  font-family: $font__main;
  font-weight: normal;
  line-height: normal;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.bold{
  font-weight: bold;
}

@import 'headings';

@import 'copy';
