.character-list {
  // Setup background colouring
  background: #2ab2ae;
  background: linear-gradient(to right, #0a5e6b 0%, #42efdf 50%, #42efdf 55%, #0a5e6b 100%);
  background: -moz-linear-gradient(left, #0a5e6b 0%, #42efdf 50%, #42efdf 55%, #0a5e6b 100%);
  background: -webkit-linear-gradient(left, #0a5e6b 0%, #42efdf 50%, #42efdf 55%, #0a5e6b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0a5e6b', endColorstr='#0a5e6b', GradientType=1);
  position: relative;
  width: 100%;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 20%;
    position: absolute;
    top: -10%;
    left: 0;
    background: #2ab2ae;
    background: linear-gradient(to right, #0a5e6b 0%, #42efdf 50%, #42efdf 55%, #0a5e6b 100%);
    background: -moz-linear-gradient(left, #0a5e6b 0%, #42efdf 50%, #42efdf 55%, #0a5e6b 100%);
    background: -webkit-linear-gradient(left, #0a5e6b 0%, #42efdf 50%, #42efdf 55%, #0a5e6b 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0a5e6b', endColorstr='#0a5e6b', GradientType=1);
    transform: skewY(-1deg);
  }

  .carousel {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    padding-bottom: 10px;
    position: relative;

    @media only screen and (min-width: 768px) {
      padding-bottom: 30px;
    }

    &__prev-arrow,
    &__next-arrow {
      outline: none;
      border: 0;
      background-color: transparent;
      height: 100%;
      position: absolute;
      z-index: 10;
      width: 35px;

      @media only screen and (min-width: 768px) {
        display: block;
      }

      svg {
        height: 100%;
        width: 100%;
      }
    }

    &__prev-arrow {
      left: 0;
    }

    &__next-arrow {
      right: 0;
    }
  }

  .carousel.active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .cWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item p {
    position: relative;
    top: -5px;
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    user-select: none;
  }

  .item:first-child {
    margin-right: 0px !important;
  }
  .item:last-child {
    margin-left: 0px !important;
  }
}

// Setup all character images
.character-list .item {
  height: 100px;
  width: 100px;

  @media only screen and (min-width: 768px) {
    height: 125px;
    width: 125px;
  }

  &.zoomba {
    background-image: url('../images/characters/carousel/Roundel_01_Zoomba_OFF.png');
    background-size: 100%;

    &:hover,
    &.active {
      background-image: url('../images/characters/carousel/Roundel_01_Zoomba_ON.png');
    }
  }

  &.quincy {
    background-image: url('../images/characters/carousel/Roundel_02_Quincy_OFF.png');
    background-size: 100%;

    &:hover,
    &.active {
      background-image: url('../images/characters/carousel/Roundel_02_Quincy_ON.png');
    }
  }

  &.pokey {
    background-image: url('../images/characters/carousel/Roundel_03_Pokey_OFF.png');
    background-size: 100%;

    &:hover,
    &.active {
      background-image: url('../images/characters/carousel/Roundel_03_Pokey_ON.png');
    }
  }

  &.antonio {
    background-image: url('../images/characters/carousel/Roundel_04_Antonio_OFF.png');
    background-size: 100%;

    &:hover,
    &.active {
      background-image: url('../images/characters/carousel/Roundel_04_Antonio_ON.png');
    }
  }

  &.babatua {
    background-image: url('../images/characters/carousel/Roundel_05_Babatua_OFF.png');
    background-size: 100%;

    &:hover,
    &.active {
      background-image: url('../images/characters/carousel/Roundel_05_Babatua_ON.png');
    }
  }

  &.renalda {
    background-image: url('../images/characters/carousel/Roundel_06_Renalda_OFF.png');
    background-size: 100%;

    &:hover,
    &.active {
      background-image: url('../images/characters/carousel/Roundel_06_Renalda_ON.png');
    }
  }

  &.colette {
    background-image: url('../images/characters/carousel/Roundel_07_Colette_OFF.png');
    background-size: 100%;

    &:hover,
    &.active {
      background-image: url('../images/characters/carousel/Roundel_07_Colette_ON.png');
    }
  }

  &.frick-and-frack,
  &.frick-frack {
    background-image: url('../images/characters/carousel/Roundel_08_FrickAndFrack_OFF.png');
    background-size: 100%;

    &:hover,
    &.active {
      background-image: url('../images/characters/carousel/Roundel_08_FrickAndFrack_ON.png');
    }
  }

  &.xiang-and-fan,
  &.xiang-fan {
    background-image: url('../images/characters/carousel/Roundel_09_XiangAndFang_OFF.png');
    background-size: 100%;

    &:hover,
    &.active {
      background-image: url('../images/characters/carousel/Roundel_09_XiangAndFang_ON.png');
    }
  }

  &.bubba {
    background-image: url('../images/characters/carousel/Roundel_10_Bubba_OFF.png');
    background-size: 100%;

    &:hover,
    &.active {
      background-image: url('../images/characters/carousel/Roundel_10_Bubba_ON.png');
    }
  }

  &.ernesto {
    background-image: url('../images/characters/carousel/Roundel_11_Ernesto_OFF.png');
    background-size: 100%;

    &:hover,
    &.active {
      background-image: url('../images/characters/carousel/Roundel_11_Ernesto_ON.png');
    }
  }

  &.spike {
    background-image: url('../images/characters/carousel/Roundel_12_Spike_OFF.png');
    background-size: 100%;

    &:hover,
    &.active {
      background-image: url('../images/characters/carousel/Roundel_12_Spike_ON.png');
    }
  }

  &.oscar {
    background-image: url('../images/characters/carousel/Roundel_13_Oscar_OFF.png');
    background-size: 100%;

    &:hover,
    &.active {
      background-image: url('../images/characters/carousel/Roundel_13_Oscar_ON.png');
    }
  }

  &.lulu {
    background-image: url('../images/characters/carousel/Roundel_14_Lulu_OFF.png');
    background-size: 100%;

    &:hover,
    &.active {
      background-image: url('../images/characters/carousel/Roundel_14_Lulu_ON.png');
    }
  }
}
