.cookie-bar {
  backface-visibility: hidden;
  background-color: $color_main_orange;
  box-shadow: 0px 7px 0px 0px rgba(0, 0, 0, 0.2);
  color: $color_main_font;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.1;
  padding: 16px 8px;
  text-align: center;
  transform-origin: 50% 100%;
  width: 100%;
  z-index: 999;

  transform: translateY(200px);

  &--active {
    transform: translateY(0);
  }
}

.cookie-bar__container {
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
}

.cookie-bar__popup-text,
.cookie-bar__popup-buttons {
  display: inline-block;
  vertical-align: top;

  @media only screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
  }
}

.cookie-bar__popup-buttons {
  text-align: right;
  width: 38%;
}

.cookie-bar__popup-text {
  padding-right: 10px;
  text-align: left;
  width: 58%;
  font-family: Arial, sans-serif;
}

.cookie-bar__title,
.cookie-bar__text {
  font: {
    family: $font__main;
    size: 15px;
  }
}

.cookie-bar__text {
  margin: 0;
}

.cookie-bar__popup-buttons__button {
  border: {
    style: none;
    radius: 45px;
  }
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font: {
    size: 13px;
    weight: 700;
  }
  margin: {
    bottom: 5px;
    left: 15px;
  }
  min-height: 45px;
  padding: 0 15px;
  text: {
    align: center;
    decoration: none;
  }

  a {
    text-decoration: none;
  }

  &--agree-button {
    background-color: #000000;
    color: #ffffff;
    &:hover {
      background-color: #ffffff;
      color: #000000;
    }
  }

  &--cookie-more {
    background-color: #ffffff;

    a {
      color: #000000;
    }

    &:hover {
      background-color: #000000;
      a {
        color: #ffffff;
      }
    }
  }
}
