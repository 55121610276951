.locked-content-item {
  position: relative;
  &.content-item{
    background: none;
    box-shadow: none;
    &:hover{
      cursor: default;
    }
  }
  .dashed {
    .cls-1 {
      opacity: 0.2;
    }

    .cls-2, .cls-3 {
      fill: none;
      stroke-miterlimit: 10;
      stroke-width: 12px;
      stroke-dasharray: 59 19;
    }

    .cls-2 {
      stroke: #000;
    }
  }
  &.content-item--download{
    color: $color_main_purple;
    .dashed{
      .cls-3 {
        stroke: $color_main_purple;
      }
    }
  }
  &.content-item--gif{
    color: $color_main_green;
    .dashed{
      .cls-3 {
        stroke: $color_main_green;
      }
    }
  }
  &.content-item--video{
    color: $color_main_teal;
    .dashed{
      .cls-3 {
        stroke: $color_main_teal;
      }
    }
  }
  &.content-item--game{
    color: $color_main_orange;
    .dashed{
      .cls-3 {
        stroke: $color_main_orange;
      }
    }
  }

  .content-wrapper{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .label{
    padding: 40px 40px 20px;
    font-size: 1.3rem;
  }
  .content-desc{
    padding: 20px 20px 40px;
    text-transform: none;
    font-size: 1.3rem;
  }
  .icon-wrapper{
    background-image: url("../images/contents_page/coconut-locked.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 420px) {
  .locked-content-item{
    .label, .content-desc{
      font-size: 2rem;
    }
  }
}

@media only screen and (min-width: 550px) {
  .locked-content-item{
    .label, .content-desc{
      font-size: 2.8rem;
    }
  }
}

@media only screen and (min-width: 640px) {
  .locked-content-item{
    .label, .content-desc{
      font-size: 3.2rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .locked-content-item{
    .label, .content-desc{
      font-size: 2.2rem;
    }
  }
}