.character-header-wrapper {
  position: relative;
  width: 100%;
  padding-top: 133.3333%;
}

.character-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .video-wrapper {
    position: absolute;
    top: -10%;
    left: -87%;
    width: 270%;
    height: 100%;

    .animated-video, .video-still-image {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .character-container {
    height: 100%;
    position: relative;
    padding-bottom: 2%;
  }

  &.character-bg {
    width: 100%;
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    padding-left: 10px;
    padding-right: 10px;
  }

  .arrow-wrapper {
    width: 15px;
    position: relative;
    z-index: 1;
  }

  .arrow-down-wrapper {
    position: relative;
    width: 60px;
    padding-bottom: 10px;
  }

  .character-name {
    display: none;
  }

  //character backgrounds and header images
  &.frick_and_frack {
    &.character-bg {
      background-image: radial-gradient(at 50% bottom, rgba(0, 78, 101, 0) 40%, rgba(0, 60, 77, 0.5)),
      url("../images/characters/bg/frick_and_frack.jpg");
    }

    .coconut-size-wrap-1 {
      width: 8%;
      right: auto;
      left: 5%;
      bottom: 66%;
      transform: rotate(-40deg);

      &.unlocked{
        width: 10%;
      }
    }

    .coconut-size-wrap-2 {
      width: 18%;
      right: 11%;
      left: auto;
      bottom: 6%;
      transform: rotate(-60deg);

      &.unlocked{

      }
    }
  }

  &.xiang_and_fan {
    &.character-bg {
      background-image: radial-gradient(at 50% bottom, rgba(0, 78, 101, 0) 40%, rgba(0, 60, 77, 0.5)),
      url("../images/characters/bg/xiang_and_fan.jpg");
      background-position: 48% center;
    }

    .coconut-size-wrap-1 {
      width: 5%;
      right: auto;
      left: 17%;
      bottom: 62%;
      transform: rotate(-60deg);

      &.unlocked{
        width: 10%;
      }
    }

    .coconut-size-wrap-2 {
      width: 17%;
      right: 33%;
      left: auto;
      bottom: 25%;
      top: auto;
      transform: rotate(-80deg);
    }
  }

  &.bubba {
    &.character-bg {
      background-image: radial-gradient(at 50% bottom, rgba(0, 78, 101, 0) 40%, rgba(0, 60, 77, 0.5)),
      url("../images/characters/bg/bubba.jpg");
    }

    .coconut-size-wrap-1 {
      width: 5%;
      right: 9%;
      left: auto;
      bottom: 78%;
      transform: rotate(-60deg);

      &.unlocked{
        width: 10%;
        bottom: 70%;
      }
    }

    .coconut-size-wrap-2 {
      width: 18%;
      right: auto;
      left: 24%;
      bottom: 11%;
      top: auto;
      transform: rotate(-80deg);
    }
  }

  &.ernesto {
    &.character-bg {
      background-image: radial-gradient(at 50% bottom, rgba(0, 78, 101, 0) 40%, rgba(0, 60, 77, 0.5)),
      url("../images/characters/bg/ernesto.jpg");
    }

    .coconut-size-wrap-1 {
      width: 6%;
      right: 13%;
      left: auto;
      bottom: 42%;
      transform: rotate(-60deg);

      &.unlocked{
        width: 10%;
      }
    }

    .coconut-size-wrap-2 {
      width: 18%;
      right: auto;
      left: 10%;
      bottom: 35%;
      top: auto;
      transform: rotate(-80deg);
    }
  }

  &.spike {
    &.character-bg {
      background-image: radial-gradient(at 50% bottom, rgba(0, 78, 101, 0) 40%, rgba(0, 60, 77, 0.5)),
      url("../images/characters/bg/spike.jpg");
    }

    .coconut-size-wrap-1 {
      width: 11%;
      right: auto;
      left: 21%;
      bottom: 52%;
      transform: rotate(-60deg);
    }

    .coconut-size-wrap-2 {
      width: 17%;
      right: 7%;
      left: auto;
      bottom: 21%;
      top: auto;
      transform: rotate(-85deg);
    }
  }

  &.oscar {
    &.character-bg {
      background-image: radial-gradient(at 50% bottom, rgba(0, 78, 101, 0) 40%, rgba(0, 60, 77, 0.5)),
      url("../images/characters/bg/oscar.jpg");
    }

    .coconut-size-wrap-1 {
      width: 6%;
      right: 3%;
      left: auto;
      bottom: 59%;
      transform: rotate(-115deg);

      &.unlocked{
        width: 10%;
        right: 5%;
      }
    }

    .coconut-size-wrap-2 {
      width: 15%;
      right: 9%;
      left: auto;
      bottom: 9%;
      transform: rotate(-60deg);
    }
  }

  &.lulu {
    &.character-bg {
      background-image: radial-gradient(at 50% bottom, rgba(0, 78, 101, 0) 40%, rgba(0, 60, 77, 0.5)),
      url("../images/characters/bg/lulu.jpg");
    }

    .coconut-size-wrap-1 {
      width: 7%;
      right: 18%;
      left: auto;
      bottom: 61%;
      transform: rotate(-70deg);

      &.unlocked{
        width: 11%;
        right: 16%;
      }
    }

    .coconut-size-wrap-2 {
      width: 15%;
      right: auto;
      left: 14%;
      bottom: 10%;
      top: auto;
      transform: rotate(-80deg);
    }
  }

  &.renalda {
    &.character-bg {
      background-image: radial-gradient(at 50% bottom, rgba(0, 78, 101, 0) 40%, rgba(0, 60, 77, 0.5)),
      url("../images/characters/bg/renalda.jpg");
    }

    .coconut-size-wrap-1 {
      width: 17%;
      right: auto;
      left: 24%;
      bottom: 13%;
      transform: rotate(-80deg);
    }

    .coconut-size-wrap-2 {
      width: 11%;
      right: 11%;
      left: auto;
      bottom: 30%;
      top: auto;
      transform: rotate(-60deg);
    }
  }

  &.renalda, &.lulu, &.oscar, &.spike, &.ernesto, &.bubba, &.frick_and_frack {
    &.character-bg {
      background-position: center;
    }
  }

  &.frick_and_frack, &.xiang_and_fan, &.bubba, &.ernesto, &.spike, &.oscar, &.lulu, &.zoomba, &.quincy, &.pokey, &.antonio, &.babatua, &.renalda, &.colette {
    .coconut-size-wrap {
      &.unlocked {
        transform: none;
      }
    }
  }

  &.antonio {
    .coconut-size-wrap-1 {
      width: 17%;
      right: auto;
      left: 10%;
      bottom: 15%;
      transform: rotate(-40deg);
    }

    .coconut-size-wrap-2 {
      width: 8%;
      right: auto;
      left: 8%;
      bottom: 59%;
      transform: rotate(-25deg);
    }
  }

  &.quincy {
    .video-wrapper {
      left: -65%;
      top: -14%;
    }

    .coconut-size-wrap-1 {
      width: 18%;
      right: auto;
      left: 40%;
      bottom: 10%;
      transform: rotate(-90deg);
    }

    .coconut-size-wrap-2 {
      width: 12%;
      right: 4%;
      bottom: 15%;
      transform: rotate(-25deg);
    }
  }

  &.pokey {
    .video-wrapper {
      left: -84%;
    }

    .coconut-size-wrap-1 {
      width: 17%;
      right: auto;
      left: 2%;
      bottom: 4%;
      transform: rotate(-70deg);
    }

    .coconut-size-wrap-2 {
      width: 12%;
      right: 4%;
      bottom: 8%;
      transform: rotate(-25deg);
    }
  }

  &.babatua {
    .video-wrapper {
      left: -69%;
      top: -3%;
      width: 250%;
    }

    .coconut-size-wrap-1 {
      width: 17%;
      right: auto;
      left: 3%;
      bottom: 4%;
      transform: rotate(-40deg);
    }

    .coconut-size-wrap-2 {
      width: 14%;
      right: 1%;
      left: auto;
      bottom: 5%;
      top: auto;
      transform: rotate(-25deg);

      &.unlocked{
        right: 2%;
      }
    }
  }

  &.colette {
    .video-wrapper {
      left: -69%;
      top: -0%;
      width: 240%;
    }

    .coconut-size-wrap-1 {
      width: 17%;
      right: auto;
      left: 3%;
      bottom: 3%;
      transform: rotate(-80deg);
    }

    .coconut-size-wrap-2 {
      width: 10%;
      right: 21%;
      left: auto;
      bottom: 77%;
      transform: rotate(-25deg);

      &.unlocked{
        width: 12%;
      }
    }
  }

  &.zoomba {
    .coconut-size-wrap-1 {
      width: 17%;
      right: auto;
      left: 4%;
      bottom: 29%;
      transform: rotate(-75deg);

      &.unlocked {
        bottom: 25%;
      }
    }

    .coconut-size-wrap-2 {
      width: 12%;
      right: 1%;
      bottom: 63%;
      transform: rotate(-55deg);

      &.unlocked {
        right: 3%;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .character-header-wrapper {
    padding-top: 51.25%;
  }
  .character-header {
    &.character-bg {
      padding-left: 20px;
      padding-right: 20px;
    }

    .video-wrapper {
      position: absolute;
      top: -10%;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .character-name {
      display: block;
      position: relative;
      font-size: 2.40625rem;
      color: #fff;
      text-shadow: 3px 6px rgba(0, 0, 0, 0.25);
    }

    &.xiang_and_fan {
      &.character-bg {
        background-position: 50% 50%;
      }
      .coconut-size-wrap-1 {
        width: 3%;
        left: 34%;
        bottom: 62%;
        &.unlocked{
          width: 6%;
          left: 30%;
          bottom: 52%;
        }
      }

      .coconut-size-wrap-2 {
        width: 8%;
        right: 35%;
        bottom: 20%;
      }
    }

    &.bubba {
      &.character-bg {
        background-position: 50% 40%;
      }

      .coconut-size-wrap-1 {
        width: 6%;
        right: 13%;
        bottom: 61%;

        &.unlocked{
          width: 8%;
          bottom: 45%;
          right: 10%;
        }
      }

      .coconut-size-wrap-2 {
        width: 10%;
        bottom: 7%;
      }

    }

    &.spike {
      &.character-bg {
        background-position: 50% 55%;
      }
    }

    &.pokey, &.quincy, &.colette, &.babatua {
      .video-wrapper {
        position: absolute;
        top: -10%;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &.zoomba {
      .coconut-size-wrap-1 {
        width: 10%;
        left: 10%;
        bottom: 27%;
        &.unlocked {

        }
      }

      .coconut-size-wrap-2 {
        width: 6%;
        right: 4%;
        bottom: 54%;
        &.unlocked {
          width: 7%;
          right: 8%;
          bottom: 48%;
        }
      }
    }

    &.quincy {
      .coconut-size-wrap-1 {
        width: 7.5%;
        left: 37%;
        bottom: 12%;
      }

      .coconut-size-wrap-2 {
        width: 6%;
        right: 38%;
        bottom: 14%;
      }
    }

    &.pokey {
      .coconut-size-wrap-1 {
        width: 8%;
        left: 32%;
        bottom: 8%;
      }

      .coconut-size-wrap-2 {
        width: 6%;
        right: 14%;
        bottom: 11%;
      }
    }

    &.antonio {
      .coconut-size-wrap-1 {
        width: 7.5%;
        left: 34%;
        bottom: 17%;
      }

      .coconut-size-wrap-2 {
        width: 4%;
        bottom: 60%;
        left: 35%;
        &.unlocked{
          width: 5%;
          left: 36%;
        }
      }
    }

    &.babatua {
      .coconut-size-wrap-1 {
        width: 7.5%;
        left: 28%;
        bottom: 10%;
      }

      .coconut-size-wrap-2 {
        width: 6%;
        right: 33%;
        bottom: 12%;
        &.unlocked{
          right: 33%;
        }
      }
    }

    &.colette {
      .coconut-size-wrap-1 {
        width: 8%;
        left: 24%;
        bottom: 8%;
      }

      .coconut-size-wrap-2 {
        width: 6%;
        right: 31%;
        bottom: 84%;
        z-index: 50;

        &.unlocked{
          width: 7%;
          bottom: 70%;
        }
      }
    }

    &.renalda {
      .coconut-size-wrap-1 {
        width: 8%;
        left: 32%;
        bottom: 7%;
      }

      .coconut-size-wrap-2 {
        width: 7%;
        right: 22%;
        bottom: 15%;
      }
    }

    &.frick_and_frack {
      .coconut-size-wrap-1 {
        width: 6%;
        left: 27%;
        bottom: 60%;
        &.unlocked{
          width: 8%;
          bottom: 46%;
        }
      }

      .coconut-size-wrap-2 {
        width: 8%;
        right: 17%;
        bottom: 15%;
      }
    }

    &.ernesto {
      .coconut-size-wrap-1 {
        width: 4%;
        right: 34%;

        &.unlocked{
          width: 6%;
        }
      }

      .coconut-size-wrap-2 {
        width: 10%;
        left: 32%;
        bottom: 30%;
      }
    }

    &.spike {
      .coconut-size-wrap-1 {
        width: 5%;
        left: 18%;
        bottom: 56%;

        &.unlocked{
          width: 7%;
          bottom: 48%;
        }
      }

      .coconut-size-wrap-2 {
        width: 10%;
        bottom: 17%;
      }
    }

    &.oscar {
      .coconut-size-wrap-1 {
        width: 4%;
        right: 18%;
        bottom: 69%;

        &.unlocked{
          width: 6%;
          right: 18%;
          bottom: 55%;
        }
      }

      .coconut-size-wrap-2 {
        width: 10%;
        right: 7%;
        bottom: 7%;
      }
    }

    &.lulu {
      .coconut-size-wrap-1 {
        width: 4%;
        right: 36%;
        bottom: 62%;

        &.unlocked{
          width: 6%;
          right: 35%;
        }
      }

      .coconut-size-wrap-2 {
        width: 8.5%;
        left: 21%;
        bottom: 10%;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .character-header {
    &.character-bg {
      padding-left: 30px;
      padding-right: 30px;
      background-position: center;
    }

    &.zoomba {
      .coconut-size-wrap-1 {
        width: 8%;
      }

      .coconut-size-wrap-2 {
        width: 4%;
        right: 6%;
        bottom: 53%;

        &.unlocked {
          width: 7%;
          right: 6%;
          bottom: 53%;
        }
      }
    }

    &.quincy {
      .coconut-size-wrap-1 {
        width: 6%;
      }

      .coconut-size-wrap-2 {
        width: 5%;
      }
    }

    &.pokey {
      .coconut-size-wrap-1 {
        width: 6%;
        &.unlocked{
          width: 8%;
        }
      }

      .coconut-size-wrap-2 {
        width: 4%;
        &.unlocked{
          width: 6%;
        }
      }
    }

    &.antonio {
      .coconut-size-wrap-1 {
        width: 6.5%;
      }

      .coconut-size-wrap-2 {
        width: 3%;
      }
    }

    &.babatua {
      .coconut-size-wrap-1 {
        width: 6.5%;
      }

      .coconut-size-wrap-2 {
        width: 5%;
      }
    }

    &.colette {
      .coconut-size-wrap-1 {
        width: 6%;
        &.unlocked{
          width: 7%;
        }
      }

      .coconut-size-wrap-2 {
        width: 4%;
        right: 31%;
        &.unlocked{
          width: 6%;
        }
      }
    }

    &.renalda {
      .coconut-size-wrap-1 {
        width: 6%;

        &.unlocked{
          width: 8%;
        }
      }

      .coconut-size-wrap-2 {
        width: 5%;

        &.unlocked{
          width: 7%;
        }
      }
    }

    &.frick_and_frack {
      .coconut-size-wrap-1 {
        width: 4%;

        &.unlocked{
          width: 6%;
          bottom: 60%;
        }
      }

      .coconut-size-wrap-2 {
        width: 6%;
        &.unlocked{
          width: 8%;
        }
      }
    }

    &.xiang_and_fan {
      .coconut-size-wrap-1 {
        width: 3%;
        &.unlocked{
          left: 33%;
          bottom: 62%;
          width: 5%;
        }
      }

      .coconut-size-wrap-2 {
        width: 6%;
        right: 36%;
      }
    }

    &.bubba {
      .coconut-size-wrap-1 {
        width: 4%;
        right: 13%;

        &.unlocked{
          width: 6%;
          bottom: 56%;
          right: 11%;
        }
      }

      .coconut-size-wrap-2 {
        width: 8%;
      }
    }

    &.ernesto {
      .coconut-size-wrap-1 {
        width: 3%;
        &.unlocked{
          width: 5%;
        }
      }

      .coconut-size-wrap-2 {
        width: 8%;
      }
    }

    &.spike {
      .coconut-size-wrap-1 {
        width: 4%;
        left: 19%;
        bottom: 61%;

        &.unlocked{
          width: 7%;
          bottom: 54%;
        }
      }

      .coconut-size-wrap-2 {
        width: 8%;
      }
    }

    &.oscar {
      .coconut-size-wrap-1 {
        width: 3%;
        bottom: 69%;

        &.unlocked{
          width: 6%;
          right: 16%;
          bottom: 58%;
        }
      }

      .coconut-size-wrap-2 {
        width: 8%;
        right: 9%;
      }
    }

    &.lulu {
      .coconut-size-wrap-1 {
        width: 3%;
        bottom: 62%;

        &.unlocked{
          width: 5%;
          right: 35%;
        }
      }

      .coconut-size-wrap-2 {
        width: 6.5%;
      }
    }
  }
}

@media only screen and (min-width: 1300px) {
  .character-header {
    &.character-bg {
      padding-left: 40px;
      padding-right: 40px;
    }

    .character-name {
      font-size: 4.8125rem;
    }

    .arrow-wrapper {
      width: 40px;
      height: 81px;
    }

    .arrow-down-wrapper {
      width: 120px;
      padding-bottom: 10px;
    }

    &.zoomba {
      .coconut-size-wrap-1 {
        width: 6%;
      }

      .coconut-size-wrap-2 {
        width: 3%;
        right: 7%;

        &.unlocked {
          width: 5%;
          right: 7%;
        }
      }
    }

    &.quincy {
      .coconut-size-wrap-1 {
        width: 6%;
      }

      .coconut-size-wrap-2 {
        width: 5%;
      }
    }

    &.pokey {
      .coconut-size-wrap-1 {
        width: 5%;
      }

      .coconut-size-wrap-2 {
        width: 3%;
      }
    }

    &.antonio {
      .coconut-size-wrap-2 {
        width: 2.5%;
        //top: 35%;
      }
    }

    //&.babatua{
    //  .coconut-size-wrap-1{
    //    width: 6.5%;
    //  }
    //  .coconut-size-wrap-2{
    //    width: 5%;
    //  }
    //}
    &.colette{
      .coconut-size-wrap-2{
        &.unlocked{
          width: 6%;
          bottom: 76%;
        }
      }
    }
    //&.renalda{
    //  .coconut-size-wrap-1{
    //    width: 6%;
    //  }
    //  .coconut-size-wrap-2{
    //    width: 5%;
    //  }
    //}
    &.frick_and_frack {
      .coconut-size-wrap-1 {
        width: 3%;
      }
    }

    &.xiang_and_fan {
      .coconut-size-wrap-1 {
        width: 2%;
      }

      .coconut-size-wrap-2 {
        width: 5%;
        right: 37%;

        &.unlocked{
          width: 7%;
        }
      }
    }

    &.bubba{
      .coconut-size-wrap-1{
        &.unlocked{
          width: 6%;
          bottom: 60%;
          right: 12%;
        }
      }

    }
    &.ernesto {
      .coconut-size-wrap-1{
        &.unlocked{
          width: 5%;
        }
      }
      .coconut-size-wrap-2 {
        width: 6%;
        left: 34%;
      }
    }

    &.spike {
      .coconut-size-wrap-1 {
        width: 4%;
        left: 19%;
        bottom: 61%;

        &.unlocked{
          width: 6%;
          left: 19%;
          bottom: 61%;
        }
      }

      .coconut-size-wrap-2 {
        width: 7%;
      }
    }

    &.oscar {
      .coconut-size-wrap-1 {
        width: 2.5%;

        &.unlocked{
          width: 4%;
          right: 17%;
          bottom: 69%;
        }
      }
    }

    &.lulu {
      .coconut-size-wrap-1 {
        width: 2.5%;
        bottom: 62%;
        right: 36%;

        &.unlocked{
          width: 4%;
          right: 35%;
        }
      }

      .coconut-size-wrap-2 {
        width: 5%;
      }
    }
  }
}
