@keyframes downwards {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.navigation__container.active .mute-message-overlay {
  z-index: -1;
}

.mute-message-overlay {
  position: absolute;
  top: calc(100% + 10px);
  background-color: white;
  border-radius: 10px;
  text-align: center;
  padding: 5px 20px;
  left: -30px;
  width: 120px;
  z-index: 10;

  animation: downwards 0.6s ease-in-out 1;

  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 1);

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 15px 5px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    top: -15px;
    left: calc(50% - 5px);
  }
}

.mute-message-overlay__text {
  font-size: 0.9rem;
  line-height: 1rem;
}

// Speech Bubble close
.mute-message-overlay__close {
  position: absolute;
  right: -8px;
  top: -8px;
}

.mute-message-overlay__close-button {
  width: 13px;
  height: 26px;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    border-radius: 7px;
    background-color: #000;
    transition: background-color 0.2s ease-in-out;
  }

  &:before {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }

  &:after {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
  }
}

.mute-message-overlay__close-button__wrapper {
  background-color: #fff;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  position: relative;
  z-index: 6;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: $color_main_green;

    .mute-message-overlay__close-button {
      &:before,
      &:after {
        background-color: #fff;
      }
    }
  }
}
