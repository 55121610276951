.signup-form {
  text-align: center;
  position: relative;
}

.signup-form__title {
  color: #ffffff;
  font-size: 36px;
}

.signup-form__description {
  color: #ffffff;
  font-size: 22px;
  line-height: 26px;
  margin: 15px auto 30px;

  width: 90%;

  @media only screen and (min-width: 768px) {
    width: 550px;
  }
}

.signup-form__firstname,
.signup-form__lastname,
.signup-form__email {
  box-shadow: inset 0px 13px 0px -6px rgba(0, 0, 0, 0.2);
  border: {
    style: none;
    radius: 30px;
  }
  display: block;
  height: 70px;
  font-size: 25px;
  margin: 10px auto;
  text-align: center;
  width: 90%;

  @media only screen and (min-width: 768px) {
    width: 350px;
  }

  &::placeholder {
    color: $color_main_font;
  }
}

.signup-form__submit {
  color: #ffffff;
  border: {
    style: none;
    radius: 35px;
  }
  background-color: $color_main_orange;
  cursor: pointer;
  padding: 15px 60px;
  margin: {
    top: 30px;
    right: auto;
    left: auto;
  }
}

.signup-form__submit__title {
  font-size: 32px;
}

.signup-form__submit__icon {
  margin-right: 20px;
  svg {
    width: 50px;
  }
}
.signup-form__status {
  font-size: 32px;

  &--success {
    color: $color_main_green;
  }

  &--error {
    color: red;
  }
}
