// import Prater Font from typekit -- dont have font to hand currently
// Or should I use <link rel="stylesheet" href="https://use.typekit.net/hgw6eeb.css">????
@import url('https://use.typekit.net/hgw6eeb.css');

// Google Fonts Import
@import url('https://fonts.googleapis.com/css?family=Roboto');

@font-face {
  font-family: 'sinzanoregular';
  src: url('../fonts/sinzano/typodermic_-_sinzano-regular-webfont.eot');
  src: url('../fonts/sinzano/typodermic_-_sinzano-regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/sinzano/typodermic_-_sinzano-regular-webfont.woff2') format('woff2'),
    url('../fonts/sinzano/typodermic_-_sinzano-regular-webfont.woff') format('woff'), url('../fonts/sinzano/typodermic_-_sinzano-regular-webfont.ttf') format('truetype'),
    url('../fonts/sinzano/typodermic_-_sinzano-regular-webfont.svg#sinzanoregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sinzanodisplay_sans';
  src: url('../fonts/sinzano/sans/typodermic_-_sinzanodisplaysans-regular-webfont.woff2') format('woff2'),
    url('../fonts/sinzano/sans/typodermic_-_sinzanodisplaysans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

$font__main: 'ff-prater-sans-web', sans-serif;
// $font__main: 'OpenSans-Regular', sans;
$font__code: Monaco, Consolas, 'Andale Mono', 'DejaVu Sans Mono', monospace;
$font__pre: 'Courier 10 Pitch', Courier, monospace;
$font__roboto: 'Roboto', sans-serif;

// Custom fonts
// Sinzano / Sinzano Sans
$font_sinzano: 'sinzanoregular';
$font_sinzano_sans: 'sinzanodisplay_sans';
