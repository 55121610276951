// Homepage styling
.page-home {
  background-color: #000000;
}

.page-home__bg-container {
  background: {
    color: #000000;
    image: url('../images/splash_page/bg_splash_page.jpg');
    size: cover;
    repeat: no-repeat;
    position: center;
  }
  height: 100vh;
  position: relative;

  @media only screen and (max-width: 768px) {
    background: {
      image: url('../images/splash_page/bg_splash_page_mobile.jpg');
      size: cover;
      repeat: no-repeat;
      position: center 80%;
    }
  }
}

// Home Page CTAS
.page-home__ctas {
  bottom: 40px;
  position: absolute;
  width: 100%;

  @media only screen and (max-width: 768px) {
    bottom: 0;
    flex-direction: column;
  }
}

.page-home__ctas {
  .cta-wrapper {
    padding: 15px;

    @media only screen and (max-width: 768px) {
      width: 75%;
      margin: 0 auto;
      padding: 15px 0;
    }

    @media only screen and (max-width: 640px) {
      width: 90%;
    }

    .cta {
      &:hover {
        .icon {
          background-position: center bottom;
        }
      }
    }
    &:nth-child(1) {
      .cta {
        background-color: $color_main_green;
        &:hover {
          color: $color_main_green;
          background-color: #fff;
        }
      }
    }

    &:nth-child(2) {
      .cta {
        background-color: $color_main_teal;
        &:hover {
          color: $color_main_teal;
          background-color: #fff;
        }
      }
    }

    @media only screen and (min-width: 992px) {
      &:first-child {
        position: relative;

        // Arrows
        &::before {
          content: '';
          height: 150px;
          width: 150px;
          position: absolute;
          background: {
            image: url('../images/splash_page/arrows/arrow-trail--home--to-cta.svg');
            size: 100%;
            repeat: no-repeat;
            position: center;
          }
          top: -60px;
          left: -180px;
        }
      }

      &:last-child {
        position: relative;

        &::after {
          content: '';
          height: 150px;
          width: 60px;
          position: absolute;
          background: {
            image: url('../images/splash_page/arrows/arrow-trail--home--to-promos.svg');
            size: 100%;
            repeat: no-repeat;
            position: center;
          }
          bottom: -100px;
          right: -200px;
        }
      }
    }
  }
}

.page-home__ctas .cta {
  border: {
    style: none;
    radius: 45px;
  }
  display: block;
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  padding: 15px 30px;
  text-align: center;
  transition: background-color 0.2s;
  color: #fff;
  text-decoration: none;
}

.page-home__ctas .cta .icon {
  width: 60px;
  height: 60px;
  margin-right: 15px;
  vertical-align: middle;
  background-size: 100%;
  background-position: center 0;
  background-repeat: no-repeat;
  display: inline-block;

  @media only screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
}

.page-home__ctas .cta span {
  font-size: 22px;
  font-size: 2.2rem;
  text-transform: uppercase;
  vertical-align: middle;
  transition: color 0.2s;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.page-home__promos {
  padding-bottom: 60px;

  @media only screen and (min-width: 768px) {
    margin-top: -40px;
  }
}
