@import url(https://use.typekit.net/hgw6eeb.css);
@import url(https://fonts.googleapis.com/css?family=Roboto);
/*
Project: Zafari
Author: Jollywise
Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal http://necolas.github.io/normalize.css/
*/
@font-face {
  font-family: 'sinzanoregular';
  src: url(../fonts/sinzano/typodermic_-_sinzano-regular-webfont.eot);
  src: url(../fonts/sinzano/typodermic_-_sinzano-regular-webfont.eot?#iefix) format("embedded-opentype"), url(../fonts/sinzano/typodermic_-_sinzano-regular-webfont.woff2) format("woff2"), url(../fonts/sinzano/typodermic_-_sinzano-regular-webfont.woff) format("woff"), url(../fonts/sinzano/typodermic_-_sinzano-regular-webfont.ttf) format("truetype"), url(../fonts/sinzano/typodermic_-_sinzano-regular-webfont.svg#sinzanoregular) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'sinzanodisplay_sans';
  src: url(../fonts/sinzano/sans/typodermic_-_sinzanodisplaysans-regular-webfont.woff2) format("woff2"), url(../fonts/sinzano/sans/typodermic_-_sinzanodisplaysans-regular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

.fade-block {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  z-index: 0;
  opacity: 0; }

.characters-page.fade-out .fade-block {
  display: block;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  opacity: 1; }

.characters-page.fade-in .fade-block {
  display: block;
  -webkit-transition: opacity 0.5s, -webkit-transform 0s 0.5s;
  transition: opacity 0.5s, -webkit-transform 0s 0.5s;
  transition: opacity 0.5s, transform 0s 0.5s;
  transition: opacity 0.5s, transform 0s 0.5s, -webkit-transform 0s 0.5s;
  opacity: 0;
  -webkit-transform: translate(-100%);
          transform: translate(-100%); }

.media-viewer.expand-enter.media-viewer--on-splash-page .media-viewer__container {
  padding-top: 0; }

.media-viewer.expand-enter .media-viewer__container {
  height: 0;
  min-height: 0; }

.media-viewer.expand-enter .media-viewer__media {
  opacity: 0; }

.media-viewer.expand-enter-active.media-viewer--on-splash-page .media-viewer__container {
  padding-top: 200px;
  -webkit-transition: min-height 0.5s, padding-top 0.5s;
  transition: min-height 0.5s, padding-top 0.5s; }

.media-viewer.expand-enter-active .media-viewer__container {
  min-height: 600px;
  -webkit-transition: min-height 0.5s;
  transition: min-height 0.5s; }

.media-viewer.expand-enter-active .media-viewer__media {
  opacity: 1;
  -webkit-transition: opacity 0.2s 0.3s;
  transition: opacity 0.2s 0.3s; }

.media-viewer.expand-enter-done.media-viewer--on-splash-page .media-viewer__container {
  padding-top: 200px; }

.media-viewer.expand-enter-done .media-viewer__container {
  min-height: 600px; }

.media-viewer.expand-enter-done .media-viewer__media {
  opacity: 1; }

.media-viewer.expand-exit {
  padding-top: 20px;
  padding-bottom: 20px; }
  .media-viewer.expand-exit.media-viewer--on-splash-page .media-viewer__container {
    padding-top: 200px; }
  .media-viewer.expand-exit .media-viewer__container {
    height: 0;
    min-height: 600px; }
  .media-viewer.expand-exit .media-viewer__media {
    opacity: 0; }

.media-viewer.expand-exit-active {
  padding-top: 0;
  padding-bottom: 0;
  -webkit-transition: padding-top 0.5s, padding-bottom 0.5s;
  transition: padding-top 0.5s, padding-bottom 0.5s; }
  .media-viewer.expand-exit-active.media-viewer--on-splash-page .media-viewer__container {
    padding-top: 0;
    -webkit-transition: padding-top 0.5s, min-height 0.5s;
    transition: padding-top 0.5s, min-height 0.5s; }
  .media-viewer.expand-exit-active .media-viewer__container {
    min-height: 0;
    -webkit-transition: min-height 0.5s;
    transition: min-height 0.5s; }
  .media-viewer.expand-exit-active .media-viewer__media {
    opacity: 0; }

.media-viewer.expand-exit-done.media-viewer--on-splash-page .media-viewer__container {
  padding-top: 0; }

.media-viewer.expand-exit-done .media-viewer__container {
  min-height: 0; }

.media-viewer.expand-exit-done .media-viewer__media {
  opacity: 0; }

@-webkit-keyframes flashingArrows {
  from {
    stroke: #fff; }
  50% {
    stroke: #61ae26; }
  to {
    stroke: #fff; } }

@keyframes flashingArrows {
  from {
    stroke: #fff; }
  50% {
    stroke: #61ae26; }
  to {
    stroke: #fff; } }

@-webkit-keyframes rockingAnimation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes rockingAnimation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@-webkit-keyframes fade-in-out-animation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-in-out-animation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes coconut-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom; }
  1.5% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg); }
  3% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  4.5% {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg); }
  6% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  7.5% {
    -webkit-transform: rotate(-3deg);
            transform: rotate(-3deg); }
  9% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  10.5% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg); }
  12% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  14% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  18% {
    -webkit-transform: translateY(-30px) scale(0.91, 1.02) rotate(2deg);
            transform: translateY(-30px) scale(0.91, 1.02) rotate(2deg); }
  22% {
    -webkit-transform: translateY(0px) scale(1, 1) rotate(0deg);
            transform: translateY(0px) scale(1, 1) rotate(0deg); }
  26% {
    -webkit-transform: translateY(-15px) scale(0.97, 1) rotate(-1deg);
            transform: translateY(-15px) scale(0.97, 1) rotate(-1deg); }
  29% {
    -webkit-transform: translateY(0px) scale(1, 1) rotate(0deg);
            transform: translateY(0px) scale(1, 1) rotate(0deg); }
  31% {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg); }
  32.5% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  55% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom; }
  56.5% {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg); }
  58% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  59.5% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg); }
  61% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  62.5% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg); }
  64% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  65.5% {
    -webkit-transform: rotate(-3deg);
            transform: rotate(-3deg); }
  67% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes coconut-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom; }
  1.5% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg); }
  3% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  4.5% {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg); }
  6% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  7.5% {
    -webkit-transform: rotate(-3deg);
            transform: rotate(-3deg); }
  9% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  10.5% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg); }
  12% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  14% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  18% {
    -webkit-transform: translateY(-30px) scale(0.91, 1.02) rotate(2deg);
            transform: translateY(-30px) scale(0.91, 1.02) rotate(2deg); }
  22% {
    -webkit-transform: translateY(0px) scale(1, 1) rotate(0deg);
            transform: translateY(0px) scale(1, 1) rotate(0deg); }
  26% {
    -webkit-transform: translateY(-15px) scale(0.97, 1) rotate(-1deg);
            transform: translateY(-15px) scale(0.97, 1) rotate(-1deg); }
  29% {
    -webkit-transform: translateY(0px) scale(1, 1) rotate(0deg);
            transform: translateY(0px) scale(1, 1) rotate(0deg); }
  31% {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg); }
  32.5% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  55% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom; }
  56.5% {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg); }
  58% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  59.5% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg); }
  61% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  62.5% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg); }
  64% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  65.5% {
    -webkit-transform: rotate(-3deg);
            transform: rotate(-3deg); }
  67% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@-webkit-keyframes coconut-shadow-animation {
  14% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  18% {
    -webkit-transform: scale(0.85, 0.9);
            transform: scale(0.85, 0.9); }
  22% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  26% {
    -webkit-transform: scale(0.92, 0.95);
            transform: scale(0.92, 0.95); }
  29% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@keyframes coconut-shadow-animation {
  14% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  18% {
    -webkit-transform: scale(0.85, 0.9);
            transform: scale(0.85, 0.9); }
  22% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  26% {
    -webkit-transform: scale(0.92, 0.95);
            transform: scale(0.92, 0.95); }
  29% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@-webkit-keyframes starburst-animation {
  0% {
    -webkit-transform: translate(-50%, 32%) rotate(0deg);
            transform: translate(-50%, 32%) rotate(0deg); }
  100% {
    -webkit-transform: translate(-50%, 32%) rotate(359deg);
            transform: translate(-50%, 32%) rotate(359deg); } }

@keyframes starburst-animation {
  0% {
    -webkit-transform: translate(-50%, 32%) rotate(0deg);
            transform: translate(-50%, 32%) rotate(0deg); }
  100% {
    -webkit-transform: translate(-50%, 32%) rotate(359deg);
            transform: translate(-50%, 32%) rotate(359deg); } }

.regot-loader {
  font-size: 20px;
  position: absolute;
  width: 4em;
  height: 1em;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.regot {
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  background: #ff9500;
  position: absolute;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.regot1,
.regot2 {
  left: 0; }

.regot3 {
  left: 1.5em; }

.regot4 {
  left: 3em; }

@-webkit-keyframes show-dot {
  from {
    -webkit-transform: scale(0.001);
            transform: scale(0.001); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes show-dot {
  from {
    -webkit-transform: scale(0.001);
            transform: scale(0.001); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes remove-dot {
  from {
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    -webkit-transform: scale(0.001);
            transform: scale(0.001); } }

@keyframes remove-dot {
  from {
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    -webkit-transform: scale(0.001);
            transform: scale(0.001); } }

@-webkit-keyframes shift-dot {
  to {
    -webkit-transform: translateX(1.5em);
            transform: translateX(1.5em); } }

@keyframes shift-dot {
  to {
    -webkit-transform: translateX(1.5em);
            transform: translateX(1.5em); } }

.regot1 {
  -webkit-animation-name: show-dot;
          animation-name: show-dot; }

.regot2,
.regot3 {
  -webkit-animation-name: shift-dot;
          animation-name: shift-dot; }

.regot4 {
  -webkit-animation-name: remove-dot;
          animation-name: remove-dot; }

/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

figure {
  line-height: 0; }

html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

body {
  margin: 0; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

a {
  background-color: transparent; }

abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

b,
strong {
  font-weight: bolder; }

code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

button,
input {
  /* 1 */
  overflow: visible; }

button,
select {
  /* 1 */
  text-transform: none; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type='checkbox'],
[type='radio'] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
.signup-form {
  text-align: center;
  position: relative; }

.signup-form__title {
  color: #ffffff;
  font-size: 36px; }

.signup-form__description {
  color: #ffffff;
  font-size: 22px;
  line-height: 26px;
  margin: 15px auto 30px;
  width: 90%; }
  @media only screen and (min-width: 768px) {
    .signup-form__description {
      width: 550px; } }

.signup-form__firstname,
.signup-form__lastname,
.signup-form__email {
  -webkit-box-shadow: inset 0px 13px 0px -6px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0px 13px 0px -6px rgba(0, 0, 0, 0.2);
  border-style: none;
  border-radius: 30px;
  display: block;
  height: 70px;
  font-size: 25px;
  margin: 10px auto;
  text-align: center;
  width: 90%; }
  @media only screen and (min-width: 768px) {
    .signup-form__firstname,
    .signup-form__lastname,
    .signup-form__email {
      width: 350px; } }
  .signup-form__firstname::-webkit-input-placeholder,
  .signup-form__lastname::-webkit-input-placeholder,
  .signup-form__email::-webkit-input-placeholder {
    color: #000000; }
  .signup-form__firstname:-ms-input-placeholder,
  .signup-form__lastname:-ms-input-placeholder,
  .signup-form__email:-ms-input-placeholder {
    color: #000000; }
  .signup-form__firstname::-ms-input-placeholder,
  .signup-form__lastname::-ms-input-placeholder,
  .signup-form__email::-ms-input-placeholder {
    color: #000000; }
  .signup-form__firstname::placeholder,
  .signup-form__lastname::placeholder,
  .signup-form__email::placeholder {
    color: #000000; }

.signup-form__submit {
  color: #ffffff;
  border-style: none;
  border-radius: 35px;
  background-color: #ff9500;
  cursor: pointer;
  padding: 15px 60px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto; }

.signup-form__submit__title {
  font-size: 32px; }

.signup-form__submit__icon {
  margin-right: 20px; }
  .signup-form__submit__icon svg {
    width: 50px; }

.signup-form__status {
  font-size: 32px; }
  .signup-form__status--success {
    color: #61ae26; }
  .signup-form__status--error {
    color: red; }

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
body,
button,
input,
select,
optgroup,
textarea {
  font-family: "ff-prater-sans-web", sans-serif;
  font-weight: normal;
  line-height: normal; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.bold {
  font-weight: bold; }

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  font-weight: normal; }

h1 {
  font-size: 2.6em; }

h2 {
  font-size: 2.5em; }

h3 {
  font-size: 3em; }

h4 {
  font-size: 1.3em; }

/*--------------------------------------------------------------
# Menus
--------------------------------------------------------------*/
.social-menu__list {
  list-style: none;
  margin-top: 20px;
  margin-right: 40%;
  text-align: right; }
  @media only screen and (min-width: 768px) {
    .social-menu__list {
      margin-left: 20%;
      margin-right: 0;
      text-align: left; } }

.social-menu__link {
  display: inline-block;
  margin: auto 7px; }

.social-menu__link__icon svg,
.social-menu__link__icon {
  width: 26px; }

/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/
.navigation {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100; }
  .navigation--my-contents .navigation__toggle {
    right: 15px;
    top: 15px; }
    @media only screen and (min-width: 768px) {
      .navigation--my-contents .navigation__toggle {
        right: 40px;
        top: 25px; } }
    @media only screen and (min-width: 992px) {
      .navigation--my-contents .navigation__toggle {
        right: 90px; } }
  .navigation--my-contents .navigation__menu-burger__wrapper,
  .navigation--my-contents .navigation__sound__wrapper {
    background-color: rgba(255, 255, 255, 0.6); }
  .navigation--my-contents .navigation__menu-burger-line,
  .navigation--my-contents .navigation__menu-burger:before,
  .navigation--my-contents .navigation__menu-burger:after,
  .navigation--my-contents .navigation__menu-burger .navigation__menu-burger-line {
    background-color: black; }

.navigation__toggle {
  position: absolute;
  top: 25px;
  right: 60px; }
  @media only screen and (max-width: 768px) {
    .navigation__toggle {
      right: 15px; } }

.navigation__title {
  color: #ffffff;
  font-size: 14px;
  font-size: 1.4rem;
  margin-right: 10px;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.5); }
  @media only screen and (max-width: 768px) {
    .navigation__title {
      display: none; } }

.navigation__items-group {
  background-color: rgba(0, 0, 0, 0.7);
  position: relative;
  min-height: 450px;
  min-width: 450px;
  border-radius: 50%;
  margin-top: -20%;
  right: -30%;
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 70px;
  padding-left: 50px; }
  @media only screen and (min-width: 768px) {
    .navigation__items-group {
      right: -20%; } }

.navigation__list {
  width: 60%;
  margin-top: 35%;
  margin-right: 40%;
  margin-left: auto; }
  @media only screen and (min-width: 768px) {
    .navigation__list {
      margin-right: 0;
      margin-left: 0; } }

.navigation__link {
  list-style: none;
  margin-top: 5px;
  font-size: 12px;
  font-size: 1.2rem; }
  .navigation__link a {
    color: #ffffff;
    text-decoration: none; }
    .navigation__link a.active, .navigation__link a:hover {
      color: #ff9500; }
      .navigation__link a.active .navigation__link__icon, .navigation__link a:hover .navigation__link__icon {
        background-position: center bottom; }
      .navigation__link a.active img, .navigation__link a:hover img {
        fill: #ff9500; }

@media only screen and (min-width: 768px) {
  .navigation__link__title {
    min-width: 135px;
    text-align: right; } }

.navigation__link__icon {
  margin-left: 10px; }

.navigation__link__icon svg,
.navigation__link__icon {
  width: 26px;
  height: 26px;
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: 100%; }

.navigation__items-group__container {
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: top right;
          transform-origin: top right;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
  .navigation__items-group__container--active {
    -webkit-transform-origin: top right;
            transform-origin: top right;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

.navigation__sound__wrapper .sound-on-icon {
  width: 40px; }

.navigation__sound__wrapper .sound-off-icon {
  width: 40px; }

.navigation__menu-burger {
  width: 30px;
  height: 26px;
  position: relative; }
  .navigation__menu-burger:before, .navigation__menu-burger:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 5px;
    border-radius: 7px;
    background-color: white; }
  .navigation__menu-burger:before {
    top: -0px;
    -webkit-transition: top 0.3s 0.3s, background-color 0.3s ease-in-out, -webkit-transform 0.3s;
    transition: top 0.3s 0.3s, background-color 0.3s ease-in-out, -webkit-transform 0.3s;
    transition: transform 0.3s, top 0.3s 0.3s, background-color 0.3s ease-in-out;
    transition: transform 0.3s, top 0.3s 0.3s, background-color 0.3s ease-in-out, -webkit-transform 0.3s; }
  .navigation__menu-burger:after {
    bottom: -1px;
    -webkit-transition: bottom 0.3s 0.3s, background-color 0.3s ease-in-out, -webkit-transform 0.3s;
    transition: bottom 0.3s 0.3s, background-color 0.3s ease-in-out, -webkit-transform 0.3s;
    transition: transform 0.3s, bottom 0.3s 0.3s, background-color 0.3s ease-in-out;
    transition: transform 0.3s, bottom 0.3s 0.3s, background-color 0.3s ease-in-out, -webkit-transform 0.3s; }
  .navigation__menu-burger--active .navigation__menu-burger-line {
    opacity: 0; }
  .navigation__menu-burger--active:before {
    top: 10px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: top 0.3s, -webkit-transform 0.3s 0.3s;
    transition: top 0.3s, -webkit-transform 0.3s 0.3s;
    transition: transform 0.3s 0.3s, top 0.3s;
    transition: transform 0.3s 0.3s, top 0.3s, -webkit-transform 0.3s 0.3s; }
  .navigation__menu-burger--active:after {
    bottom: 11px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: bottom 0.3s, -webkit-transform 0.3s 0.3s;
    transition: bottom 0.3s, -webkit-transform 0.3s 0.3s;
    transition: transform 0.3s 0.3s, bottom 0.3s;
    transition: transform 0.3s 0.3s, bottom 0.3s, -webkit-transform 0.3s 0.3s; }

.navigation__menu-burger .navigation__menu-burger-line {
  position: relative;
  width: 100%;
  height: 5px;
  border-radius: 7px;
  background-color: white;
  -webkit-transition: opacity 0.3s, background-color 0.3s ease-in-out, -webkit-transform 0.3s;
  transition: opacity 0.3s, background-color 0.3s ease-in-out, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, background-color 0.3s ease-in-out;
  transition: opacity 0.3s, transform 0.3s, background-color 0.3s ease-in-out, -webkit-transform 0.3s; }

.navigation__menu-burger__wrapper,
.navigation__sound__wrapper {
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 50%;
  height: 60px;
  width: 60px;
  position: relative;
  z-index: 6;
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out; }
  .navigation__menu-burger__wrapper:hover,
  .navigation__sound__wrapper:hover {
    background-color: #fff;
    cursor: pointer; }
    .navigation__menu-burger__wrapper:hover .navigation__menu-burger .navigation__menu-burger-line,
    .navigation__sound__wrapper:hover .navigation__menu-burger .navigation__menu-burger-line {
      background-color: #000; }
    .navigation__menu-burger__wrapper:hover .navigation__menu-burger:before, .navigation__menu-burger__wrapper:hover .navigation__menu-burger:after,
    .navigation__sound__wrapper:hover .navigation__menu-burger:before,
    .navigation__sound__wrapper:hover .navigation__menu-burger:after {
      background-color: #000; }
    .navigation__menu-burger__wrapper:hover .sound-on-icon .cls-2,
    .navigation__menu-burger__wrapper:hover .sound-off-icon .cls-2,
    .navigation__sound__wrapper:hover .sound-on-icon .cls-2,
    .navigation__sound__wrapper:hover .sound-off-icon .cls-2 {
      fill: #000; }

.navigation__sound {
  position: relative; }

.navigation__sound__wrapper {
  margin-right: 10px; }
  @media only screen and (min-width: 768px) {
    .navigation__sound__wrapper {
      margin-right: 15px; } }
  @media only screen and (min-width: 1300px) {
    .navigation__sound__wrapper {
      margin-right: 25px; } }

.navigation__container.active .navigation__menu-burger-line,
.navigation__container.active .navigation__menu-burger:before,
.navigation__container.active .navigation__menu-burger:after {
  background-color: black; }

.navigation__container.active .navigation__menu-burger__wrapper,
.navigation__container.active .navigation__sound__wrapper {
  background-color: rgba(255, 255, 255, 0.6); }

@media only screen and (min-width: 1024px) {
  .navigation__container--splash-page .navigation__list {
    width: 50%; }
  .navigation__container--splash-page .navigation__toggle {
    right: 100px; } }

/*--------------------------------------------------------------
# Overlays
--------------------------------------------------------------*/
.popup-window {
  position: fixed;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.popup-wrapper {
  position: absolute;
  width: 100%;
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 50px;
  padding-bottom: 50px; }

.popup-contents-wrapper {
  position: relative;
  width: 90%;
  height: 100%;
  max-height: 100%;
  max-width: 640px; }

.popup-contents {
  background-color: #fff;
  border-radius: 25px;
  padding: 40px 20px; }

.close-button {
  background-color: #fff;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  position: relative;
  z-index: 6;
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
  margin-left: auto;
  margin-bottom: 20px; }
  .close-button:hover {
    cursor: pointer;
    background-color: #61ae26; }
    .close-button:hover:before {
      background-color: #fff; }
    .close-button:hover:after {
      background-color: #fff; }
  .close-button:before, .close-button:after {
    position: absolute;
    content: '';
    display: block;
    width: 30px;
    height: 5px;
    top: 50%;
    left: 50%;
    background-color: black;
    border-radius: 15px;
    -webkit-transition: background-color 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out; }
  .close-button:before {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg); }
  .close-button:after {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
            transform: translate(-50%, -50%) rotate(-45deg); }

.wrapper-game {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  height: 1px !important;
  width: 1px !important;
  min-height: 100%;
  min-width: 100%;
  z-index: 1; }

.wrapper-game-iframe {
  height: 100%;
  width: 100%; }

@-webkit-keyframes downwards {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes downwards {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.navigation__container.active .mute-message-overlay {
  z-index: -1; }

.mute-message-overlay {
  position: absolute;
  top: calc(100% + 10px);
  background-color: white;
  border-radius: 10px;
  text-align: center;
  padding: 5px 20px;
  left: -30px;
  width: 120px;
  z-index: 10;
  -webkit-animation: downwards 0.6s ease-in-out 1;
          animation: downwards 0.6s ease-in-out 1;
  -webkit-box-shadow: 0px 0px 10px 0px black;
  box-shadow: 0px 0px 10px 0px black; }
  .mute-message-overlay:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 15px 5px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    top: -15px;
    left: calc(50% - 5px); }

.mute-message-overlay__text {
  font-size: 0.9rem;
  line-height: 1rem; }

.mute-message-overlay__close {
  position: absolute;
  right: -8px;
  top: -8px; }

.mute-message-overlay__close-button {
  width: 13px;
  height: 26px;
  position: relative; }
  .mute-message-overlay__close-button:before, .mute-message-overlay__close-button:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    border-radius: 7px;
    background-color: #000;
    -webkit-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out; }
  .mute-message-overlay__close-button:before {
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(45deg);
            transform: translateY(-50%) rotate(45deg); }
  .mute-message-overlay__close-button:after {
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(-45deg);
            transform: translateY(-50%) rotate(-45deg); }

.mute-message-overlay__close-button__wrapper {
  background-color: #fff;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  position: relative;
  z-index: 6;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out; }
  .mute-message-overlay__close-button__wrapper:hover {
    cursor: pointer;
    background-color: #61ae26; }
    .mute-message-overlay__close-button__wrapper:hover .mute-message-overlay__close-button:before, .mute-message-overlay__close-button__wrapper:hover .mute-message-overlay__close-button:after {
      background-color: #fff; }

/*--------------------------------------------------------------
# Site Sections
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Grid
--------------------------------------------------------------*/
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%; }

@media (min-width: 768px) {
  .container {
    width: 750px; } }

@media (min-width: 992px) {
  .container {
    width: 850px; } }

@media (min-width: 1300px) {
  .container {
    width: 1270px; } }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -15px;
  margin-right: -15px; }

.col-xxs-1,
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xxs-2,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xxs-3,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xxs-4,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xxs-5,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xxs-6,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xxs-7,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xxs-8,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xxs-9,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xxs-10,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xxs-11,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xxs-12,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

.col-xxs-12 {
  width: 100%; }

.col-xxs-11 {
  width: 91.66666667%; }

.col-xxs-10 {
  width: 83.33333333%; }

.col-xxs-9 {
  width: 75%; }

.col-xxs-8 {
  width: 66.66666667%; }

.col-xxs-7 {
  width: 58.33333333%; }

.col-xxs-6 {
  width: 50%; }

.col-xxs-5 {
  width: 41.66666667%; }

.col-xxs-4 {
  width: 33.33333333%; }

.col-xxs-3 {
  width: 25%; }

.col-xxs-2 {
  width: 16.66666667%; }

.col-xxs-1 {
  width: 8.33333333%; }

@media (min-width: 640px) {
  .col-xs-12 {
    width: 100%; }
  .col-xs-11 {
    width: 91.66666667%; }
  .col-xs-10 {
    width: 83.33333333%; }
  .col-xs-9 {
    width: 75%; }
  .col-xs-8 {
    width: 66.66666667%; }
  .col-xs-7 {
    width: 58.33333333%; }
  .col-xs-6 {
    width: 50%; }
  .col-xs-5 {
    width: 41.66666667%; }
  .col-xs-4 {
    width: 33.33333333%; }
  .col-xs-3 {
    width: 25%; }
  .col-xs-2 {
    width: 16.66666667%; }
  .col-xs-1 {
    width: 8.33333333%; } }

@media (min-width: 768px) {
  .col-sm-12 {
    width: 100%; }
  .col-sm-11 {
    width: 91.66666667%; }
  .col-sm-10 {
    width: 83.33333333%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-8 {
    width: 66.66666667%; }
  .col-sm-7 {
    width: 58.33333333%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-5 {
    width: 41.66666667%; }
  .col-sm-4 {
    width: 33.33333333%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-2 {
    width: 16.66666667%; }
  .col-sm-1 {
    width: 8.33333333%; } }

@media (min-width: 992px) {
  .col-md-12 {
    width: 100%; }
  .col-md-11 {
    width: 91.66666667%; }
  .col-md-10 {
    width: 83.33333333%; }
  .col-md-9 {
    width: 75%; }
  .col-md-8 {
    width: 66.66666667%; }
  .col-md-7 {
    width: 58.33333333%; }
  .col-md-6 {
    width: 50%; }
  .col-md-5 {
    width: 41.66666667%; }
  .col-md-4 {
    width: 33.33333333%; }
  .col-md-3 {
    width: 25%; }
  .col-md-2 {
    width: 16.66666667%; }
  .col-md-1 {
    width: 8.33333333%; } }

@media (min-width: 1300px) {
  .col-lg-12 {
    width: 100%; }
  .col-lg-11 {
    width: 91.66666667%; }
  .col-lg-10 {
    width: 83.33333333%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-8 {
    width: 66.66666667%; }
  .col-lg-7 {
    width: 58.33333333%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-5 {
    width: 41.66666667%; }
  .col-lg-4 {
    width: 33.33333333%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-2 {
    width: 16.66666667%; }
  .col-lg-1 {
    width: 8.33333333%; } }

/*--------------------------------------------------------------
## Generic layout
--------------------------------------------------------------*/
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.flex-wrap {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.flex-nowrap {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse; }

.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row; }

.flex-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse; }

.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.flex-column-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse; }

.justify-content-start {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }

.justify-content-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.justify-content-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.justify-content-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.justify-content-around {
  -ms-flex-pack: distribute;
      justify-content: space-around; }

.align-items-start {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }

.align-items-end {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end; }

.align-items-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.align-items-baseline {
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline; }

.align-items-stretch {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; }

.flex-grow-0 {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0; }

.flex-shrink-0 {
  -ms-flex-negative: 0;
      flex-shrink: 0; }

.flex-grow-1 {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

.flex-shrink-1 {
  -ms-flex-negative: 1;
      flex-shrink: 1; }

/*--------------------------------------------------------------
## Containers
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Content
--------------------------------------------------------------*/
.cookie-bar {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  background-color: #ff9500;
  -webkit-box-shadow: 0px 7px 0px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 7px 0px 0px rgba(0, 0, 0, 0.2);
  color: #000000;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.1;
  padding: 16px 8px;
  text-align: center;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  width: 100%;
  z-index: 999;
  -webkit-transform: translateY(200px);
          transform: translateY(200px); }
  .cookie-bar--active {
    -webkit-transform: translateY(0);
            transform: translateY(0); }

.cookie-bar__container {
  width: 100%;
  max-width: 980px;
  margin: 0 auto; }

.cookie-bar__popup-text,
.cookie-bar__popup-buttons {
  display: inline-block;
  vertical-align: top; }
  @media only screen and (max-width: 768px) {
    .cookie-bar__popup-text,
    .cookie-bar__popup-buttons {
      width: 100%;
      text-align: center;
      padding-bottom: 10px; } }

.cookie-bar__popup-buttons {
  text-align: right;
  width: 38%; }

.cookie-bar__popup-text {
  padding-right: 10px;
  text-align: left;
  width: 58%;
  font-family: Arial, sans-serif; }

.cookie-bar__title,
.cookie-bar__text {
  font-family: "ff-prater-sans-web", sans-serif;
  font-size: 15px; }

.cookie-bar__text {
  margin: 0; }

.cookie-bar__popup-buttons__button {
  border-style: none;
  border-radius: 45px;
  -webkit-box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 5px;
  margin-left: 15px;
  min-height: 45px;
  padding: 0 15px;
  text-align: center;
  text-decoration: none; }
  .cookie-bar__popup-buttons__button a {
    text-decoration: none; }
  .cookie-bar__popup-buttons__button--agree-button {
    background-color: #000000;
    color: #ffffff; }
    .cookie-bar__popup-buttons__button--agree-button:hover {
      background-color: #ffffff;
      color: #000000; }
  .cookie-bar__popup-buttons__button--cookie-more {
    background-color: #ffffff; }
    .cookie-bar__popup-buttons__button--cookie-more a {
      color: #000000; }
    .cookie-bar__popup-buttons__button--cookie-more:hover {
      background-color: #000000; }
      .cookie-bar__popup-buttons__button--cookie-more:hover a {
        color: #ffffff; }

.media-viewer {
  position: relative;
  background-color: black;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden; }
  .media-viewer .leaf_pattern {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(../images/characters/shared/leaf_pattern.png);
    background-size: auto 350px;
    background-position: top left;
    opacity: 0.15;
    top: 0;
    z-index: 0; }
  .media-viewer .media-viewer__container {
    width: 100%;
    min-height: 600px;
    padding-left: 75px;
    padding-right: 75px; }
  .media-viewer.media-viewer--on-splash-page .media-viewer__container {
    padding-top: 200px; }

.media-viewer__media {
  width: 100%;
  max-width: 600px;
  position: relative; }
  .media-viewer__media.game {
    max-width: 1300px; }
  .media-viewer__media img {
    width: 100%; }
  .media-viewer__media .game-wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%; }
  .media-viewer__media .game-iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }

.media-viewer__close {
  position: absolute;
  right: -70px;
  top: 0px; }

.media-viewer__close-button {
  width: 34px;
  height: 26px;
  position: relative; }
  .media-viewer__close-button:before, .media-viewer__close-button:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 7px;
    border-radius: 7px;
    background-color: black;
    -webkit-transition: background-color 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out; }
  .media-viewer__close-button:before {
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(45deg);
            transform: translateY(-50%) rotate(45deg); }
  .media-viewer__close-button:after {
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(-45deg);
            transform: translateY(-50%) rotate(-45deg); }

.media-viewer__close-button__wrapper {
  background-color: #fff;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  position: relative;
  z-index: 6;
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out; }
  .media-viewer__close-button__wrapper:hover {
    cursor: pointer;
    background-color: #61ae26; }
    .media-viewer__close-button__wrapper:hover .media-viewer__close-button:before, .media-viewer__close-button__wrapper:hover .media-viewer__close-button:after {
      background-color: #fff; }

.media-viewer__media__description {
  color: #ffffff;
  font-size: 20px;
  font-size: 1.25rem;
  margin-top: 10px; }

/*--------------------------------------------------------------
## Pages
--------------------------------------------------------------*/
.page-home {
  background-color: #000000; }

.page-home__bg-container {
  background-color: #000000;
  background-image: url(../images/splash_page/bg_splash_page.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .page-home__bg-container {
      background-image: url(../images/splash_page/bg_splash_page_mobile.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center 80%; } }

.page-home__ctas {
  bottom: 40px;
  position: absolute;
  width: 100%; }
  @media only screen and (max-width: 768px) {
    .page-home__ctas {
      bottom: 0;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; } }

.page-home__ctas .cta-wrapper {
  padding: 15px; }
  @media only screen and (max-width: 768px) {
    .page-home__ctas .cta-wrapper {
      width: 75%;
      margin: 0 auto;
      padding: 15px 0; } }
  @media only screen and (max-width: 640px) {
    .page-home__ctas .cta-wrapper {
      width: 90%; } }
  .page-home__ctas .cta-wrapper .cta:hover .icon {
    background-position: center bottom; }
  .page-home__ctas .cta-wrapper:nth-child(1) .cta {
    background-color: #61ae26; }
    .page-home__ctas .cta-wrapper:nth-child(1) .cta:hover {
      color: #61ae26;
      background-color: #fff; }
  .page-home__ctas .cta-wrapper:nth-child(2) .cta {
    background-color: #26a7a5; }
    .page-home__ctas .cta-wrapper:nth-child(2) .cta:hover {
      color: #26a7a5;
      background-color: #fff; }
  @media only screen and (min-width: 992px) {
    .page-home__ctas .cta-wrapper:first-child {
      position: relative; }
      .page-home__ctas .cta-wrapper:first-child::before {
        content: '';
        height: 150px;
        width: 150px;
        position: absolute;
        background-image: url(../images/splash_page/arrows/arrow-trail--home--to-cta.svg);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        top: -60px;
        left: -180px; }
    .page-home__ctas .cta-wrapper:last-child {
      position: relative; }
      .page-home__ctas .cta-wrapper:last-child::after {
        content: '';
        height: 150px;
        width: 60px;
        position: absolute;
        background-image: url(../images/splash_page/arrows/arrow-trail--home--to-promos.svg);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        bottom: -100px;
        right: -200px; } }

.page-home__ctas .cta {
  border-style: none;
  border-radius: 45px;
  display: block;
  -webkit-box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  padding: 15px 30px;
  text-align: center;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  color: #fff;
  text-decoration: none; }

.page-home__ctas .cta .icon {
  width: 60px;
  height: 60px;
  margin-right: 15px;
  vertical-align: middle;
  background-size: 100%;
  background-position: center 0;
  background-repeat: no-repeat;
  display: inline-block; }
  @media only screen and (max-width: 768px) {
    .page-home__ctas .cta .icon {
      width: 40px;
      height: 40px; } }

.page-home__ctas .cta span {
  font-size: 22px;
  font-size: 2.2rem;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-transition: color 0.2s;
  transition: color 0.2s; }
  @media only screen and (max-width: 768px) {
    .page-home__ctas .cta span {
      font-size: 18px;
      font-size: 1.8rem; } }

.page-home__promos {
  padding-bottom: 60px; }
  @media only screen and (min-width: 768px) {
    .page-home__promos {
      margin-top: -40px; } }

.characters-page {
  position: relative; }

.prevent-scrolling {
  overflow: hidden; }

.page-my-contents {
  background-image: url(../images/characters/shared/leaf_pattern.png), -webkit-gradient(linear, left top, right top, from(#cddbde), color-stop(35%, white), color-stop(65%, white), to(#cddbde));
  background-image: url(../images/characters/shared/leaf_pattern.png), linear-gradient(to right, #cddbde 0%, white 35%, white 65%, #cddbde 100%);
  background-size: auto 350px;
  background-position: top left;
  padding-bottom: 100px; }

.page-my-contents__page-header {
  padding-top: 40px;
  margin-bottom: 80px;
  position: relative; }

.page-my-contents__title {
  font-size: 35px;
  font-size: 2.1875rem; }

.page-my-contents__dropdown {
  background-color: #ff9500;
  border-radius: 45px;
  -webkit-box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.2);
  margin-left: auto;
  padding: 20px 25px; }
  @media only screen and (min-width: 720px) {
    .page-my-contents__dropdown {
      position: absolute;
      right: 0;
      top: 40px;
      z-index: 5; } }

.page-my-contents__dropdown__button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%; }
  @media only screen and (min-width: 720px) {
    .page-my-contents__dropdown__button {
      width: auto; } }
  .page-my-contents__dropdown__button svg {
    width: auto;
    height: 25px;
    margin-left: auto; }
    @media only screen and (min-width: 720px) {
      .page-my-contents__dropdown__button svg {
        margin-left: 15px; } }

.page-my-contents__dropdown__button__title {
  color: #ffffff;
  font-size: 25px;
  font-size: 1.5625rem; }
  @media only screen and (min-width: 720px) {
    .page-my-contents__dropdown__button__title {
      font-size: 35px;
      font-size: 2.1875rem; } }

.page-my-contents__dropdown__list {
  height: 0;
  list-style: none;
  visibility: hidden;
  -webkit-transition: height 0.3s ease-in-out;
  transition: height 0.3s ease-in-out; }
  .page-my-contents__dropdown__list--active {
    height: auto;
    visibility: visible; }

.page-my-contents__dropdown__list li {
  color: #ffffff;
  cursor: pointer;
  font-size: 22px;
  padding-left: 10px; }

.page-my-contents__more-button__container {
  position: relative;
  z-index: 1;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 300px; }
  @media only screen and (min-width: 992px) {
    .page-my-contents__more-button__container {
      max-width: 450px; } }

.page-my-contents__more-button {
  background-color: #61ae26;
  color: #fff;
  border-style: none;
  border-radius: 40px;
  -webkit-box-shadow: 0 3px 0px 4px rgba(0, 0, 0, 0.2);
          box-shadow: 0 3px 0px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  width: 100%;
  -webkit-transition: background-color 0.2s, color 0.2s;
  transition: background-color 0.2s, color 0.2s; }
  .page-my-contents__more-button:hover {
    background-color: #fff;
    color: #61ae26; }
    .page-my-contents__more-button:hover .more-btn .cls-2 {
      fill: #61ae26; }
  @media only screen and (min-width: 992px) {
    .page-my-contents__more-button {
      padding: 22px;
      border-radius: 200px; } }

.page-my-contents__more-button__icon {
  width: 40px; }
  @media only screen and (min-width: 992px) {
    .page-my-contents__more-button__icon {
      width: 80px; } }

.page-my-contents__more-button__title {
  font-size: 1.203125rem; }
  @media only screen and (min-width: 992px) {
    .page-my-contents__more-button__title {
      font-size: 3rem; } }

.page-game {
  padding-bottom: 100px;
  position: relative; }
  .page-game .leaf_pattern {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(../images/characters/shared/leaf_pattern.png);
    background-size: auto 350px;
    background-position: top left;
    opacity: 0.15;
    top: 0;
    z-index: 0; }
  .page-game .game-iframe-wrapper {
    position: relative;
    z-index: 3;
    width: 100%;
    padding-top: 56.25%; }
  .page-game iframe {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

.page-game__loader,
.page-game__loader:before,
.page-game__loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out; }

.page-game__loader {
  z-index: 1;
  left: 50%;
  margin-left: -2.5em;
  top: 40%;
  color: #ff9500;
  font-size: 10px;
  margin: 80px auto;
  position: absolute;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

.page-game__loader:before,
.page-game__loader:after {
  content: '';
  position: absolute;
  top: 0; }

.page-game__loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.page-game__loader:after {
  left: 3.5em; }

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    -webkit-box-shadow: 0 2.5em 0 -1.3em;
            box-shadow: 0 2.5em 0 -1.3em; }
  40% {
    -webkit-box-shadow: 0 2.5em 0 0;
            box-shadow: 0 2.5em 0 0; } }

@keyframes load7 {
  0%,
  80%,
  100% {
    -webkit-box-shadow: 0 2.5em 0 -1.3em;
            box-shadow: 0 2.5em 0 -1.3em; }
  40% {
    -webkit-box-shadow: 0 2.5em 0 0;
            box-shadow: 0 2.5em 0 0; } }

/*--------------------------------------------------------------
## Sections
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Site generic styles
--------------------------------------------------------------*/
html,
body {
  min-height: 100%;
  background-color: #000; }

#app {
  position: relative; }

body {
  overflow-x: hidden;
  min-height: 320px; }

svg {
  display: block;
  width: 100%;
  height: auto; }

.arrow .cls-1 {
  opacity: 0.35; }

.arrow .cls-2,
.arrow .cls-3 {
  fill: none;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 40px; }

.arrow .cls-2 {
  stroke: #000; }

.arrow .cls-3 {
  stroke: #fff;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s; }

.arrow.down-arrow .cls-2,
.arrow.down-arrow .cls-3 {
  stroke-width: 20px; }

.arrow:hover {
  cursor: pointer; }
  .arrow:hover .cls-3 {
    stroke: #61ae26; }

.down-arrow:hover {
  cursor: pointer; }
  .down-arrow:hover .cls-3 {
    stroke: #fff; }

.arrow-thin .cls-1 {
  opacity: 0.35; }

.arrow-thin .cls-2,
.arrow-thin .cls-3 {
  fill: none;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 13.16px; }

.arrow-thin .cls-2 {
  stroke: #000; }

.arrow-thin .cls-3 {
  stroke: #fff; }

.play-button .cls-1 {
  opacity: 0.2; }

.play-button .cls-2 {
  opacity: 0.75; }

.play-button .cls-3 {
  opacity: 0.35; }

.play-button .cls-4 {
  fill: #fff; }

.trait-stripe .cls-1 {
  fill: none; }

.trait-stripe .cls-2 {
  isolation: isolate; }

.trait-stripe .cls-3 {
  mix-blend-mode: hard-light; }

.trait-stripe .cls-4 {
  clip-path: url(#stripe_clip_path); }

.more-btn .cls-1 {
  opacity: 0.25; }

.more-btn .cls-2 {
  fill: #fff;
  -webkit-transition: fill 0.2s;
  transition: fill 0.2s; }

.envelope-icon .cls-1 {
  opacity: 0.25; }

.envelope-icon .cls-2 {
  fill: #fff; }

.facebook-icon .cls-1 {
  fill: #4267b2; }

.facebook-icon .cls-2 {
  fill: #fff; }

.twitter-icon .cls-1 {
  fill: #1da1f2; }

.instagram-icon .cls-1 {
  fill: #fff; }

.sound-on-icon .cls-1,
.sound-off-icon .cls-1 {
  opacity: 0.35; }

.sound-on-icon .cls-2,
.sound-off-icon .cls-2 {
  -webkit-transition: fill 0.3s ease-in-out;
  transition: fill 0.3s ease-in-out;
  fill: #fff; }

.button {
  border-radius: 40px;
  background-color: #61ae26;
  color: #fff;
  width: 100%;
  padding: 10px;
  -webkit-box-shadow: 0 8px 1px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0 8px 1px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: background-color 0.2s, color 0.2s;
  transition: background-color 0.2s, color 0.2s; }
  .button:hover {
    cursor: pointer;
    background-color: #fff;
    color: #61ae26; }
    .button:hover .more-btn .cls-2 {
      fill: #61ae26; }
  .button .icon-wrapper {
    width: 40px; }
  .button .button-label {
    padding: 0 5px;
    text-transform: uppercase;
    font-size: 1.5rem; }

.dashed-arrow-to-info .cls-1,
.dashed-arrow-to-specials .cls-1,
.dashed-arrow-to-previous-character .cls-1,
.dashed-arrow-to-previous-character .cls-1 {
  opacity: 0.2; }

.dashed-arrow-to-info .cls-2,
.dashed-arrow-to-info .cls-3,
.dashed-arrow-to-specials .cls-2,
.dashed-arrow-to-specials .cls-3,
.dashed-arrow-to-previous-character .cls-2,
.dashed-arrow-to-previous-character .cls-3,
.dashed-arrow-to-previous-character .cls-2,
.dashed-arrow-to-previous-character .cls-3 {
  fill: none;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 4px;
  stroke-dashoffset: 100;
  stroke-dasharray: 20 20;
  -webkit-animation: dash 2s linear forwards;
          animation: dash 2s linear forwards; }

.dashed-arrow-to-info .cls-2,
.dashed-arrow-to-specials .cls-2,
.dashed-arrow-to-previous-character .cls-2,
.dashed-arrow-to-previous-character .cls-2 {
  stroke: #000; }

.dashed-arrow-to-info .cls-3,
.dashed-arrow-to-specials .cls-3,
.dashed-arrow-to-previous-character .cls-3,
.dashed-arrow-to-previous-character .cls-3 {
  stroke: #fff; }

.dashed-arrow-to-info .cls-4,
.dashed-arrow-to-specials .cls-4,
.dashed-arrow-to-previous-character .cls-4,
.dashed-arrow-to-previous-character .cls-4 {
  fill: #fff; }

.unlocked-padlock .cls-1 {
  fill: #fff; }

.coconut-size-wrap {
  width: 20%;
  position: absolute;
  right: 2%; }
  .coconut-size-wrap .coconut-wrapper {
    position: relative;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    width: 100%;
    padding-top: 78%; }
    .coconut-size-wrap .coconut-wrapper:hover {
      cursor: pointer; }
    .coconut-size-wrap .coconut-wrapper .coconut {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 95%;
      background-image: url(../images/contents_page/coconut.png);
      background-position: center;
      background-size: contain;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden; }
    .coconut-size-wrap .coconut-wrapper .coconut-shadow {
      position: absolute;
      width: 96%;
      height: 20%;
      left: 2%;
      bottom: -3%;
      background-image: url(../images/contents_page/coconut-shadow.png);
      background-position: center;
      background-size: contain;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden; }
  .coconut-size-wrap.unlocked .coconut-wrapper {
    padding-top: 0; }
    .coconut-size-wrap.unlocked .coconut-wrapper .coconut-shadow {
      -webkit-animation: none;
              animation: none;
      width: 100%;
      padding-top: 15.0838%;
      height: auto;
      bottom: -6%;
      -webkit-transform: scale(0.9);
              transform: scale(0.9); }
    .coconut-size-wrap.unlocked .coconut-wrapper .starburst {
      position: absolute;
      left: 50%;
      bottom: 0%;
      -webkit-transform: translate(-50%, 32%);
              transform: translate(-50%, 32%);
      width: 200%;
      padding-top: 169%;
      background-image: url(../images/contents_page/starburst.png);
      background-position: center;
      background-size: contain;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      background-repeat: no-repeat;
      -webkit-animation: starburst-animation 7s infinite linear;
              animation: starburst-animation 7s infinite linear; }
    .coconut-size-wrap.unlocked .coconut-wrapper .text-wrapper {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      color: #fff;
      text-shadow: 0px 3px rgba(0, 0, 0, 0.5);
      text-align: center;
      font-family: "sinzanodisplay_sans";
      line-height: 1;
      margin-bottom: 10px; }
      .coconut-size-wrap.unlocked .coconut-wrapper .text-wrapper .unlockable-description {
        font-size: 1.1rem; }
        @media only screen and (min-width: 600px) {
          .coconut-size-wrap.unlocked .coconut-wrapper .text-wrapper .unlockable-description {
            font-size: 1.4rem; } }
        @media only screen and (min-width: 992px) {
          .coconut-size-wrap.unlocked .coconut-wrapper .text-wrapper .unlockable-description {
            font-size: 1.6rem; } }
        @media only screen and (min-width: 1300px) {
          .coconut-size-wrap.unlocked .coconut-wrapper .text-wrapper .unlockable-description {
            font-size: 2rem; } }
    .coconut-size-wrap.unlocked .coconut-wrapper .unlocked-padlock-wrapper {
      width: 30%;
      position: relative;
      margin-bottom: 5%; }
    .coconut-size-wrap.unlocked .coconut-wrapper .coconuts-unlocked {
      position: relative;
      width: 100%;
      padding-top: 66%;
      background-image: url(../images/contents_page/coconuts.png);
      background-position: center;
      background-size: contain;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      background-repeat: no-repeat;
      position: relative; }

@media only screen and (min-width: 768px) {
  .coconut-size-wrap {
    width: 10%;
    position: absolute;
    right: 10%;
    bottom: 25%; }
  .button .button-label {
    font-size: 1.203125rem; } }

@media only screen and (min-width: 1300px) {
  .button {
    padding: 22px;
    border-radius: 200px; }
    .button .icon-wrapper {
      width: 80px; }
    .button .button-label {
      font-size: 3rem; } }

/*--------------------------------------------------------------
## Header
--------------------------------------------------------------*/
.header {
  position: relative; }
  .header--splash-page {
    text-align: center; }
    @media only screen and (max-width: 768px) {
      .header--splash-page {
        text-align: left; } }
    .header--splash-page .header__logo a {
      margin-left: 0; }
      @media only screen and (max-width: 768px) {
        .header--splash-page .header__logo a {
          max-width: 250px; } }
      @media only screen and (max-width: 390px) {
        .header--splash-page .header__logo a {
          max-width: 170px;
          margin-top: 25px; } }
  .header--my-contents {
    background-color: #000000; }
    .header--my-contents .header__contents {
      position: relative; }
    .header--my-contents .header__logo {
      position: relative;
      margin-right: auto;
      margin-left: auto;
      padding-left: 15px;
      padding-right: 15px;
      width: 100%; }
      @media (min-width: 992px) {
        .header--my-contents .header__logo {
          width: 1024px; } }
      @media (min-width: 1300px) {
        .header--my-contents .header__logo {
          width: 1270px; } }
      .header--my-contents .header__logo a {
        margin-top: 15px;
        margin-left: -15px;
        max-width: 250px; }
        @media only screen and (min-width: 768px) {
          .header--my-contents .header__logo a {
            margin-left: -25px;
            max-width: 350px; } }
        @media only screen and (max-width: 390px) {
          .header--my-contents .header__logo a {
            max-width: 170px;
            margin-top: 25px; } }

.header__contents {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10; }

.header__logo a {
  display: inline-block;
  width: 100%;
  max-width: 420px;
  margin-left: 25px; }
  .header__logo a img {
    width: 100%;
    max-width: 100%; }
  @media only screen and (max-width: 1200px) {
    .header__logo a {
      margin-top: 25px;
      margin-left: 0;
      max-width: 250px; } }
  @media only screen and (max-width: 390px) {
    .header__logo a {
      max-width: 170px; } }

.header__slogan {
  color: #ffffff;
  width: 280px;
  padding-top: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto 0 15px; }

@media only screen and (min-width: 640px) {
  .header__slogan {
    width: 280px; } }

@media only screen and (min-width: 768px) {
  .header__slogan {
    width: 560px;
    margin: 0 auto;
    padding-top: 80px; } }

/*--------------------------------------------------------------
## Footer
--------------------------------------------------------------*/
.footer {
  color: #ffffff;
  padding-top: 2%;
  position: relative;
  width: 100%;
  background: #2ab2ae;
  background: -webkit-gradient(linear, left top, right top, from(#0a5e6b), color-stop(50%, #42efdf), color-stop(55%, #42efdf), to(#0a5e6b));
  background: linear-gradient(to right, #0a5e6b 0%, #42efdf 50%, #42efdf 55%, #0a5e6b 100%);
  background: -webkit-linear-gradient(left, #0a5e6b 0%, #42efdf 50%, #42efdf 55%, #0a5e6b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0a5e6b', endColorstr='#0a5e6b', GradientType=1); }
  @media only screen and (max-width: 768px) {
    .footer {
      padding-top: 2%; } }
  .footer:before {
    content: '';
    display: block;
    width: 100%;
    height: 10%;
    position: absolute;
    top: -5%;
    left: 0;
    background: #2ab2ae;
    background: -webkit-gradient(linear, left top, right top, from(#0a5e6b), color-stop(50%, #42efdf), color-stop(55%, #42efdf), to(#0a5e6b));
    background: linear-gradient(to right, #0a5e6b 0%, #42efdf 50%, #42efdf 55%, #0a5e6b 100%);
    background: -webkit-linear-gradient(left, #0a5e6b 0%, #42efdf 50%, #42efdf 55%, #0a5e6b 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0a5e6b', endColorstr='#0a5e6b', GradientType=1);
    -webkit-transform: skewY(-1deg);
            transform: skewY(-1deg); }
  .footer .footer-slide {
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out; }
  .footer .show-slide {
    opacity: 1; }
  .footer .hide-slide {
    opacity: 0; }

.footer__container {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px; }
  @media only screen and (max-width: 767px) {
    .footer__container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; } }

.footer__left-block,
.footer__right-block {
  text-align: center;
  width: 26%;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .footer__left-block,
    .footer__right-block {
      margin-bottom: 15px;
      -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
              order: 2;
      width: 70%;
      margin-left: auto;
      margin-right: auto; } }

.footer__left-block--characters-page {
  display: none; }
  @media only screen and (min-width: 768px) {
    .footer__left-block--characters-page {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }

.footer__characters-placeholder {
  background-image: url(../images/footer/characters-footer.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 94%;
  padding-top: 34%;
  position: relative;
  width: 60%; }
  @media only screen and (max-width: 767px) {
    .footer__characters-placeholder {
      background-size: 100%;
      padding-top: 75%;
      -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
              order: 1;
      width: 100%; } }
  .footer__characters-placeholder--overlap {
    margin-top: -10%; }
    @media only screen and (max-width: 767px) {
      .footer__characters-placeholder--overlap {
        margin-top: -25%; } }

.footer__bottom {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  padding-top: 15px; }
  .footer__bottom .footer-links-container {
    width: 100%;
    position: relative;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .footer__bottom .footer-links-container .legal-link {
      cursor: pointer;
      padding-left: 10px;
      padding-right: 10px; }
    .footer__bottom .footer-links-container .social-menu__link__icon svg {
      width: 30px; }
    .footer__bottom .footer-links-container > div,
    .footer__bottom .footer-links-container a {
      margin-bottom: 20px; }
  .footer__bottom .footer-item-wrapper {
    padding-left: 20px;
    padding-right: 20px; }

.footer__copyright {
  font-family: "Roboto", sans-serif;
  font-size: 8px;
  font-size: 0.8rem;
  text-align: center;
  -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10;
  width: 100%; }
  @media only screen and (max-width: 767px) {
    .footer__copyright {
      font-size: 6px;
      font-size: 0.6rem; } }
  @media only screen and (min-width: 992px) {
    .footer__copyright {
      -webkit-box-ordinal-group: 1;
          -ms-flex-order: 0;
              order: 0;
      width: auto; } }

.footer__legal-links {
  width: 100%; }
  .footer__legal-links a {
    color: #ffffff;
    text-decoration: none; }
    .footer__legal-links a:hover {
      cursor: pointer; }
    @media only screen and (max-width: 767px) {
      .footer__legal-links a {
        font-size: 8px;
        font-size: 0.8rem; } }

.footer__channel a {
  color: #ffffff;
  text-decoration: none; }

.footer__channel__title {
  font-size: 20px;
  font-size: 2rem;
  text-transform: uppercase; }
  @media only screen and (max-width: 767px) {
    .footer__channel__title {
      font-size: 14px;
      font-size: 1.4rem;
      text-align: right;
      vertical-align: middle;
      display: inline-block; } }

@media only screen and (max-width: 767px) {
  .footer__channel__image {
    display: inline-block;
    vertical-align: middle;
    width: 50%; }
    .footer__channel__image img {
      width: 100%;
      max-width: 100%; } }

.footer__button-wrapper {
  width: 100%;
  position: relative; }
  .footer__button-wrapper .dashed-path-wrapper {
    display: none; }
  .footer__button-wrapper .character-image-wrapper {
    width: 90%;
    margin: 0 auto; }
    .footer__button-wrapper .character-image-wrapper .character-image {
      width: 100%;
      padding-top: 60%;
      background-size: contain;
      background-position: center bottom;
      background-repeat: no-repeat; }
  .footer__button-wrapper .button {
    background-color: #ff9500; }
    .footer__button-wrapper .button .icon-wrapper {
      width: 10px; }
    .footer__button-wrapper .button .button-label {
      text-transform: capitalize; }
  .footer__button-wrapper .character-footprint-shared {
    position: absolute;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0; }
    .footer__button-wrapper .character-footprint-shared.active {
      opacity: 1; }
  .footer__button-wrapper .character-footprint-1 {
    width: 60%;
    padding-top: 60%;
    margin-top: 80%;
    left: -70%;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    -webkit-transition: opacity 0.7s;
    transition: opacity 0.7s; }
  .footer__button-wrapper .character-footprint-2 {
    width: 22%;
    padding-top: 22%;
    margin-top: 60%;
    right: 40%;
    -webkit-transform: rotate(210deg);
            transform: rotate(210deg);
    -webkit-transition: opacity 0.7s 0.7s;
    transition: opacity 0.7s 0.7s; }

.xiang_and_fan .footer__button-wrapper .button .button-label {
  font-size: 1.3125rem; }

@media only screen and (min-width: 768px) {
  .footer__button-wrapper .dashed-path-wrapper {
    display: block;
    position: relative;
    width: 100%; }
    .footer__button-wrapper .dashed-path-wrapper.previous .dashed-path-container {
      width: 25%;
      right: 7%;
      -webkit-transform: translateY(-70%);
              transform: translateY(-70%); }
    .footer__button-wrapper .dashed-path-wrapper.next .dashed-path-container {
      width: 70%;
      left: -25%;
      -webkit-transform: translateY(-80%);
              transform: translateY(-80%); }
  .footer__button-wrapper .dashed-path-container {
    position: absolute;
    top: 0;
    padding-top: 10%; }
  .footer__button-wrapper .button .icon-wrapper {
    width: 8px; }
  .footer__button-wrapper .button .button-label {
    font-size: 1.05rem; } }

@media only screen and (min-width: 1300px) {
  .footer__button-wrapper {
    width: 383px; }
    .footer__button-wrapper .button {
      padding: 15px; }
      .footer__button-wrapper .button .button-label {
        font-size: 2.4rem; } }

@media only screen and (min-width: 1500px) {
  .footer__button-wrapper .button .button-label {
    font-size: 2.625rem; } }

/*--------------------------------------------------------------
## Components
--------------------------------------------------------------*/
.character-header-wrapper {
  position: relative;
  width: 100%;
  padding-top: 133.3333%; }

.character-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .character-header .video-wrapper {
    position: absolute;
    top: -10%;
    left: -87%;
    width: 270%;
    height: 100%; }
    .character-header .video-wrapper .animated-video, .character-header .video-wrapper .video-still-image {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: auto; }
  .character-header .character-container {
    height: 100%;
    position: relative;
    padding-bottom: 2%; }
  .character-header.character-bg {
    width: 100%;
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    padding-left: 10px;
    padding-right: 10px; }
  .character-header .arrow-wrapper {
    width: 15px;
    position: relative;
    z-index: 1; }
  .character-header .arrow-down-wrapper {
    position: relative;
    width: 60px;
    padding-bottom: 10px; }
  .character-header .character-name {
    display: none; }
  .character-header.frick_and_frack.character-bg {
    background-image: radial-gradient(at 50% bottom, rgba(0, 78, 101, 0) 40%, rgba(0, 60, 77, 0.5)), url(../images/characters/bg/frick_and_frack.jpg); }
  .character-header.frick_and_frack .coconut-size-wrap-1 {
    width: 8%;
    right: auto;
    left: 5%;
    bottom: 66%;
    -webkit-transform: rotate(-40deg);
            transform: rotate(-40deg); }
    .character-header.frick_and_frack .coconut-size-wrap-1.unlocked {
      width: 10%; }
  .character-header.frick_and_frack .coconut-size-wrap-2 {
    width: 18%;
    right: 11%;
    left: auto;
    bottom: 6%;
    -webkit-transform: rotate(-60deg);
            transform: rotate(-60deg); }
  .character-header.xiang_and_fan.character-bg {
    background-image: radial-gradient(at 50% bottom, rgba(0, 78, 101, 0) 40%, rgba(0, 60, 77, 0.5)), url(../images/characters/bg/xiang_and_fan.jpg);
    background-position: 48% center; }
  .character-header.xiang_and_fan .coconut-size-wrap-1 {
    width: 5%;
    right: auto;
    left: 17%;
    bottom: 62%;
    -webkit-transform: rotate(-60deg);
            transform: rotate(-60deg); }
    .character-header.xiang_and_fan .coconut-size-wrap-1.unlocked {
      width: 10%; }
  .character-header.xiang_and_fan .coconut-size-wrap-2 {
    width: 17%;
    right: 33%;
    left: auto;
    bottom: 25%;
    top: auto;
    -webkit-transform: rotate(-80deg);
            transform: rotate(-80deg); }
  .character-header.bubba.character-bg {
    background-image: radial-gradient(at 50% bottom, rgba(0, 78, 101, 0) 40%, rgba(0, 60, 77, 0.5)), url(../images/characters/bg/bubba.jpg); }
  .character-header.bubba .coconut-size-wrap-1 {
    width: 5%;
    right: 9%;
    left: auto;
    bottom: 78%;
    -webkit-transform: rotate(-60deg);
            transform: rotate(-60deg); }
    .character-header.bubba .coconut-size-wrap-1.unlocked {
      width: 10%;
      bottom: 70%; }
  .character-header.bubba .coconut-size-wrap-2 {
    width: 18%;
    right: auto;
    left: 24%;
    bottom: 11%;
    top: auto;
    -webkit-transform: rotate(-80deg);
            transform: rotate(-80deg); }
  .character-header.ernesto.character-bg {
    background-image: radial-gradient(at 50% bottom, rgba(0, 78, 101, 0) 40%, rgba(0, 60, 77, 0.5)), url(../images/characters/bg/ernesto.jpg); }
  .character-header.ernesto .coconut-size-wrap-1 {
    width: 6%;
    right: 13%;
    left: auto;
    bottom: 42%;
    -webkit-transform: rotate(-60deg);
            transform: rotate(-60deg); }
    .character-header.ernesto .coconut-size-wrap-1.unlocked {
      width: 10%; }
  .character-header.ernesto .coconut-size-wrap-2 {
    width: 18%;
    right: auto;
    left: 10%;
    bottom: 35%;
    top: auto;
    -webkit-transform: rotate(-80deg);
            transform: rotate(-80deg); }
  .character-header.spike.character-bg {
    background-image: radial-gradient(at 50% bottom, rgba(0, 78, 101, 0) 40%, rgba(0, 60, 77, 0.5)), url(../images/characters/bg/spike.jpg); }
  .character-header.spike .coconut-size-wrap-1 {
    width: 11%;
    right: auto;
    left: 21%;
    bottom: 52%;
    -webkit-transform: rotate(-60deg);
            transform: rotate(-60deg); }
  .character-header.spike .coconut-size-wrap-2 {
    width: 17%;
    right: 7%;
    left: auto;
    bottom: 21%;
    top: auto;
    -webkit-transform: rotate(-85deg);
            transform: rotate(-85deg); }
  .character-header.oscar.character-bg {
    background-image: radial-gradient(at 50% bottom, rgba(0, 78, 101, 0) 40%, rgba(0, 60, 77, 0.5)), url(../images/characters/bg/oscar.jpg); }
  .character-header.oscar .coconut-size-wrap-1 {
    width: 6%;
    right: 3%;
    left: auto;
    bottom: 59%;
    -webkit-transform: rotate(-115deg);
            transform: rotate(-115deg); }
    .character-header.oscar .coconut-size-wrap-1.unlocked {
      width: 10%;
      right: 5%; }
  .character-header.oscar .coconut-size-wrap-2 {
    width: 15%;
    right: 9%;
    left: auto;
    bottom: 9%;
    -webkit-transform: rotate(-60deg);
            transform: rotate(-60deg); }
  .character-header.lulu.character-bg {
    background-image: radial-gradient(at 50% bottom, rgba(0, 78, 101, 0) 40%, rgba(0, 60, 77, 0.5)), url(../images/characters/bg/lulu.jpg); }
  .character-header.lulu .coconut-size-wrap-1 {
    width: 7%;
    right: 18%;
    left: auto;
    bottom: 61%;
    -webkit-transform: rotate(-70deg);
            transform: rotate(-70deg); }
    .character-header.lulu .coconut-size-wrap-1.unlocked {
      width: 11%;
      right: 16%; }
  .character-header.lulu .coconut-size-wrap-2 {
    width: 15%;
    right: auto;
    left: 14%;
    bottom: 10%;
    top: auto;
    -webkit-transform: rotate(-80deg);
            transform: rotate(-80deg); }
  .character-header.renalda.character-bg {
    background-image: radial-gradient(at 50% bottom, rgba(0, 78, 101, 0) 40%, rgba(0, 60, 77, 0.5)), url(../images/characters/bg/renalda.jpg); }
  .character-header.renalda .coconut-size-wrap-1 {
    width: 17%;
    right: auto;
    left: 24%;
    bottom: 13%;
    -webkit-transform: rotate(-80deg);
            transform: rotate(-80deg); }
  .character-header.renalda .coconut-size-wrap-2 {
    width: 11%;
    right: 11%;
    left: auto;
    bottom: 30%;
    top: auto;
    -webkit-transform: rotate(-60deg);
            transform: rotate(-60deg); }
  .character-header.renalda.character-bg, .character-header.lulu.character-bg, .character-header.oscar.character-bg, .character-header.spike.character-bg, .character-header.ernesto.character-bg, .character-header.bubba.character-bg, .character-header.frick_and_frack.character-bg {
    background-position: center; }
  .character-header.frick_and_frack .coconut-size-wrap.unlocked, .character-header.xiang_and_fan .coconut-size-wrap.unlocked, .character-header.bubba .coconut-size-wrap.unlocked, .character-header.ernesto .coconut-size-wrap.unlocked, .character-header.spike .coconut-size-wrap.unlocked, .character-header.oscar .coconut-size-wrap.unlocked, .character-header.lulu .coconut-size-wrap.unlocked, .character-header.zoomba .coconut-size-wrap.unlocked, .character-header.quincy .coconut-size-wrap.unlocked, .character-header.pokey .coconut-size-wrap.unlocked, .character-header.antonio .coconut-size-wrap.unlocked, .character-header.babatua .coconut-size-wrap.unlocked, .character-header.renalda .coconut-size-wrap.unlocked, .character-header.colette .coconut-size-wrap.unlocked {
    -webkit-transform: none;
            transform: none; }
  .character-header.antonio .coconut-size-wrap-1 {
    width: 17%;
    right: auto;
    left: 10%;
    bottom: 15%;
    -webkit-transform: rotate(-40deg);
            transform: rotate(-40deg); }
  .character-header.antonio .coconut-size-wrap-2 {
    width: 8%;
    right: auto;
    left: 8%;
    bottom: 59%;
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg); }
  .character-header.quincy .video-wrapper {
    left: -65%;
    top: -14%; }
  .character-header.quincy .coconut-size-wrap-1 {
    width: 18%;
    right: auto;
    left: 40%;
    bottom: 10%;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  .character-header.quincy .coconut-size-wrap-2 {
    width: 12%;
    right: 4%;
    bottom: 15%;
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg); }
  .character-header.pokey .video-wrapper {
    left: -84%; }
  .character-header.pokey .coconut-size-wrap-1 {
    width: 17%;
    right: auto;
    left: 2%;
    bottom: 4%;
    -webkit-transform: rotate(-70deg);
            transform: rotate(-70deg); }
  .character-header.pokey .coconut-size-wrap-2 {
    width: 12%;
    right: 4%;
    bottom: 8%;
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg); }
  .character-header.babatua .video-wrapper {
    left: -69%;
    top: -3%;
    width: 250%; }
  .character-header.babatua .coconut-size-wrap-1 {
    width: 17%;
    right: auto;
    left: 3%;
    bottom: 4%;
    -webkit-transform: rotate(-40deg);
            transform: rotate(-40deg); }
  .character-header.babatua .coconut-size-wrap-2 {
    width: 14%;
    right: 1%;
    left: auto;
    bottom: 5%;
    top: auto;
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg); }
    .character-header.babatua .coconut-size-wrap-2.unlocked {
      right: 2%; }
  .character-header.colette .video-wrapper {
    left: -69%;
    top: -0%;
    width: 240%; }
  .character-header.colette .coconut-size-wrap-1 {
    width: 17%;
    right: auto;
    left: 3%;
    bottom: 3%;
    -webkit-transform: rotate(-80deg);
            transform: rotate(-80deg); }
  .character-header.colette .coconut-size-wrap-2 {
    width: 10%;
    right: 21%;
    left: auto;
    bottom: 77%;
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg); }
    .character-header.colette .coconut-size-wrap-2.unlocked {
      width: 12%; }
  .character-header.zoomba .coconut-size-wrap-1 {
    width: 17%;
    right: auto;
    left: 4%;
    bottom: 29%;
    -webkit-transform: rotate(-75deg);
            transform: rotate(-75deg); }
    .character-header.zoomba .coconut-size-wrap-1.unlocked {
      bottom: 25%; }
  .character-header.zoomba .coconut-size-wrap-2 {
    width: 12%;
    right: 1%;
    bottom: 63%;
    -webkit-transform: rotate(-55deg);
            transform: rotate(-55deg); }
    .character-header.zoomba .coconut-size-wrap-2.unlocked {
      right: 3%; }

@media only screen and (min-width: 768px) {
  .character-header-wrapper {
    padding-top: 51.25%; }
  .character-header.character-bg {
    padding-left: 20px;
    padding-right: 20px; }
  .character-header .video-wrapper {
    position: absolute;
    top: -10%;
    left: 0;
    width: 100%;
    height: 100%; }
  .character-header .character-name {
    display: block;
    position: relative;
    font-size: 2.40625rem;
    color: #fff;
    text-shadow: 3px 6px rgba(0, 0, 0, 0.25); }
  .character-header.xiang_and_fan.character-bg {
    background-position: 50% 50%; }
  .character-header.xiang_and_fan .coconut-size-wrap-1 {
    width: 3%;
    left: 34%;
    bottom: 62%; }
    .character-header.xiang_and_fan .coconut-size-wrap-1.unlocked {
      width: 6%;
      left: 30%;
      bottom: 52%; }
  .character-header.xiang_and_fan .coconut-size-wrap-2 {
    width: 8%;
    right: 35%;
    bottom: 20%; }
  .character-header.bubba.character-bg {
    background-position: 50% 40%; }
  .character-header.bubba .coconut-size-wrap-1 {
    width: 6%;
    right: 13%;
    bottom: 61%; }
    .character-header.bubba .coconut-size-wrap-1.unlocked {
      width: 8%;
      bottom: 45%;
      right: 10%; }
  .character-header.bubba .coconut-size-wrap-2 {
    width: 10%;
    bottom: 7%; }
  .character-header.spike.character-bg {
    background-position: 50% 55%; }
  .character-header.pokey .video-wrapper, .character-header.quincy .video-wrapper, .character-header.colette .video-wrapper, .character-header.babatua .video-wrapper {
    position: absolute;
    top: -10%;
    left: 0;
    width: 100%;
    height: 100%; }
  .character-header.zoomba .coconut-size-wrap-1 {
    width: 10%;
    left: 10%;
    bottom: 27%; }
  .character-header.zoomba .coconut-size-wrap-2 {
    width: 6%;
    right: 4%;
    bottom: 54%; }
    .character-header.zoomba .coconut-size-wrap-2.unlocked {
      width: 7%;
      right: 8%;
      bottom: 48%; }
  .character-header.quincy .coconut-size-wrap-1 {
    width: 7.5%;
    left: 37%;
    bottom: 12%; }
  .character-header.quincy .coconut-size-wrap-2 {
    width: 6%;
    right: 38%;
    bottom: 14%; }
  .character-header.pokey .coconut-size-wrap-1 {
    width: 8%;
    left: 32%;
    bottom: 8%; }
  .character-header.pokey .coconut-size-wrap-2 {
    width: 6%;
    right: 14%;
    bottom: 11%; }
  .character-header.antonio .coconut-size-wrap-1 {
    width: 7.5%;
    left: 34%;
    bottom: 17%; }
  .character-header.antonio .coconut-size-wrap-2 {
    width: 4%;
    bottom: 60%;
    left: 35%; }
    .character-header.antonio .coconut-size-wrap-2.unlocked {
      width: 5%;
      left: 36%; }
  .character-header.babatua .coconut-size-wrap-1 {
    width: 7.5%;
    left: 28%;
    bottom: 10%; }
  .character-header.babatua .coconut-size-wrap-2 {
    width: 6%;
    right: 33%;
    bottom: 12%; }
    .character-header.babatua .coconut-size-wrap-2.unlocked {
      right: 33%; }
  .character-header.colette .coconut-size-wrap-1 {
    width: 8%;
    left: 24%;
    bottom: 8%; }
  .character-header.colette .coconut-size-wrap-2 {
    width: 6%;
    right: 31%;
    bottom: 84%;
    z-index: 50; }
    .character-header.colette .coconut-size-wrap-2.unlocked {
      width: 7%;
      bottom: 70%; }
  .character-header.renalda .coconut-size-wrap-1 {
    width: 8%;
    left: 32%;
    bottom: 7%; }
  .character-header.renalda .coconut-size-wrap-2 {
    width: 7%;
    right: 22%;
    bottom: 15%; }
  .character-header.frick_and_frack .coconut-size-wrap-1 {
    width: 6%;
    left: 27%;
    bottom: 60%; }
    .character-header.frick_and_frack .coconut-size-wrap-1.unlocked {
      width: 8%;
      bottom: 46%; }
  .character-header.frick_and_frack .coconut-size-wrap-2 {
    width: 8%;
    right: 17%;
    bottom: 15%; }
  .character-header.ernesto .coconut-size-wrap-1 {
    width: 4%;
    right: 34%; }
    .character-header.ernesto .coconut-size-wrap-1.unlocked {
      width: 6%; }
  .character-header.ernesto .coconut-size-wrap-2 {
    width: 10%;
    left: 32%;
    bottom: 30%; }
  .character-header.spike .coconut-size-wrap-1 {
    width: 5%;
    left: 18%;
    bottom: 56%; }
    .character-header.spike .coconut-size-wrap-1.unlocked {
      width: 7%;
      bottom: 48%; }
  .character-header.spike .coconut-size-wrap-2 {
    width: 10%;
    bottom: 17%; }
  .character-header.oscar .coconut-size-wrap-1 {
    width: 4%;
    right: 18%;
    bottom: 69%; }
    .character-header.oscar .coconut-size-wrap-1.unlocked {
      width: 6%;
      right: 18%;
      bottom: 55%; }
  .character-header.oscar .coconut-size-wrap-2 {
    width: 10%;
    right: 7%;
    bottom: 7%; }
  .character-header.lulu .coconut-size-wrap-1 {
    width: 4%;
    right: 36%;
    bottom: 62%; }
    .character-header.lulu .coconut-size-wrap-1.unlocked {
      width: 6%;
      right: 35%; }
  .character-header.lulu .coconut-size-wrap-2 {
    width: 8.5%;
    left: 21%;
    bottom: 10%; } }

@media only screen and (min-width: 992px) {
  .character-header.character-bg {
    padding-left: 30px;
    padding-right: 30px;
    background-position: center; }
  .character-header.zoomba .coconut-size-wrap-1 {
    width: 8%; }
  .character-header.zoomba .coconut-size-wrap-2 {
    width: 4%;
    right: 6%;
    bottom: 53%; }
    .character-header.zoomba .coconut-size-wrap-2.unlocked {
      width: 7%;
      right: 6%;
      bottom: 53%; }
  .character-header.quincy .coconut-size-wrap-1 {
    width: 6%; }
  .character-header.quincy .coconut-size-wrap-2 {
    width: 5%; }
  .character-header.pokey .coconut-size-wrap-1 {
    width: 6%; }
    .character-header.pokey .coconut-size-wrap-1.unlocked {
      width: 8%; }
  .character-header.pokey .coconut-size-wrap-2 {
    width: 4%; }
    .character-header.pokey .coconut-size-wrap-2.unlocked {
      width: 6%; }
  .character-header.antonio .coconut-size-wrap-1 {
    width: 6.5%; }
  .character-header.antonio .coconut-size-wrap-2 {
    width: 3%; }
  .character-header.babatua .coconut-size-wrap-1 {
    width: 6.5%; }
  .character-header.babatua .coconut-size-wrap-2 {
    width: 5%; }
  .character-header.colette .coconut-size-wrap-1 {
    width: 6%; }
    .character-header.colette .coconut-size-wrap-1.unlocked {
      width: 7%; }
  .character-header.colette .coconut-size-wrap-2 {
    width: 4%;
    right: 31%; }
    .character-header.colette .coconut-size-wrap-2.unlocked {
      width: 6%; }
  .character-header.renalda .coconut-size-wrap-1 {
    width: 6%; }
    .character-header.renalda .coconut-size-wrap-1.unlocked {
      width: 8%; }
  .character-header.renalda .coconut-size-wrap-2 {
    width: 5%; }
    .character-header.renalda .coconut-size-wrap-2.unlocked {
      width: 7%; }
  .character-header.frick_and_frack .coconut-size-wrap-1 {
    width: 4%; }
    .character-header.frick_and_frack .coconut-size-wrap-1.unlocked {
      width: 6%;
      bottom: 60%; }
  .character-header.frick_and_frack .coconut-size-wrap-2 {
    width: 6%; }
    .character-header.frick_and_frack .coconut-size-wrap-2.unlocked {
      width: 8%; }
  .character-header.xiang_and_fan .coconut-size-wrap-1 {
    width: 3%; }
    .character-header.xiang_and_fan .coconut-size-wrap-1.unlocked {
      left: 33%;
      bottom: 62%;
      width: 5%; }
  .character-header.xiang_and_fan .coconut-size-wrap-2 {
    width: 6%;
    right: 36%; }
  .character-header.bubba .coconut-size-wrap-1 {
    width: 4%;
    right: 13%; }
    .character-header.bubba .coconut-size-wrap-1.unlocked {
      width: 6%;
      bottom: 56%;
      right: 11%; }
  .character-header.bubba .coconut-size-wrap-2 {
    width: 8%; }
  .character-header.ernesto .coconut-size-wrap-1 {
    width: 3%; }
    .character-header.ernesto .coconut-size-wrap-1.unlocked {
      width: 5%; }
  .character-header.ernesto .coconut-size-wrap-2 {
    width: 8%; }
  .character-header.spike .coconut-size-wrap-1 {
    width: 4%;
    left: 19%;
    bottom: 61%; }
    .character-header.spike .coconut-size-wrap-1.unlocked {
      width: 7%;
      bottom: 54%; }
  .character-header.spike .coconut-size-wrap-2 {
    width: 8%; }
  .character-header.oscar .coconut-size-wrap-1 {
    width: 3%;
    bottom: 69%; }
    .character-header.oscar .coconut-size-wrap-1.unlocked {
      width: 6%;
      right: 16%;
      bottom: 58%; }
  .character-header.oscar .coconut-size-wrap-2 {
    width: 8%;
    right: 9%; }
  .character-header.lulu .coconut-size-wrap-1 {
    width: 3%;
    bottom: 62%; }
    .character-header.lulu .coconut-size-wrap-1.unlocked {
      width: 5%;
      right: 35%; }
  .character-header.lulu .coconut-size-wrap-2 {
    width: 6.5%; } }

@media only screen and (min-width: 1300px) {
  .character-header.character-bg {
    padding-left: 40px;
    padding-right: 40px; }
  .character-header .character-name {
    font-size: 4.8125rem; }
  .character-header .arrow-wrapper {
    width: 40px;
    height: 81px; }
  .character-header .arrow-down-wrapper {
    width: 120px;
    padding-bottom: 10px; }
  .character-header.zoomba .coconut-size-wrap-1 {
    width: 6%; }
  .character-header.zoomba .coconut-size-wrap-2 {
    width: 3%;
    right: 7%; }
    .character-header.zoomba .coconut-size-wrap-2.unlocked {
      width: 5%;
      right: 7%; }
  .character-header.quincy .coconut-size-wrap-1 {
    width: 6%; }
  .character-header.quincy .coconut-size-wrap-2 {
    width: 5%; }
  .character-header.pokey .coconut-size-wrap-1 {
    width: 5%; }
  .character-header.pokey .coconut-size-wrap-2 {
    width: 3%; }
  .character-header.antonio .coconut-size-wrap-2 {
    width: 2.5%; }
  .character-header.colette .coconut-size-wrap-2.unlocked {
    width: 6%;
    bottom: 76%; }
  .character-header.frick_and_frack .coconut-size-wrap-1 {
    width: 3%; }
  .character-header.xiang_and_fan .coconut-size-wrap-1 {
    width: 2%; }
  .character-header.xiang_and_fan .coconut-size-wrap-2 {
    width: 5%;
    right: 37%; }
    .character-header.xiang_and_fan .coconut-size-wrap-2.unlocked {
      width: 7%; }
  .character-header.bubba .coconut-size-wrap-1.unlocked {
    width: 6%;
    bottom: 60%;
    right: 12%; }
  .character-header.ernesto .coconut-size-wrap-1.unlocked {
    width: 5%; }
  .character-header.ernesto .coconut-size-wrap-2 {
    width: 6%;
    left: 34%; }
  .character-header.spike .coconut-size-wrap-1 {
    width: 4%;
    left: 19%;
    bottom: 61%; }
    .character-header.spike .coconut-size-wrap-1.unlocked {
      width: 6%;
      left: 19%;
      bottom: 61%; }
  .character-header.spike .coconut-size-wrap-2 {
    width: 7%; }
  .character-header.oscar .coconut-size-wrap-1 {
    width: 2.5%; }
    .character-header.oscar .coconut-size-wrap-1.unlocked {
      width: 4%;
      right: 17%;
      bottom: 69%; }
  .character-header.lulu .coconut-size-wrap-1 {
    width: 2.5%;
    bottom: 62%;
    right: 36%; }
    .character-header.lulu .coconut-size-wrap-1.unlocked {
      width: 4%;
      right: 35%; }
  .character-header.lulu .coconut-size-wrap-2 {
    width: 5%; } }

.character-header--walkthrough .arrow-wrapper,
.character-header--walkthrough .arrow-down-wrapper,
.character-header--walkthrough .character-name {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out; }

.character-header--walkthrough .speech-bubble--step-1,
.character-header--walkthrough .speech-bubble--step-2,
.character-header--walkthrough .speech-bubble--step-3,
.character-header--walkthrough .speech-bubble--step-4 {
  display: none; }

.character-header--walkthrough.step-2 .arrow-wrapper,
.character-header--walkthrough.step-3 .arrow-wrapper,
.character-header--walkthrough.step-3 .arrow-down-wrapper,
.character-header--walkthrough.step-3 .character-name {
  opacity: 1;
  z-index: 300; }

.character-header--walkthrough.step-4 .arrow-down-wrapper,
.character-header--walkthrough.step-4 .character-name,
.character-header--walkthrough.step-4 .arrow-wrapper {
  opacity: 1; }

.character-header--walkthrough.step-2 .arrow-wrapper .arrow .cls-3,
.character-header--walkthrough.step-3 .arrow-down-wrapper .down-arrow .cls-3 {
  -webkit-animation: flashingArrows 0.7s ease-in-out infinite;
          animation: flashingArrows 0.7s ease-in-out infinite; }

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  10% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  30% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); }
  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  10% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  30% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); }
  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.character-header--walkthrough.step-1 .speech-bubble--step-1 {
  display: block;
  position: absolute;
  height: 0;
  width: 40%;
  bottom: 0px;
  right: 20%; }
  @media only screen and (min-width: 768px) {
    .character-header--walkthrough.step-1 .speech-bubble--step-1 {
      width: 22%; } }
  .character-header--walkthrough.step-1 .speech-bubble--step-1 .orange-bubble {
    content: '';
    width: 100%;
    padding-top: 100%;
    background-color: #ff9500;
    border-radius: 50%;
    position: absolute;
    left: 40%;
    display: block;
    -webkit-transform: translateY(-53%);
            transform: translateY(-53%); }
  .character-header--walkthrough.step-1 .speech-bubble--step-1 .quincy {
    display: block;
    width: 130%;
    padding-top: 130%;
    content: '';
    position: absolute;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100%;
    left: 0;
    background-image: url(../images/characters_walkthrough/char_quincy_step_1.png);
    -webkit-transform: translateY(-57%);
            transform: translateY(-57%); }
    .character-header--walkthrough.step-1 .speech-bubble--step-1 .quincy .speech-bubble-message-wrapper {
      position: absolute;
      top: -5%;
      width: 100%;
      -webkit-animation-duration: 4s;
              animation-duration: 4s;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite; }
      @media only screen and (min-width: 768px) {
        .character-header--walkthrough.step-1 .speech-bubble--step-1 .quincy .speech-bubble-message-wrapper {
          width: 200%;
          top: 20%;
          right: 85%; } }
      .character-header--walkthrough.step-1 .speech-bubble--step-1 .quincy .speech-bubble-message-wrapper.bounce {
        -webkit-animation-name: bounce;
                animation-name: bounce; }
    .character-header--walkthrough.step-1 .speech-bubble--step-1 .quincy .speech-bubble__message {
      right: -5%;
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%); }
      .character-header--walkthrough.step-1 .speech-bubble--step-1 .quincy .speech-bubble__message:after {
        right: -10%;
        bottom: 4%; }
        @media only screen and (min-width: 952px) {
          .character-header--walkthrough.step-1 .speech-bubble--step-1 .quincy .speech-bubble__message:after {
            right: -14%; } }
        @media only screen and (min-width: 1300px) {
          .character-header--walkthrough.step-1 .speech-bubble--step-1 .quincy .speech-bubble__message:after {
            right: -18%; } }

.character-header--walkthrough.step-2 .speech-bubble--step-2 {
  display: block;
  position: absolute;
  height: 0;
  width: 30%;
  bottom: 50%;
  right: -5%; }
  @media only screen and (min-width: 768px) {
    .character-header--walkthrough.step-2 .speech-bubble--step-2 {
      width: 20%; } }
  @media only screen and (min-width: 1300px) {
    .character-header--walkthrough.step-2 .speech-bubble--step-2 {
      width: 17%; } }
  .character-header--walkthrough.step-2 .speech-bubble--step-2 .orange-bubble {
    content: '';
    width: 130%;
    padding-top: 130%;
    background-color: #ff9500;
    border-radius: 50%;
    position: absolute;
    left: 10%;
    display: block;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .character-header--walkthrough.step-2 .speech-bubble--step-2 .quincy {
    display: block;
    width: 130%;
    padding-top: 130%;
    content: '';
    position: absolute;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
    background-image: url(../images/characters_walkthrough/char_quincy_step_2.png);
    -webkit-transform: translateY(-57%);
            transform: translateY(-57%); }
  .character-header--walkthrough.step-2 .speech-bubble--step-2 .speech-bubble-message-wrapper {
    position: absolute;
    left: -125%;
    width: 120%;
    top: 40%;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite; }
    @media only screen and (min-width: 768px) {
      .character-header--walkthrough.step-2 .speech-bubble--step-2 .speech-bubble-message-wrapper {
        width: 210%;
        left: -235%;
        top: 81%; } }
    @media only screen and (min-width: 952px) {
      .character-header--walkthrough.step-2 .speech-bubble--step-2 .speech-bubble-message-wrapper {
        left: -235%;
        top: 61%; } }
    @media only screen and (min-width: 1300px) {
      .character-header--walkthrough.step-2 .speech-bubble--step-2 .speech-bubble-message-wrapper {
        width: 150%;
        left: -165%;
        top: 30%; } }
    .character-header--walkthrough.step-2 .speech-bubble--step-2 .speech-bubble-message-wrapper.bounce {
      -webkit-animation-name: bounce;
              animation-name: bounce; }
  .character-header--walkthrough.step-2 .speech-bubble--step-2 .speech-bubble__message {
    right: -5%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
    @media only screen and (min-width: 768px) {
      .character-header--walkthrough.step-2 .speech-bubble--step-2 .speech-bubble__message {
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%); } }
    .character-header--walkthrough.step-2 .speech-bubble--step-2 .speech-bubble__message:after {
      right: -10%;
      bottom: 4%; }
      @media only screen and (min-width: 768px) {
        .character-header--walkthrough.step-2 .speech-bubble--step-2 .speech-bubble__message:after {
          right: -15%;
          bottom: 45%;
          -webkit-transform: rotate(-45deg);
                  transform: rotate(-45deg); } }
      @media only screen and (min-width: 952px) {
        .character-header--walkthrough.step-2 .speech-bubble--step-2 .speech-bubble__message:after {
          right: -15%;
          bottom: 35%;
          -webkit-transform: rotate(-45deg);
                  transform: rotate(-45deg); } }
      @media only screen and (min-width: 1300px) {
        .character-header--walkthrough.step-2 .speech-bubble--step-2 .speech-bubble__message:after {
          right: -18%;
          bottom: 5%;
          -webkit-transform: rotate(15deg);
                  transform: rotate(15deg); } }

.character-header--walkthrough.step-3 .speech-bubble--step-3 {
  display: block;
  position: absolute;
  height: 0;
  width: 40%;
  bottom: 2%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  @media only screen and (min-width: 768px) {
    .character-header--walkthrough.step-3 .speech-bubble--step-3 {
      width: 25%; } }
  @media only screen and (min-width: 1300px) {
    .character-header--walkthrough.step-3 .speech-bubble--step-3 {
      width: 30%; } }
  .character-header--walkthrough.step-3 .speech-bubble--step-3 .orange-bubble {
    content: '';
    width: 110%;
    padding-top: 110%;
    background-color: #ff9500;
    border-radius: 50%;
    position: absolute;
    left: -5%;
    display: block;
    -webkit-transform: translateY(-42%);
            transform: translateY(-42%); }
  .character-header--walkthrough.step-3 .speech-bubble--step-3 .quincy {
    display: block;
    width: 130%;
    padding-top: 130%;
    content: '';
    position: absolute;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
    left: -25%;
    background-image: url(../images/characters_walkthrough/char_quincy_step_3.png);
    -webkit-transform: translateY(-57%);
            transform: translateY(-57%); }
  .character-header--walkthrough.step-3 .speech-bubble--step-3 .speech-bubble-message-wrapper {
    position: absolute;
    left: 0;
    width: 120%;
    top: 10%;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite; }
    @media only screen and (min-width: 768px) {
      .character-header--walkthrough.step-3 .speech-bubble--step-3 .speech-bubble-message-wrapper {
        width: 120%;
        left: 60%;
        top: 14%; } }
    @media only screen and (min-width: 952px) {
      .character-header--walkthrough.step-3 .speech-bubble--step-3 .speech-bubble-message-wrapper {
        width: 130%;
        left: 60%;
        top: 14%; } }
    @media only screen and (min-width: 1300px) {
      .character-header--walkthrough.step-3 .speech-bubble--step-3 .speech-bubble-message-wrapper {
        width: 110%;
        left: 60%;
        top: 12%; } }
    .character-header--walkthrough.step-3 .speech-bubble--step-3 .speech-bubble-message-wrapper.bounce {
      -webkit-animation-name: bounce;
              animation-name: bounce; }
  .character-header--walkthrough.step-3 .speech-bubble--step-3 .speech-bubble__message {
    left: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
    @media only screen and (min-width: 768px) {
      .character-header--walkthrough.step-3 .speech-bubble--step-3 .speech-bubble__message {
        padding-left: 50px;
        padding-right: 50px; } }
    @media only screen and (min-width: 952px) {
      .character-header--walkthrough.step-3 .speech-bubble--step-3 .speech-bubble__message {
        padding-left: 100px;
        padding-right: 100px; } }
    .character-header--walkthrough.step-3 .speech-bubble--step-3 .speech-bubble__message:after {
      right: -10%;
      bottom: 4%; }
      @media only screen and (min-width: 768px) {
        .character-header--walkthrough.step-3 .speech-bubble--step-3 .speech-bubble__message:after {
          left: -15%;
          right: auto;
          bottom: 25%;
          -webkit-transform: rotate(140deg);
                  transform: rotate(140deg); } }
      @media only screen and (min-width: 952px) {
        .character-header--walkthrough.step-3 .speech-bubble--step-3 .speech-bubble__message:after {
          left: -15%;
          right: auto;
          bottom: 25%;
          -webkit-transform: rotate(140deg);
                  transform: rotate(140deg); } }
      @media only screen and (min-width: 1300px) {
        .character-header--walkthrough.step-3 .speech-bubble--step-3 .speech-bubble__message:after {
          left: -15%;
          right: auto;
          bottom: 25%;
          -webkit-transform: rotate(140deg);
                  transform: rotate(140deg); } }

.character-header--walkthrough.step-4 .speech-bubble--step-4 {
  display: block;
  position: absolute;
  height: 0;
  width: 40%;
  bottom: 2%;
  right: -25%; }
  @media only screen and (min-width: 768px) {
    .character-header--walkthrough.step-4 .speech-bubble--step-4 {
      width: 25%;
      right: -15%;
      bottom: -2%; } }
  .character-header--walkthrough.step-4 .speech-bubble--step-4 .orange-bubble {
    content: '';
    width: 110%;
    padding-top: 110%;
    background-color: #ff9500;
    border-radius: 50%;
    position: absolute;
    left: -30%;
    display: block;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .character-header--walkthrough.step-4 .speech-bubble--step-4 .quincy {
    display: block;
    width: 130%;
    padding-top: 130%;
    content: '';
    position: absolute;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
    left: -25%;
    background-image: url(../images/characters_walkthrough/char_quincy_step_4.png);
    -webkit-transform: translateY(-57%);
            transform: translateY(-57%); }
  .character-header--walkthrough.step-4 .speech-bubble--step-4 .speech-bubble-message-wrapper {
    position: absolute;
    left: -120%;
    width: 120%;
    top: 10%;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite; }
    @media only screen and (min-width: 768px) {
      .character-header--walkthrough.step-4 .speech-bubble--step-4 .speech-bubble-message-wrapper {
        left: -160%;
        width: 140%;
        top: 10%; } }
    .character-header--walkthrough.step-4 .speech-bubble--step-4 .speech-bubble-message-wrapper.bounce {
      -webkit-animation-name: bounce;
              animation-name: bounce; }
  .character-header--walkthrough.step-4 .speech-bubble--step-4 .speech-bubble__message {
    right: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
    @media only screen and (min-width: 768px) {
      .character-header--walkthrough.step-4 .speech-bubble--step-4 .speech-bubble__message {
        padding-left: 50px;
        padding-right: 50px; } }
    .character-header--walkthrough.step-4 .speech-bubble--step-4 .speech-bubble__message:after {
      right: -10%;
      bottom: 4%; }
      @media only screen and (min-width: 768px) {
        .character-header--walkthrough.step-4 .speech-bubble--step-4 .speech-bubble__message:after {
          right: -15%;
          bottom: 10%;
          -webkit-transform: rotate(15deg);
                  transform: rotate(15deg); } }

.speech-bubble__message {
  background-color: #ffffff;
  padding: 20px;
  text-align: center;
  border-radius: 20px;
  position: absolute;
  -webkit-box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.2); }
  @media only screen and (min-width: 768px) {
    .speech-bubble__message {
      padding: 20px 100px; } }
  .speech-bubble__message:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 40px 0 0 100px;
    border-color: transparent;
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg); }
    @media only screen and (min-width: 768px) {
      .speech-bubble__message:after {
        border-color: transparent transparent transparent #ffffff; } }

.speech-bubble__title {
  font-size: 20px;
  line-height: 1.1; }
  @media only screen and (min-width: 768px) {
    .speech-bubble__title {
      font-size: 36px; } }

.speech-bubble__description {
  font-size: 18px;
  line-height: 1.1; }
  @media only screen and (min-width: 768px) {
    .speech-bubble__description {
      font-size: 22px; } }

.speech-bubble__close {
  position: absolute;
  right: -15px;
  top: -15px; }

.speech-bubble__close-button {
  width: 20px;
  height: 26px;
  position: relative; }
  .speech-bubble__close-button:before, .speech-bubble__close-button:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 6px;
    border-radius: 7px;
    background-color: #000;
    -webkit-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out; }
  .speech-bubble__close-button:before {
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(45deg);
            transform: translateY(-50%) rotate(45deg); }
  .speech-bubble__close-button:after {
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(-45deg);
            transform: translateY(-50%) rotate(-45deg); }

.speech-bubble__close-button__wrapper {
  background-color: #fff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: relative;
  z-index: 6;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out; }
  .speech-bubble__close-button__wrapper:hover {
    cursor: pointer;
    background-color: #61ae26; }
    .speech-bubble__close-button__wrapper:hover .speech-bubble__close-button:before, .speech-bubble__close-button__wrapper:hover .speech-bubble__close-button:after {
      background-color: #fff; }

.character-info {
  position: relative;
  background-image: -webkit-gradient(linear, left top, right top, from(#18600a), color-stop(45%, #a9fc42), color-stop(55%, #a9fc42), to(#18600a));
  background-image: linear-gradient(to right, #18600a, #a9fc42 45%, #a9fc42 55%, #18600a);
  background-size: 200%;
  background-position: center;
  -webkit-transform: skewY(-1deg);
          transform: skewY(-1deg);
  margin-top: -10px; }
  .character-info .unskew {
    -webkit-transform: skewY(1deg);
            transform: skewY(1deg); }
  .character-info .leaf-pattern {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(../images/characters/shared/leaf_pattern.png);
    background-size: auto 60%;
    background-position: 270% -15%;
    background-repeat: repeat-x;
    opacity: 0.15; }
  .character-info .character-img-wrapper {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    padding-left: 0;
    padding-right: 0; }
  .character-info .info-wrapper {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    padding-top: 20px; }
    .character-info .info-wrapper p {
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 1.125rem; }
      .character-info .info-wrapper p:last-child {
        margin-bottom: 0; }
  .character-info .dashed-path-wrapper {
    display: none; }
  .character-info .char-name {
    margin: 0;
    width: 100%;
    color: #fff;
    font-size: 2.25rem; }
  .character-info .animal-img-wrapper {
    display: none; }
  .character-info .animal-img {
    width: 100%;
    padding-top: 65%;
    position: relative; }
  .character-info .character-img {
    width: 100%;
    padding-top: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center top; }

@media only screen and (min-width: 768px) {
  .character-info {
    margin-top: -1%;
    background-image: -webkit-gradient(linear, left top, right top, from(#18600a), color-stop(45%, #a9fc42), color-stop(55%, #a9fc42), to(#18600a));
    background-image: linear-gradient(to right, #18600a, #a9fc42 45%, #a9fc42 55%, #18600a); }
    .character-info .leaf-pattern {
      background-size: 33%;
      background-position: -7% -40%;
      background-repeat: repeat-x; }
    .character-info .character-img-wrapper {
      width: 37%;
      -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
              order: 1;
      -ms-flex-item-align: end;
          align-self: flex-end;
      padding: 3.5% 0 0; }
    .character-info .info-wrapper {
      width: 35%;
      -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
              order: 2;
      padding-top: 8%;
      padding-bottom: 3%;
      text-align: right;
      -ms-flex-item-align: start;
          align-self: flex-start; }
      .character-info .info-wrapper p {
        margin-top: 10px;
        font-size: 0.9rem; }
    .character-info .dashed-path-wrapper {
      display: block;
      position: relative;
      width: 100%; }
    .character-info .dashed-path-container {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%; }
    .character-info .dashed-arrow-to-info {
      width: 100%;
      position: absolute;
      bottom: 0;
      right: 20%; }
    .character-info .char-name {
      font-size: 1.8rem; }
    .character-info .animal-img-wrapper {
      width: 28%;
      -webkit-box-ordinal-group: 4;
          -ms-flex-order: 3;
              order: 3;
      display: block;
      padding-top: 15px;
      padding-bottom: 15px; }
    .character-info .animal-img {
      padding-top: 100%; }
    .character-info .character-img {
      padding-top: 90%;
      width: 90%;
      background-size: 100%;
      background-position: bottom; }
    .character-info .animal-img {
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain; } }

@media only screen and (min-width: 1300px) {
  .character-info .char-name {
    font-size: 4.5rem; }
  .character-info .info-wrapper p {
    font-size: 2.25rem; } }

.character-specials {
  position: relative;
  background-color: #000;
  background-size: 200%;
  background-position: center;
  -webkit-transform: skewY(-1deg);
          transform: skewY(-1deg);
  margin-top: -1%;
  padding-top: 20px;
  position: relative;
  z-index: 1; }
  .character-specials .unskew {
    -webkit-transform: skewY(1deg);
            transform: skewY(1deg); }
  .character-specials .specials-wrapper {
    width: 100%; }
  .character-specials .video-wrapper {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    padding-top: 0px;
    padding-bottom: 10px;
    margin-bottom: -3%; }
  .character-specials .dashed-path-wrapper {
    display: none; }
  .character-specials .character-footprint-shared {
    position: absolute;
    width: 10%;
    padding-top: 10%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0; }
    .character-specials .character-footprint-shared.active {
      opacity: 1; }
  .character-specials .character-footprint-1 {
    margin-top: -29%;
    right: -2%;
    -webkit-transform: rotate(200deg);
            transform: rotate(200deg);
    -webkit-transition: opacity 0.7s;
    transition: opacity 0.7s; }
  .character-specials .character-footprint-2 {
    margin-top: -25%;
    left: 30%;
    -webkit-transform: rotate(-60deg);
            transform: rotate(-60deg);
    -webkit-transition: opacity 0.7s 0.7s;
    transition: opacity 0.7s 0.7s; }
  .character-specials .traits-wrapper {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    margin-bottom: -3%; }
  .character-specials .traits-stripe,
  .character-specials .more-btn-wrapper {
    margin: 0 auto;
    position: relative; }
  .character-specials .traits-stripe {
    width: 100%; }
  .character-specials .more-btn-wrapper {
    text-decoration: none;
    width: 80%; }
  .character-specials .traits-column {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    -webkit-transform: skewY(-11deg);
            transform: skewY(-11deg);
    padding: 15%;
    text-align: center; }
  .character-specials .trait {
    margin: 0;
    text-transform: uppercase;
    font-size: 1.45625rem;
    margin-bottom: 18%;
    text-shadow: 0 4px rgba(0, 0, 0, 0.3); }
  .character-specials .more-btn-wrapper .button {
    width: 100%;
    margin: 0 auto; }
    .character-specials .more-btn-wrapper .button .button-label {
      font-size: 1.5rem; }

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .character-specials .trait {
    font-size: 1.5rem;
    margin-bottom: 17%; } }

@media only screen and (min-width: 480px) {
  .character-specials .trait {
    font-size: 1.5rem;
    margin-bottom: 20%; } }

@media only screen and (min-width: 601px) {
  .character-specials .trait {
    font-size: 2.3rem;
    margin-bottom: 18%; } }

@media only screen and (min-width: 768px) {
  .character-specials .video-wrapper {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    width: 63%;
    padding-top: 50px;
    position: relative;
    z-index: 1; }
  .character-specials .traits-wrapper {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    width: 37%;
    padding-right: 30px;
    position: relative;
    z-index: 1;
    -ms-flex-item-align: end;
        align-self: flex-end; }
  .character-specials .trait {
    font-size: 1.1625rem;
    margin-bottom: 13%; }
  .character-specials .more-btn-wrapper {
    width: 95%; }
    .character-specials .more-btn-wrapper .button .button-label {
      font-size: 1.203125rem; }
  .character-specials .dashed-path-wrapper {
    display: block;
    position: relative;
    width: 100%; }
  .character-specials .dashed-path-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 75%;
    padding-top: 10%; }
  .character-specials .dashed-arrow-to-specials {
    width: 100%;
    position: absolute;
    right: 10%;
    bottom: 0; } }

@media only screen and (min-width: 1300px) {
  .character-specials .trait {
    font-size: 2.90625rem;
    margin-bottom: 6%; }
  .character-specials .traits-column {
    padding: 12%; }
  .character-specials .traits-stripe {
    width: 100%; }
  .character-specials .more-btn-wrapper .button .button-label {
    font-size: 2.5rem; } }

.video-player-container {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  -webkit-box-shadow: 0 8px 1px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0 8px 1px 0px rgba(0, 0, 0, 0.2); }
  .video-player-container .player-wrapper, .video-player-container .video-thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .video-player-container #youtube_player {
    display: block;
    width: 100%;
    height: 100%; }
  .video-player-container .video-thumbnail {
    background-size: cover; }
  .video-player-container .play-button {
    width: 100px; }
    .video-player-container .play-button:hover {
      cursor: pointer; }

.content-item {
  background-color: #ff9500;
  border-radius: 45px;
  -webkit-box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 22px;
  font-size: 2.2rem;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  margin: 20px 0;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s; }
  .content-item a, .content-item .content-item__label__title {
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
  .content-item:hover {
    background-color: #fff; }
    .content-item:hover a, .content-item:hover .content-item__label__title {
      color: #ff9500; }
    .content-item:hover .content-item__label__icon .icon {
      background-position: center bottom; }
  @media only screen and (max-width: 768px) {
    .content-item {
      max-width: 100%;
      margin: 20px auto; } }
  .content-item--game:hover .game-icon .cls-2 {
    fill: #ff9500; }
  .content-item--video {
    background-color: #26a7a5; }
    .content-item--video:hover a, .content-item--video:hover .content-item__label__title {
      color: #26a7a5; }
    .content-item--video:hover .video-icon .cls-2 {
      fill: #26a7a5; }
  .content-item--gif {
    background-color: #61ae26; }
    .content-item--gif:hover a, .content-item--gif:hover .content-item__label__title {
      color: #61ae26; }
    .content-item--gif:hover .gif-icon .cls-2 {
      fill: #61ae26; }
  .content-item--download {
    background-color: #a056ca; }
    .content-item--download:hover a, .content-item--download:hover .content-item__label__title {
      color: #a056ca; }
    .content-item--download:hover .download-icon .cls-2 {
      fill: #a056ca; }

.content-item .download-icon .cls-1,
.content-item .video-icon .cls-1,
.content-item .gif-icon .cls-1,
.content-item .image-icon .cls-1,
.content-item .game-icon .cls-1 {
  fill: #000000;
  opacity: 0.25; }

.content-item__container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%; }
  .content-item__container .content-item-wrapper:nth-child(odd) .content-item {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg); }
  .content-item__container .content-item-wrapper:nth-child(even) .content-item {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg); }
  @media (min-width: 992px) {
    .content-item__container {
      width: 1024px; } }
  @media (min-width: 1300px) {
    .content-item__container {
      width: 1270px; } }
  @media only screen and (max-width: 767px) {
    .content-item__container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; } }

.content-item a {
  color: #ffffff;
  text-decoration: none; }

.content-item__image img {
  width: 100%;
  max-width: 100%; }

.content-item__label {
  padding: 15px; }

.content-item__label__title {
  color: #ffffff;
  line-height: 1;
  vertical-align: middle;
  font-size: 18px;
  font-size: 1.8rem; }
  @media only screen and (min-width: 992px) {
    .content-item__label__title {
      font-size: 36px;
      font-size: 2.25rem; } }

.content-item__label__icon svg {
  fill: #ffffff;
  width: auto; }

.content-item__label__icon .icon,
.content-item__label__icon svg {
  width: 55px;
  height: 55px;
  background-position: center 0;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-right: 15px;
  vertical-align: middle;
  display: inline-block; }
  @media only screen and (max-width: 768px) {
    .content-item__label__icon .icon,
    .content-item__label__icon svg {
      height: 40px;
      width: 40px; } }

.locked-content-item {
  position: relative; }
  .locked-content-item.content-item {
    background: none;
    -webkit-box-shadow: none;
            box-shadow: none; }
    .locked-content-item.content-item:hover {
      cursor: default; }
  .locked-content-item .dashed .cls-1 {
    opacity: 0.2; }
  .locked-content-item .dashed .cls-2, .locked-content-item .dashed .cls-3 {
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: 12px;
    stroke-dasharray: 59 19; }
  .locked-content-item .dashed .cls-2 {
    stroke: #000; }
  .locked-content-item.content-item--download {
    color: #a056ca; }
    .locked-content-item.content-item--download .dashed .cls-3 {
      stroke: #a056ca; }
  .locked-content-item.content-item--gif {
    color: #61ae26; }
    .locked-content-item.content-item--gif .dashed .cls-3 {
      stroke: #61ae26; }
  .locked-content-item.content-item--video {
    color: #26a7a5; }
    .locked-content-item.content-item--video .dashed .cls-3 {
      stroke: #26a7a5; }
  .locked-content-item.content-item--game {
    color: #ff9500; }
    .locked-content-item.content-item--game .dashed .cls-3 {
      stroke: #ff9500; }
  .locked-content-item .content-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .locked-content-item .label {
    padding: 40px 40px 20px;
    font-size: 1.3rem; }
  .locked-content-item .content-desc {
    padding: 20px 20px 40px;
    text-transform: none;
    font-size: 1.3rem; }
  .locked-content-item .icon-wrapper {
    background-image: url(../images/contents_page/coconut-locked.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; }

@media only screen and (min-width: 420px) {
  .locked-content-item .label, .locked-content-item .content-desc {
    font-size: 2rem; } }

@media only screen and (min-width: 550px) {
  .locked-content-item .label, .locked-content-item .content-desc {
    font-size: 2.8rem; } }

@media only screen and (min-width: 640px) {
  .locked-content-item .label, .locked-content-item .content-desc {
    font-size: 3.2rem; } }

@media only screen and (min-width: 768px) {
  .locked-content-item .label, .locked-content-item .content-desc {
    font-size: 2.2rem; } }

.home-intro-text {
  position: relative;
  background-image: -webkit-gradient(linear, left top, right top, from(#18600a), color-stop(45%, #a9fc42), color-stop(55%, #a9fc42), to(#18600a));
  background-image: linear-gradient(to right, #18600a, #a9fc42 45%, #a9fc42 55%, #18600a);
  background-size: 200%;
  background-position: center;
  -webkit-transform: skewY(-1deg);
          transform: skewY(-1deg);
  margin-top: -10px;
  overflow: hidden; }
  .home-intro-text .unskew {
    -webkit-transform: skewY(1deg);
            transform: skewY(1deg); }
  .home-intro-text .leaf-pattern {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(../images/characters/shared/leaf_pattern.png);
    background-size: auto 60%;
    opacity: 0.15; }
  .home-intro-text__container {
    margin-top: 40px;
    margin-bottom: 100px; }
  .home-intro-text__text {
    text-align: center;
    margin: 0 auto;
    width: 90%; }
    .home-intro-text__text h2 {
      color: #ffffff;
      font-size: 1.8em;
      line-height: 1.2em;
      margin-bottom: 20px;
      text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.5); }

@media only screen and (min-width: 768px) {
  .home-intro-text {
    margin-top: -1%;
    background-image: -webkit-gradient(linear, left top, right top, from(#18600a), color-stop(45%, #a9fc42), color-stop(55%, #a9fc42), to(#18600a));
    background-image: linear-gradient(to right, #18600a, #a9fc42 45%, #a9fc42 55%, #18600a); }
    .home-intro-text .leaf-pattern {
      background-size: 33%;
      background-position-y: 20%; }
    .home-intro-text__container {
      margin-top: 80px;
      margin-bottom: 100px; }
    .home-intro-text__text {
      text-align: center;
      margin: 0 auto;
      width: 50%; }
      .home-intro-text__text h2 {
        font-size: 2.5em;
        line-height: 1.2em; } }

.character-list {
  background: #2ab2ae;
  background: -webkit-gradient(linear, left top, right top, from(#0a5e6b), color-stop(50%, #42efdf), color-stop(55%, #42efdf), to(#0a5e6b));
  background: linear-gradient(to right, #0a5e6b 0%, #42efdf 50%, #42efdf 55%, #0a5e6b 100%);
  background: -webkit-linear-gradient(left, #0a5e6b 0%, #42efdf 50%, #42efdf 55%, #0a5e6b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0a5e6b', endColorstr='#0a5e6b', GradientType=1);
  position: relative;
  width: 100%; }
  .character-list:before {
    content: '';
    display: block;
    width: 100%;
    height: 20%;
    position: absolute;
    top: -10%;
    left: 0;
    background: #2ab2ae;
    background: -webkit-gradient(linear, left top, right top, from(#0a5e6b), color-stop(50%, #42efdf), color-stop(55%, #42efdf), to(#0a5e6b));
    background: linear-gradient(to right, #0a5e6b 0%, #42efdf 50%, #42efdf 55%, #0a5e6b 100%);
    background: -webkit-linear-gradient(left, #0a5e6b 0%, #42efdf 50%, #42efdf 55%, #0a5e6b 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0a5e6b', endColorstr='#0a5e6b', GradientType=1);
    -webkit-transform: skewY(-1deg);
            transform: skewY(-1deg); }
  .character-list .carousel {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow: hidden;
    padding-bottom: 10px;
    position: relative; }
    @media only screen and (min-width: 768px) {
      .character-list .carousel {
        padding-bottom: 30px; } }
    .character-list .carousel__prev-arrow, .character-list .carousel__next-arrow {
      outline: none;
      border: 0;
      background-color: transparent;
      height: 100%;
      position: absolute;
      z-index: 10;
      width: 35px; }
      @media only screen and (min-width: 768px) {
        .character-list .carousel__prev-arrow, .character-list .carousel__next-arrow {
          display: block; } }
      .character-list .carousel__prev-arrow svg, .character-list .carousel__next-arrow svg {
        height: 100%;
        width: 100%; }
    .character-list .carousel__prev-arrow {
      left: 0; }
    .character-list .carousel__next-arrow {
      right: 0; }
  .character-list .carousel.active {
    cursor: -webkit-grabbing;
    cursor: grabbing; }
  .character-list .cWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .character-list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .character-list .item p {
    position: relative;
    top: -5px;
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .character-list .item:first-child {
    margin-right: 0px !important; }
  .character-list .item:last-child {
    margin-left: 0px !important; }

.character-list .item {
  height: 100px;
  width: 100px; }
  @media only screen and (min-width: 768px) {
    .character-list .item {
      height: 125px;
      width: 125px; } }
  .character-list .item.zoomba {
    background-image: url(../images/characters/carousel/Roundel_01_Zoomba_OFF.png);
    background-size: 100%; }
    .character-list .item.zoomba:hover, .character-list .item.zoomba.active {
      background-image: url(../images/characters/carousel/Roundel_01_Zoomba_ON.png); }
  .character-list .item.quincy {
    background-image: url(../images/characters/carousel/Roundel_02_Quincy_OFF.png);
    background-size: 100%; }
    .character-list .item.quincy:hover, .character-list .item.quincy.active {
      background-image: url(../images/characters/carousel/Roundel_02_Quincy_ON.png); }
  .character-list .item.pokey {
    background-image: url(../images/characters/carousel/Roundel_03_Pokey_OFF.png);
    background-size: 100%; }
    .character-list .item.pokey:hover, .character-list .item.pokey.active {
      background-image: url(../images/characters/carousel/Roundel_03_Pokey_ON.png); }
  .character-list .item.antonio {
    background-image: url(../images/characters/carousel/Roundel_04_Antonio_OFF.png);
    background-size: 100%; }
    .character-list .item.antonio:hover, .character-list .item.antonio.active {
      background-image: url(../images/characters/carousel/Roundel_04_Antonio_ON.png); }
  .character-list .item.babatua {
    background-image: url(../images/characters/carousel/Roundel_05_Babatua_OFF.png);
    background-size: 100%; }
    .character-list .item.babatua:hover, .character-list .item.babatua.active {
      background-image: url(../images/characters/carousel/Roundel_05_Babatua_ON.png); }
  .character-list .item.renalda {
    background-image: url(../images/characters/carousel/Roundel_06_Renalda_OFF.png);
    background-size: 100%; }
    .character-list .item.renalda:hover, .character-list .item.renalda.active {
      background-image: url(../images/characters/carousel/Roundel_06_Renalda_ON.png); }
  .character-list .item.colette {
    background-image: url(../images/characters/carousel/Roundel_07_Colette_OFF.png);
    background-size: 100%; }
    .character-list .item.colette:hover, .character-list .item.colette.active {
      background-image: url(../images/characters/carousel/Roundel_07_Colette_ON.png); }
  .character-list .item.frick-and-frack, .character-list .item.frick-frack {
    background-image: url(../images/characters/carousel/Roundel_08_FrickAndFrack_OFF.png);
    background-size: 100%; }
    .character-list .item.frick-and-frack:hover, .character-list .item.frick-and-frack.active, .character-list .item.frick-frack:hover, .character-list .item.frick-frack.active {
      background-image: url(../images/characters/carousel/Roundel_08_FrickAndFrack_ON.png); }
  .character-list .item.xiang-and-fan, .character-list .item.xiang-fan {
    background-image: url(../images/characters/carousel/Roundel_09_XiangAndFang_OFF.png);
    background-size: 100%; }
    .character-list .item.xiang-and-fan:hover, .character-list .item.xiang-and-fan.active, .character-list .item.xiang-fan:hover, .character-list .item.xiang-fan.active {
      background-image: url(../images/characters/carousel/Roundel_09_XiangAndFang_ON.png); }
  .character-list .item.bubba {
    background-image: url(../images/characters/carousel/Roundel_10_Bubba_OFF.png);
    background-size: 100%; }
    .character-list .item.bubba:hover, .character-list .item.bubba.active {
      background-image: url(../images/characters/carousel/Roundel_10_Bubba_ON.png); }
  .character-list .item.ernesto {
    background-image: url(../images/characters/carousel/Roundel_11_Ernesto_OFF.png);
    background-size: 100%; }
    .character-list .item.ernesto:hover, .character-list .item.ernesto.active {
      background-image: url(../images/characters/carousel/Roundel_11_Ernesto_ON.png); }
  .character-list .item.spike {
    background-image: url(../images/characters/carousel/Roundel_12_Spike_OFF.png);
    background-size: 100%; }
    .character-list .item.spike:hover, .character-list .item.spike.active {
      background-image: url(../images/characters/carousel/Roundel_12_Spike_ON.png); }
  .character-list .item.oscar {
    background-image: url(../images/characters/carousel/Roundel_13_Oscar_OFF.png);
    background-size: 100%; }
    .character-list .item.oscar:hover, .character-list .item.oscar.active {
      background-image: url(../images/characters/carousel/Roundel_13_Oscar_ON.png); }
  .character-list .item.lulu {
    background-image: url(../images/characters/carousel/Roundel_14_Lulu_OFF.png);
    background-size: 100%; }
    .character-list .item.lulu:hover, .character-list .item.lulu.active {
      background-image: url(../images/characters/carousel/Roundel_14_Lulu_ON.png); }

