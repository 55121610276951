.footer {
  color: #ffffff;
  padding-top: 2%;
  position: relative;
  width: 100%;

  // Setup background colouring
  background: #2ab2ae;
  background: linear-gradient(to right, #0a5e6b 0%, #42efdf 50%, #42efdf 55%, #0a5e6b 100%);
  background: -moz-linear-gradient(left, #0a5e6b 0%, #42efdf 50%, #42efdf 55%, #0a5e6b 100%);
  background: -webkit-linear-gradient(left, #0a5e6b 0%, #42efdf 50%, #42efdf 55%, #0a5e6b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0a5e6b', endColorstr='#0a5e6b', GradientType=1);

  @media only screen and (max-width: 768px) {
    padding-top: 2%;
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 10%;
    position: absolute;
    top: -5%;
    left: 0;
    background: #2ab2ae;
    background: linear-gradient(to right, #0a5e6b 0%, #42efdf 50%, #42efdf 55%, #0a5e6b 100%);
    background: -moz-linear-gradient(left, #0a5e6b 0%, #42efdf 50%, #42efdf 55%, #0a5e6b 100%);
    background: -webkit-linear-gradient(left, #0a5e6b 0%, #42efdf 50%, #42efdf 55%, #0a5e6b 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0a5e6b', endColorstr='#0a5e6b', GradientType=1);
    transform: skewY(-1deg);
  }

  // transitions testing
  .footer-slide {
    transition: opacity 0.3s ease-in-out;
  }

  .show-slide {
    opacity: 1;
  }

  .hide-slide {
    opacity: 0;
  }
}

.footer__container {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.footer__left-block,
.footer__right-block {
  text-align: center;
  width: 26%;
  position: relative;

  @media only screen and (max-width: 767px) {
    margin-bottom: 15px;
    order: 2;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}

.footer__left-block--characters-page {
  display: none;

  @media only screen and (min-width: 768px) {
    display: flex;
  }
}

.footer__characters-placeholder {
  background: {
    image: url('../images/footer/characters-footer.png');
    repeat: no-repeat;
    position: top center;
    size: 94%;
  }
  padding-top: 34%;
  position: relative;
  width: 60%;

  @media only screen and (max-width: 767px) {
    background-size: 100%;
    padding-top: 75%;

    order: 1;
    width: 100%;
  }

  &--overlap {
    margin-top: -10%;

    @media only screen and (max-width: 767px) {
      margin-top: -25%;
    }
  }
}

.footer__bottom {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  padding-top: 15px;

  .footer-links-container {
    width: 100%;
    position: relative;
    flex-wrap: wrap;

    .legal-link {
      cursor: pointer;
      padding-left: 10px;
      padding-right: 10px;
    }

    .social-menu__link__icon svg {
      width: 30px;
    }
    > div,
    a {
      margin-bottom: 20px;
    }
  }
  .footer-item-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.footer__copyright {
  font: {
    family: $font__roboto;
    size: 8px;
    size: 0.8rem;
  }
  text-align: center;

  order: 10;
  width: 100%;
  @media only screen and (max-width: 767px) {
    font: {
      size: 6px;
      size: 0.6rem;
    }
  }
  @media only screen and (min-width: 992px) {
    order: 0;
    width: auto;
  }
}

.footer__legal-links {
  width: 100%;

  a {
    color: #ffffff;
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
    @media only screen and (max-width: 767px) {
      font: {
        size: 8px;
        size: 0.8rem;
      }
    }
  }
}

.footer__channel a {
  color: #ffffff;
  text-decoration: none;
}

.footer__channel__title {
  font-size: 20px;
  font-size: 2rem;
  text-transform: uppercase;

  @media only screen and (max-width: 767px) {
    font-size: 14px;
    font-size: 1.4rem;
    text-align: right;
    vertical-align: middle;
    display: inline-block;
  }
}

.footer__channel__image {
  @media only screen and (max-width: 767px) {
    display: inline-block;
    vertical-align: middle;
    width: 50%;

    img {
      width: 100%;
      max-width: 100%;
    }
  }
}

.footer__button-wrapper {
  width: 100%;
  position: relative;

  .dashed-path-wrapper {
    display: none;
  }

  .character-image-wrapper {
    width: 90%;
    margin: 0 auto;

    .character-image {
      width: 100%;
      padding-top: 60%;
      background-size: contain;
      background-position: center bottom;
      background-repeat: no-repeat;
    }
  }

  .button {
    background-color: #ff9500;

    .icon-wrapper {
      width: 10px;
    }

    .button-label {
      text-transform: capitalize;
    }
  }

  .character-footprint-shared {
    position: absolute;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }
  .character-footprint-1 {
    width: 60%;
    padding-top: 60%;
    margin-top: 80%;
    left: -70%;
    transform: rotate(-180deg);
    transition: opacity 0.7s;
  }
  .character-footprint-2 {
    width: 22%;
    padding-top: 22%;
    margin-top: 60%;
    right: 40%;
    transform: rotate(210deg);
    transition: opacity 0.7s 0.7s;
  }
}

.xiang_and_fan {
  .footer__button-wrapper {
    .button {
      .button-label {
        font-size: 1.3125rem;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .footer__button-wrapper {
    .dashed-path-wrapper {
      display: block;
      position: relative;
      width: 100%;

      &.previous {
        .dashed-path-container {
          //padding-top: 0;
          width: 25%;
          right: 7%;
          transform: translateY(-70%);
        }
      }

      &.next {
        .dashed-path-container {
          //padding-top: 0;
          width: 70%;
          //left: auto;
          left: -25%;
          transform: translateY(-80%);
        }
      }
    }
    .dashed-path-container {
      position: absolute;
      top: 0;
      padding-top: 10%;
    }
    .button {
      .icon-wrapper {
        width: 8px;
      }
      .button-label {
        font-size: 1.05rem;
      }
    }
  }
}

@media only screen and (min-width: 1300px) {
  .footer__button-wrapper {
    width: 383px;

    .button {
      padding: 15px;

      .button-label {
        //font-size: 2.625rem;
        font-size: 2.4rem;
      }
    }
  }
}

@media only screen and (min-width: 1500px) {
  .footer__button-wrapper {
    .button {
      .button-label {
        font-size: 2.625rem;
      }
    }
  }
}
