.header {
  position: relative;

  // Header Splash Page modifier
  &--splash-page {
    text-align: center;

    @media only screen and (max-width: 768px) {
      text-align: left;
    }

    .header__logo a {
      margin-left: 0;
      @media only screen and (max-width: 768px) {
        max-width: 250px;
      }

      @media only screen and (max-width: 390px) {
        max-width: 170px;
        margin-top: 25px;
      }
    }
  }

  // Header My Contents page modifier
  &--my-contents {
    background-color: #000000;

    .header__contents {
      position: relative;
    }
    .header__logo {
      position: relative;

      margin-right: auto;
      margin-left: auto;
      padding-left: 15px;
      padding-right: 15px;
      width: 100%;

      @media (min-width: 992px) {
        width: 1024px;
      }
      @media (min-width: 1300px) {
        width: 1270px;
      }

      a {
        margin: {
          top: 15px;
          left: -15px;
        }
        max-width: 250px;

        @media only screen and (min-width: 768px) {
          margin-left: -25px;
          max-width: 350px;
        }

        @media only screen and (max-width: 390px) {
          max-width: 170px;
          margin-top: 25px;
        }
      }
    }
  }
}

.header__contents {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.header__logo a {
  display: inline-block;
  width: 100%;
  max-width: 420px;
  margin-left: 25px;

  img {
    width: 100%;
    max-width: 100%;
  }

  @media only screen and (max-width: 1200px) {
    margin-top: 25px;
    margin-left: 0;
    max-width: 250px;
  }

  @media only screen and (max-width: 390px) {
    max-width: 170px;
  }
}

.header__slogan {
  color: #ffffff;
  width: 280px;
  padding-top: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto 0 15px;

  //font: {
  //  family: $font_sinzano_sans;
  //  size: 18px;
  //  size: 1.8rem;
  //}
  //line-height: 0.9;
  //margin: 0;
  //padding: {
  //  right: 15px;
  //  left: 15px;
  //}
  //text-align: center;
  //text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.7);
}

@media only screen and (min-width: 640px) {
  .header__slogan {
    width: 280px;
  }
}

@media only screen and (min-width: 768px) {
  .header__slogan {
    width: 560px;
    margin: 0 auto;
    padding-top: 80px;
  }
}
