.social-menu__list {
  list-style: none;
  margin-top: 20px;
  margin-right: 40%;
  text-align: right;

  @media only screen and (min-width: 768px) {
    margin-left: 20%;
    margin-right: 0;
    text-align: left;
  }
}

.social-menu__link {
  display: inline-block;
  margin: auto 7px;
}

.social-menu__link__icon svg,
.social-menu__link__icon {
  width: 26px;
}
