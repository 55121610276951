// General Navigation styling
.navigation {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;

  // Navigation My Contents Page changes
  &--my-contents {
    .navigation__toggle {
      right: 15px;
      top: 15px;

      @media only screen and (min-width: 768px) {
        right: 40px;
        top: 25px;
      }

      @media only screen and (min-width: 992px) {
        right: 90px;
      }
    }

    .navigation__menu-burger__wrapper,
    .navigation__sound__wrapper {
      background-color: rgba(255, 255, 255, 0.6);
    }

    .navigation__menu-burger-line,
    .navigation__menu-burger:before,
    .navigation__menu-burger:after,
    .navigation__menu-burger .navigation__menu-burger-line {
      background-color: black;
    }
  }
}

.navigation__toggle {
  position: absolute;
  top: 25px;
  right: 60px;

  @media only screen and (max-width: 768px) {
    right: 15px;
  }
}

.navigation__title {
  color: #ffffff;
  font-size: 14px;
  font-size: 1.4rem;
  margin-right: 10px;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.5);

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.navigation__items-group {
  background-color: rgba(0, 0, 0, 0.7);
  position: relative;
  min-height: 450px;
  min-width: 450px;
  border-radius: 50%;
  margin-top: -20%;
  right: -30%;
  padding: {
    top: 50px;
    right: 50px;
    bottom: 70px;
    left: 50px;
  }

  @media only screen and (min-width: 768px) {
    right: -20%;
  }
}

.navigation__list {
  width: 60%;
  margin: {
    top: 35%;
    right: 40%;
    left: auto;
  }

  @media only screen and (min-width: 768px) {
    margin: {
      right: 0;
      left: 0;
    }
  }
}

.navigation__link {
  list-style: none;
  margin-top: 5px;
  font-size: 12px;
  font-size: 1.2rem;

  a {
    color: #ffffff;
    text-decoration: none;

    &.active,
    &:hover {
      color: $color_main_orange;

      .navigation__link__icon {
        background-position: center bottom;
      }
      img {
        fill: $color_main_orange;
      }
    }
  }
}

.navigation__link__title {
  @media only screen and (min-width: 768px) {
    min-width: 135px;
    text-align: right;
  }
}

.navigation__link__icon {
  margin-left: 10px;
}

.navigation__link__icon svg,
.navigation__link__icon {
  width: 26px;
  height: 26px;
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: 100%;
}

.navigation__items-group__container {
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  transform: scale(0);
  transform-origin: top right;
  transition: transform 0.3s ease-in-out;

  &--active {
    transform-origin: top right;
    transition: transform 0.3s ease-in-out;
    transform: scale(1.1);
  }
}

.navigation__sound__wrapper {
  .sound-on-icon {
    width: 40px;
  }
  .sound-off-icon {
    width: 40px;
  }
}

// Menu Burger Icon
.navigation__menu-burger {
  width: 30px;
  height: 26px;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 5px;
    border-radius: 7px;
    background-color: white;
  }

  &:before {
    top: -0px;
    transition: transform 0.3s, top 0.3s 0.3s, background-color 0.3s ease-in-out;
  }

  &:after {
    bottom: -1px;
    transition: transform 0.3s, bottom 0.3s 0.3s, background-color 0.3s ease-in-out;
  }

  &--active {
    .navigation__menu-burger-line {
      opacity: 0;
    }

    &:before {
      top: 10px;
      transform: rotate(45deg);
      transition: transform 0.3s 0.3s, top 0.3s;
    }

    &:after {
      bottom: 11px;
      transform: rotate(-45deg);
      transition: transform 0.3s 0.3s, bottom 0.3s;
    }
  }
}

.navigation__menu-burger .navigation__menu-burger-line {
  position: relative;
  width: 100%;
  height: 5px;
  border-radius: 7px;
  background-color: white;
  transition: opacity 0.3s, transform 0.3s, background-color 0.3s ease-in-out;
}

.navigation__menu-burger__wrapper,
.navigation__sound__wrapper {
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 50%;
  height: 60px;
  width: 60px;
  position: relative;
  z-index: 6;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #fff;
    cursor: pointer;

    .navigation__menu-burger {
      .navigation__menu-burger-line {
        background-color: #000;
      }

      &:before,
      &:after {
        background-color: #000;
      }
    }
    .sound-on-icon,
    .sound-off-icon {
      .cls-2 {
        fill: #000;
      }
    }
  }
}

.navigation__sound {
  position: relative;
}

.navigation__sound__wrapper {
  margin-right: 10px;
  @media only screen and (min-width: 768px) {
    margin-right: 15px;
  }
  @media only screen and (min-width: 1300px) {
    margin-right: 25px;
  }
}

.navigation__container.active {
  .navigation__menu-burger-line,
  .navigation__menu-burger:before,
  .navigation__menu-burger:after {
    background-color: black;
  }

  .navigation__menu-burger__wrapper,
  .navigation__sound__wrapper {
    background-color: rgba(255, 255, 255, 0.6);
  }
}

// Page speific variants
@media only screen and (min-width: 1024px) {
  .navigation__container--splash-page {
    .navigation__list {
      width: 50%;
    }

    .navigation__toggle {
      right: 100px;
    }
  }
}
