.content-item {
  background-color: $color_main_orange;
  border-radius: 45px;
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 22px;
  font-size: 2.2rem;
  overflow: hidden;
  text: {
    align: center;
    transform: uppercase;
  }
  margin: 20px 0;
  transition: background-color 0.2s;

  a, .content-item__label__title {
    transition: color 0.2s;
  }

  &:hover {
    background-color: #fff;

    a, .content-item__label__title {
      color: $color_main_orange;
    }

    .content-item__label__icon {
      .icon {
        background-position: center bottom;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    margin: 20px auto;
  }

  &--game {
    &:hover {
      .game-icon {
        .cls-2 {
          fill: $color_main_orange;
        }
      }
    }
  }

  &--video {
    background-color: $color_main_teal;

    &:hover {
      a, .content-item__label__title {
        color: $color_main_teal;
      }

      .video-icon {
        .cls-2 {
          fill: $color_main_teal;
        }
      }
    }
  }

  &--gif {
    background-color: $color_main_green;

    &:hover {
      a, .content-item__label__title {
        color: $color_main_green;
      }

      .gif-icon {
        .cls-2 {
          fill: $color_main_green;
        }
      }
    }
  }

  &--download {
    background-color: $color_main_purple;

    &:hover {
      a, .content-item__label__title {
        color: $color_main_purple;
      }

      .download-icon {
        .cls-2 {
          fill: $color_main_purple;
        }
      }
    }
  }
}

// content item icons
.content-item {
  .download-icon,
  .video-icon,
  .gif-icon,
  .image-icon,
  .game-icon {
    .cls-1 {
      fill: #000000;
      opacity: 0.25;
    }
  }
}

.content-item__container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;

  // Rotate the items depending on odd/even
  .content-item-wrapper {
    &:nth-child(odd) {
      .content-item {
        transform: rotate(2deg);
      }

    }

    &:nth-child(even) {
      .content-item {
        transform: rotate(-2deg);
      }
    }
  }

  @media (min-width: 992px) {
    width: 1024px;
  }
  @media (min-width: 1300px) {
    width: 1270px;
  }

  // Set flex items into col
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.content-item a {
  color: #ffffff;
  text-decoration: none;
}

.content-item__image img {
  width: 100%;
  max-width: 100%;
}

.content-item__label {
  padding: 15px;
}

.content-item__label__title {
  color: #ffffff;

  // Set line-height & compensate lost spacing at top
  line-height: 1;
  vertical-align: middle;

  // May add this in if they want to truncate the title
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;

  font-size: 18px;
  font-size: 1.8rem;

  @media only screen and (min-width: 992px) {
    font-size: 36px;
    font-size: 2.25rem;
  }
}

.content-item__label__icon svg {
  fill: #ffffff;
  width: auto;
}

.content-item__label__icon .icon,
.content-item__label__icon svg {
  width: 55px;
  height: 55px;
  background-position: center 0;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-right: 15px;
  vertical-align: middle;
  display: inline-block;

  @media only screen and (max-width: 768px) {
    height: 40px;
    width: 40px;
  }
}
