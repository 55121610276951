// My Contents styling
.page-my-contents {
  background-image: url('../images/characters/shared/leaf_pattern.png'),
    linear-gradient(to right, rgba(205, 219, 222, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 1) 65%, rgba(205, 219, 222, 1) 100%);
  background-size: auto 350px;
  background-position: top left;

  padding-bottom: 100px;
}

.page-my-contents__page-header {
  padding-top: 40px;
  margin-bottom: 80px;

  position: relative;
}

.page-my-contents__title {
  font-size: 35px;
  font-size: 2.1875rem;
}

.page-my-contents__dropdown {
  background-color: $color_main_orange;
  border-radius: 45px;
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.2);

  margin-left: auto;

  padding: 20px 25px;

  @media only screen and (min-width: 720px) {
    position: absolute;
    right: 0;
    top: 40px;
    z-index: 5;
  }
}

.page-my-contents__dropdown__button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%;

  @media only screen and (min-width: 720px) {
    width: auto;
  }

  svg {
    width: auto;
    height: 25px;
    margin-left: auto;

    @media only screen and (min-width: 720px) {
      margin-left: 15px;
    }
  }
}

.page-my-contents__dropdown__button__title {
  color: #ffffff;
  font-size: 25px;
  font-size: 1.5625rem;

  @media only screen and (min-width: 720px) {
    font-size: 35px;
    font-size: 2.1875rem;
  }
}

.page-my-contents__dropdown__list {
  height: 0;
  list-style: none;
  visibility: hidden;

  transition: height 0.3s ease-in-out;

  &--active {
    height: auto;
    visibility: visible;
  }
}

.page-my-contents__dropdown__list li {
  color: #ffffff;
  cursor: pointer;
  font-size: 22px;
  padding-left: 10px;
}

// More button styling
.page-my-contents__more-button__container {
  position: relative;
  z-index: 1;
  margin: {
    top: 40px;
    right: auto;
    left: auto;
  }
  width: 100%;
  max-width: 300px;

  @media only screen and (min-width: 992px) {
    max-width: 450px;
  }
}

.page-my-contents__more-button {
  background-color: $color_main_green;
  color: #fff;
  border: {
    style: none;
    radius: 40px;
  }
  box-shadow: 0 3px 0px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  width: 100%;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: #fff;
    color: $color_main_green;
    .more-btn .cls-2 {
      fill: $color_main_green;
    }
  }

  @media only screen and (min-width: 992px) {
    padding: 22px;
    border-radius: 200px;
  }
}

.page-my-contents__more-button__icon {
  width: 40px;

  @media only screen and (min-width: 992px) {
    width: 80px;
  }
}

.page-my-contents__more-button__title {
  font-size: 1.203125rem;

  @media only screen and (min-width: 992px) {
    font-size: 3rem;
  }
}
