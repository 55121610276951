.media-viewer {
  position: relative;
  background-color: black;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;

  .leaf_pattern {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../images/characters/shared/leaf_pattern.png');
    background-size: auto 350px;
    background-position: top left;
    opacity: 0.15;
    top: 0;
    z-index: 0;
  }
  .media-viewer__container{
    width: 100%;
    min-height: 600px;
    padding-left: 75px;
    padding-right: 75px;
  }

  &.media-viewer--on-splash-page{
    .media-viewer__container{
      padding-top: 200px;
    }
  }
}

.media-viewer__media {
  width: 100%;
  max-width: 600px;
  position: relative;

  &.game{
    max-width: 1300px;
  }
  img {
    width: 100%;
  }

  .game-wrapper{
    position: relative;
    width: 100%;
    padding-top: 56.25%;
  }
  .game-iframe{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

// Media Viewer close
.media-viewer__close {
  position: absolute;
  right: -70px;
  top: 0px;
}
.media-viewer__close-button {
  width: 34px;
  height: 26px;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 7px;
    border-radius: 7px;
    background-color: black;
    transition: background-color 0.3s ease-in-out;
  }
  &:before {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
  &:after {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
  }
}

.media-viewer__close-button__wrapper {
  background-color: #fff;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  position: relative;
  z-index: 6;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: $color_main_green;

    .media-viewer__close-button{
      &:before,
      &:after{
        background-color: #fff;
      }
    }
  }
}

.media-viewer__media__description {
  color: #ffffff;
  font-size: 20px;
  font-size: 1.25rem;
  margin-top: 10px;
}
