.page-game {
  padding-bottom: 100px;
  position: relative;

  .leaf_pattern{
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../images/characters/shared/leaf_pattern.png');
    background-size: auto 350px;
    background-position: top left;
    opacity: 0.15;
    top: 0;
    z-index: 0;
  }

  .game-iframe-wrapper{
    position: relative;
    z-index: 3;
    width: 100%;
    padding-top: 56.25%;

    @media only screen and (min-width:768px) {

    }

    @media only screen and (min-width:992px) {

    }

    @media only screen and (min-width:1300px) {

    }
  }
  iframe {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.page-game__loader,
.page-game__loader:before,
.page-game__loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.page-game__loader {
  z-index: 1;
  left: 50%;
  margin-left: -2.5em;
  top: 40%;
  color: #ff9500;
  font-size: 10px;
  margin: 80px auto;
  position: absolute;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.page-game__loader:before,
.page-game__loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.page-game__loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.page-game__loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
