html,
body {
  min-height: 100%;
  //height: 100%;
  background-color: #000;
}

#app {
  position: relative;
}

body {
  overflow-x: hidden;
  min-height: 320px;
}

svg {
  display: block;
  width: 100%;
  height: auto;
}

//icons
.arrow {
  .cls-1 {
    opacity: 0.35;
  }

  .cls-2,
  .cls-3 {
    fill: none;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    stroke-width: 40px;
  }

  .cls-2 {
    stroke: #000;
  }

  .cls-3 {
    stroke: #fff;
    transition: stroke 0.3s;
  }

  &.down-arrow {
    .cls-2,
    .cls-3 {
      stroke-width: 20px;
    }
  }

  &:hover {
    cursor: pointer;

    .cls-3 {
      stroke: $color_main_green;
    }
  }
}

.down-arrow {
  &:hover {
    cursor: pointer;

    .cls-3 {
      stroke: #fff;
    }
  }
}

.arrow-thin {
  .cls-1 {
    opacity: 0.35;
  }

  .cls-2,
  .cls-3 {
    fill: none;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    stroke-width: 13.16px;
  }

  .cls-2 {
    stroke: #000;
  }

  .cls-3 {
    stroke: #fff;
  }
}

.play-button {
  .cls-1 {
    opacity: 0.2;
  }

  .cls-2 {
    opacity: 0.75;
  }

  .cls-3 {
    opacity: 0.35;
  }

  .cls-4 {
    fill: #fff;
  }
}

.trait-stripe {
  .cls-1 {
    fill: none;
  }

  .cls-2 {
    isolation: isolate;
  }

  .cls-3 {
    mix-blend-mode: hard-light;
  }

  .cls-4 {
    clip-path: url(#stripe_clip_path);
  }
}

.more-btn {
  .cls-1 {
    opacity: 0.25;
  }

  .cls-2 {
    fill: #fff;
    transition: fill 0.2s;
  }
}

.envelope-icon {
  .cls-1 {
    opacity: 0.25;
  }

  .cls-2 {
    fill: #fff;
  }
}

.facebook-icon {
  .cls-1 {
    fill: #4267b2;
  }

  .cls-2 {
    fill: #fff;
  }
}

.twitter-icon {
  .cls-1 {
    fill: #1da1f2;
  }
}

.instagram-icon {
  .cls-1 {
    fill: #fff;
  }
}

.sound-on-icon,
.sound-off-icon {
  .cls-1 {
    opacity: 0.35;
  }

  .cls-2 {
    transition: fill 0.3s ease-in-out;
    fill: #fff;
  }
}

.button {
  border-radius: 40px;
  background-color: $color_main_green;
  color: #fff;
  width: 100%;
  padding: 10px;
  box-shadow: 0 8px 1px 0px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    cursor: pointer;
    background-color: #fff;
    color: $color_main_green;

    .more-btn .cls-2 {
      fill: $color_main_green;
    }
  }

  .icon-wrapper {
    width: 40px;
  }

  .button-label {
    padding: 0 5px;
    text-transform: uppercase;
    font-size: 1.5rem;
  }
}

.dashed-arrow-to-info,
.dashed-arrow-to-specials,
.dashed-arrow-to-previous-character,
.dashed-arrow-to-previous-character {
  .cls-1 {
    opacity: 0.2;
  }

  .cls-2,
  .cls-3 {
    fill: none;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    stroke-width: 4px;
    stroke-dashoffset: 100;
    stroke-dasharray: 20 20;
    animation: dash 2s linear forwards;
  }

  .cls-2 {
    stroke: #000;
  }

  .cls-3 {
    stroke: #fff;
  }

  .cls-4 {
    fill: #fff;
  }
}

.unlocked-padlock {
  .cls-1 {
    fill: #fff;
  }
}

.coconut-size-wrap {
  width: 20%;
  position: absolute;
  right: 2%;

  .coconut-wrapper {
    position: relative;
    backface-visibility: hidden;
    width: 100%;
    padding-top: 78%;

    &:hover {
      cursor: pointer;
    }

    .coconut {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 95%;
      background-image: url('../images/contents_page/coconut.png');
      background-position: center;
      background-size: contain;
      //animation: coconut-animation 5s infinite;
      backface-visibility: hidden;
    }

    .coconut-shadow {
      position: absolute;
      width: 96%;
      height: 20%;
      left: 2%;
      bottom: -3%;
      background-image: url('../images/contents_page/coconut-shadow.png');
      background-position: center;
      background-size: contain;
      //animation: coconut-shadow-animation 5s infinite;
      backface-visibility: hidden;
    }
  }

  &.unlocked {
    .coconut-wrapper {
      padding-top: 0;

      .coconut-shadow {
        animation: none;
        width: 100%;
        padding-top: 15.0838%;
        height: auto;
        bottom: -6%;
        transform: scale(0.9);
      }

      .starburst {
        position: absolute;
        left: 50%;
        bottom: 0%;
        transform: translate(-50%, 32%);
        width: 200%;
        padding-top: 169%;
        background-image: url('../images/contents_page/starburst.png');
        background-position: center;
        background-size: contain;
        backface-visibility: hidden;
        background-repeat: no-repeat;
        animation: starburst-animation 7s infinite linear;
      }

      .text-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        color: #fff;
        text-shadow: 0px 3px rgba(0, 0, 0, 0.5);
        text-align: center;
        font-family: $font_sinzano_sans;
        line-height: 1;
        margin-bottom: 10px;

        .unlockable-description {
          font-size: 1.1rem;

          @media only screen and (min-width: 600px) {
            font-size: 1.4rem;
          }
          @media only screen and (min-width: 992px) {
            font-size: 1.6rem;
          }
          @media only screen and (min-width: 1300px) {
            font-size: 2rem;
          }
        }
      }

      .unlocked-padlock-wrapper {
        width: 30%;
        position: relative;
        margin-bottom: 5%;
      }

      .coconuts-unlocked {
        position: relative;
        width: 100%;
        padding-top: 66%;
        background-image: url('../images/contents_page/coconuts.png');
        background-position: center;
        background-size: contain;
        backface-visibility: hidden;
        background-repeat: no-repeat;
        position: relative;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .coconut-size-wrap {
    width: 10%;
    position: absolute;
    right: 10%;
    bottom: 25%;
  }
  .button {
    .button-label {
      font-size: 1.203125rem;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .button {
    padding: 22px;
    border-radius: 200px;

    .icon-wrapper {
      width: 80px;
    }

    .button-label {
      font-size: 3rem;
    }
  }
}
