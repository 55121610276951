.home-intro-text {
  position: relative;
  background-image: linear-gradient(to right, rgb(24, 96, 10), rgb(169, 252, 66) 45%, rgb(169, 252, 66) 55%, rgb(24, 96, 10));
  background-size: 200%;
  background-position: center;
  transform: skewY(-1deg);
  margin-top: -10px;
  overflow: hidden;

  .unskew {
    transform: skewY(1deg);
  }

  .leaf-pattern {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../images/characters/shared/leaf_pattern.png');
    background-size: auto 60%;
    opacity: 0.15;
  }

  &__container {
    margin: {
      top: 40px;
      bottom: 100px;
    }
  }

  &__text {
    text-align: center;
    margin: 0 auto;
    width: 90%;

    h2 {
      color: #ffffff;
      font-size: 1.8em;
      line-height: 1.2em;
      margin-bottom: 20px;

      text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.5);
    }
  }
}

@media only screen and (min-width: 768px) {
  .home-intro-text {
    margin-top: -1%;
    background-image: linear-gradient(to right, rgb(24, 96, 10), rgb(169, 252, 66) 45%, rgb(169, 252, 66) 55%, rgb(24, 96, 10));

    .leaf-pattern {
      background-size: 33%;
      background-position-y: 20%;
    }

    &__container {
      margin: {
        top: 80px;
        bottom: 100px;
      }
    }

    &__text {
      text-align: center;
      margin: 0 auto;
      width: 50%;

      h2 {
        font-size: 2.5em;
        line-height: 1.2em;
      }
    }
  }
}
